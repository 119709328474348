<template>
  <div style="margin-bottom: 60px">
    <el-form
      :model="declarationForm"
      class="flow-form"
      :inline="true"
      ref="declarationForm"
      label-position="top">
      <header-title title="经销权转让流程" />
      <div class="tips">
        <ol>
          <li v-for="(content, index) of tips" :key="index">{{ content }}</li>
        </ol>
      </div>
      <RequiredFormItemInput
        class="formItem"
        label="标题"
        v-model="declarationForm.title"
        placeholder="自动生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="单号"
        v-model="declarationForm.orderNumber"
        placeholder="自动生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="招商单号"
        v-model="declarationForm.zsOrderNumber"
        placeholder="自动生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="提单日期"
        v-model="declarationForm.optTime"
        placeholder="自动生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="提单人工号"
        v-model="declarationForm.optJobNumber"
        placeholder="自动生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="提单人"
        v-model="declarationForm.optUserName"
        placeholder="自动生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="提单部门"
        v-model="declarationForm.optDeptName"
        placeholder="自动生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="售达方编号"
        v-model="newBossInfo.licenseNumber"
        placeholder="归档后生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="经销商平台账号"
        v-model="newBossInfo.bossNumber"
        placeholder="归档后生成"
        disabled />
      <RequiredFormItemInput
        class="formItem"
        label="OA账号"
        v-model="newBossInfo.bossNumber"
        placeholder="归档后生成"
        disabled />
      <RequiredFormItemSelect
        class="formItem"
        label="最终送达方编号"
        v-model="newBossInfo.shopBrandNumber"
        multiple
        placeholder="归档后生成"
        :dataList="newBossInfo.shopBrandNumber"
        disabled />
    </el-form>
    <!-- 原经销商信息 -->
    <dealer-flow
      ref="dealerFlow"
      :formStatus="status"
      @changeCity="changeCity"
      @changeBrand="changeBrand"
      @changeAuthorizedCityCode="changeAuthorizedCityCode" />
    <!-- 现经销商营业执照信息 -->
    <new-dealer-license-flow
      ref="newDealerLicenseFlow"
      :formStatus="status"
      @changeDisabled="changeDisabled"
      @getBossInfo="getBossInfo"
      @changeBusinessLicense="changeBusinessLicense"
      @onChangeBusinessLicenseType="onChangeBusinessLicenseType" />
    <!-- 现经销商个人基础信息 -->
    <new-dealer-flow
      ref="newDealerFlow"
      :formStatus="status"
      @changeRealName="changeRealName"
      :authorizedCityCode="authorizedCityCode" />

    <!-- 现经销商开票信息 -->
    <new-dealer-invoice-flow ref="newDealerInvoiceFlow" :license="license" />
    <!-- 转让城市情况 -->
    <city-transfer-flow ref="cityTransferFlow" />
    <!-- 目前团队结构 -->
    <team-flow ref="teamFlow" />
    <!-- 转让事项确认 -->
    <transfer-flow ref="transferFlow" :formStatus="status" />
    <!-- 原经销商门店信息 -->
    <dealer-store-flow ref="dealerStoreFlow" />
    <header-title title="货物及快递收货信息" />
    <el-form
      ref="warehouse"
      :model="warehouse"
      class="flow-form"
      :inline="true"
      label-position="top">
      <RequiredFormItemInput
        class="formItem"
        label="仓库收货人"
        prop="warehouseUser"
        v-model="warehouse.warehouseUser"
        :rules="warehouseRules.warehouseUser"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        label="联系方式"
        prop="warehousePhone"
        v-model="warehouse.warehousePhone"
        :rules="warehouseRules.warehousePhone"
        :disabled="isOA" />
      <RequiredFormItemInput
        class="formItem"
        label="仓库收货地址"
        prop="warehouseAddress"
        v-model="warehouse.warehouseAddress"
        :disabled="isOA" />

      <RequiredFormItemInput
        class="formItem"
        label="运输目的地"
        prop="deliveryAddress"
        v-model="warehouse.deliveryAddress"
        :disabled="isOA" />
      <RequiredModuleFormItemSelect
        class="formItem"
        label="运输方式"
        prop="deliveryType"
        v-model="warehouse.deliveryType"
        type="delivery_type"
        :disabled="isOA" />
      <RequiredModuleFormItemSelect
        class="formItem"
        label="运输条件"
        prop="transportType"
        v-model="warehouse.transportType"
        type="transport_type"
        :disabled="isOA" />
    </el-form>
    <!-- 品牌推广费及品牌保证金收取 -->
    <brand-cost-flow
      ref="brandCostFlow"
      style="margin-bottom: 20px"
      :channel="channel" />
    <template v-if="channel === '10'">
      <header-title title="直营门店评估备案" />
      <el-form
        ref="assessmentfFiling"
        :model="assessmentfFiling"
        class="flow-form"
        :inline="true"
        label-position="top">
        <RequiredModuleFormItemSelect
          class="formItem"
          label="直营公司"
          prop="directCompany"
          v-model="assessmentfFiling.directCompany"
          type="direct_company"
          :disabled="isOA" />
        <RequiredFormItemDatePicker
          class="formItem"
          label="预约关店时间"
          prop="closeDate"
          v-model="assessmentfFiling.closeDate"
          :datePickerProps="{
            valueFormat: 'yyyy-MM-dd',
            type: 'date'
          }"
          :disabled="isOA" />
        <RequiredModuleFormItemSelect
          class="formItem"
          label="固定资产处置情况"
          prop="fixedAssets"
          v-model="assessmentfFiling.fixedAssets"
          type="asset_disposal"
          :disabled="isOA"
          @change="
            data => {
              if (data !== '0') {
                assessmentfFiling.fixedAssetsDate = ''
              }
            }
          " />
        <RequiredFormItemInput
          v-if="assessmentfFiling.fixedAssets === '0'"
          class="formItem"
          label="固定资产截止处置时间"
          prop="fixedAssetsDate"
          v-model="assessmentfFiling.fixedAssetsDate"
          :disabled="isOA" />
        <RequiredModuleFormItemSelect
          class="formItem"
          label="门店押金收回与否"
          prop="storeDeposit"
          v-model="assessmentfFiling.storeDeposit"
          type="flow_common_select_ifEnable"
          :disabled="isOA"
          @change="
            data => {
              if (data === '0') {
                assessmentfFiling.storeDepositFileList = []
              } else {
                assessmentfFiling.storeDepositDate = ''
              }
            }
          " />
        <RequiredFormItemInput
          v-if="assessmentfFiling.storeDeposit === '0'"
          class="formItem"
          label="门店押金收回截止处置时间"
          prop="storeDepositDate"
          v-model="assessmentfFiling.storeDepositDate"
          :disabled="isOA" />

        <RequiredFormItemUploadFile
          v-if="assessmentfFiling.storeDeposit === '1'"
          prop="storeDepositFileList"
          class="formItem"
          label="上传门店押金收回附件"
          limit-size="400"
          v-model="assessmentfFiling.storeDepositFileList"
          @onUploaded="
            () => {
              $refs['assessmentfFiling'].validateField('storeDepositFileList')
            }
          ">
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
        <RequiredFormItemInput
          class="formItem"
          label="未完结订单处理安排"
          prop="orderProcessing"
          v-model="assessmentfFiling.orderProcessing"
          :disabled="isOA" />
        <RequiredModuleFormItemSelect
          class="formItem"
          label="人员安置情况"
          prop="personPlacement"
          v-model="assessmentfFiling.personPlacement"
          type="person_disposal"
          :disabled="isOA" />
        <RequiredFormItemInput
          class="formItem"
          label="存货（含饰品）处理情况"
          prop="stock"
          v-model="assessmentfFiling.stock"
          :disabled="isOA" />
        <RequiredFormItemInput
          class="formItem"
          label="关店利润中心编码"
          prop="centralCode"
          v-model="assessmentfFiling.centralCode"
          :disabled="isOA" />
        <RequiredModuleFormItemSelect
          class="formItem"
          label="营业执照注销与否"
          prop="businessLicenseCancel"
          v-model="assessmentfFiling.businessLicenseCancel"
          type="flow_common_select_ifEnable"
          :disabled="isOA"
          @change="
            data => {
              if (data === '0') {
                assessmentfFiling.businessLicenseFileList = []
              } else {
                assessmentfFiling.businessLicenseDate = ''
              }
            }
          " />
        <RequiredFormItemInput
          v-if="assessmentfFiling.businessLicenseCancel === '0'"
          class="formItem"
          label="营业执照注销截止处置时间"
          prop="businessLicenseDate"
          v-model="assessmentfFiling.businessLicenseDate"
          :disabled="isOA" />
        <RequiredFormItemUploadFile
          v-if="assessmentfFiling.businessLicenseCancel === '1'"
          prop="businessLicenseFileList"
          class="formItem"
          label="上传营业执照注销附件"
          limit-size="400"
          v-model="assessmentfFiling.businessLicenseFileList"
          @onUploaded="
            () => {
              $refs['assessmentfFiling'].validateField(
                'businessLicenseFileList'
              )
            }
          ">
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
        <RequiredFormItemInput
          class="formItem"
          label="一次性损失（装修未摊完、人员遣散、存货等）"
          prop="totalLoss"
          v-model="assessmentfFiling.totalLoss"
          :disabled="isOA" />
        <RequiredModuleFormItemSelect
          class="formItem"
          label="商场代收款结清与否"
          prop="storeSettlement"
          v-model="assessmentfFiling.storeSettlement"
          type="flow_common_select_ifEnable"
          :disabled="isOA"
          @change="
            data => {
              if (data !== '0') {
                assessmentfFiling.storeSettlementFileList = []
              }
            }
          " />
        <RequiredFormItemUploadFile
          v-if="assessmentfFiling.storeSettlement === '0'"
          prop="storeSettlementFileList"
          class="formItem"
          label="上传商场代收款附件"
          limit-size="400"
          v-model="assessmentfFiling.storeSettlementFileList"
          @onUploaded="
            () => {
              $refs['assessmentfFiling'].validateField(
                'storeSettlementFileList'
              )
            }
          ">
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
        <RequiredFormItemInput
          class="formItem"
          label="关店理由与情况介绍"
          prop="situation"
          style="width: 100%"
          type="textarea"
          maxlength="500"
          :showWordLimit="true"
          resize="none"
          :rows="6"
          v-model="assessmentfFiling.situation"
          :disabled="isOA" />
      </el-form>
    </template>
    <el-form class="flow-form" :inline="true" label-position="top">
      <remark-item
        class="remark"
        v-model="declarationForm.remark"
        :fileList="fileRecordList"
        @onUpload="
          file => {
            fileRecordList = file.map(item => {
              return { ...item.response, functionAttribution: '备注' }
            })
          }
        " />
    </el-form>
    <footer v-if="!isOA" class="footer">
      <el-button
        type="primary"
        @click.stop="submit(10)"
        v-loading.fullscreen.lock="loading"
        >提交</el-button
      >
      <el-button
        type="info"
        @click.stop="submit(0)"
        v-loading.fullscreen.lock="loading"
        >保存</el-button
      >
      <el-button style="margin-left: 50px" @click="$router.go(-1)"
        >取消</el-button
      >
    </footer>
  </div>
</template>
<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import DealerFlow from './components/dealerFlow'
import NewDealerFlow from './components/newDealerFlow'
import NewDealerLicenseFlow from './components/newDealerLicenseFlow'
import NewDealerInvoiceFlow from './components/newDealerInvoiceFlow'
import CityTransferFlow from './components/cityTransferFlow'
import TeamFlow from './components/teamFlow'
import TransferFlow from './components/transferFlow'
import DealerStoreFlow from './components/dealerStoreFlow'
import BrandCostFlow from './components/brandCostFlow'
import { saveFranchiseChange } from '@/api/flow/dealershipTransfer'
import { details } from '@/api/flow/dealershipTransfer'
import { getMasterDataList } from '@/api/businessOos'
import { validPhone, validName } from '@/utils/useFormValidator'
import {
  RequiredFormItemInput,
  RequiredFormItemSelect,
  RequiredModuleFormItemSelect,
  RequiredFormItemUploadFile,
  RequestModuleSelect,
  RequiredBankFormItemSelect,
  RequiredFormItemDatePicker
} from '@/components/CustomFormItem'
import { positionToError } from '@/utils/index'
import HeaderTitle from './components/headerTitle.vue'
import RemarkItem from './components/remarkItem.vue'
import DirectFlow from './components/directTranslateFlow.vue'
import { setSession, getSession, removeSession } from '@/utils/storage'
export default {
  components: {
    HeaderTitle,
    DealerFlow,
    NewDealerFlow,
    NewDealerLicenseFlow,
    NewDealerInvoiceFlow,
    CityTransferFlow,
    TeamFlow,
    TransferFlow,
    DealerStoreFlow,
    RequiredFormItemSelect,
    RequiredModuleFormItemSelect,
    RequestModuleSelect,
    RequiredFormItemUploadFile,
    RequiredFormItemInput,
    RequiredFormItemDatePicker,
    BrandCostFlow,
    RemarkItem,
    DirectFlow
  },
  data() {
    return {
      channel: '',
      license: {
        businessLicenseName: '', //开票名称===营业执照名称
        creditCode: '', //纳税人识别号===社会统一信用代码
        businessLicenseAddress: '' //纳税人地址===营业执照地址
      },
      authorizedCityCode: '',
      tips: [
        '1、经销权转让申请表：该地区经销商代理的慕思品牌授权转让给其他经销商',
        '2、原经销商转让品牌后，旗下所有门店的对应转让品牌全部取消',
        '3、如果新经销接收品牌和门店，需要转店的品牌必须是新经销商代理的授权，如果有门店代理的品牌超出新经销商接手的品牌，那么流程不能创建（例如A代理3D、凯奇、歌蒂娅，转让3D给到B，其中有一个门店代理3D、凯奇，B要接手这个门店是不允许的，因为B没有歌蒂娅的权限）',
        '4、如果新经销商只接收品牌不接收门店，那么原经销商的转让品牌对应的门店在流程归档后自动撤店失效，并且新经销商需要另外提交一个开店流程'
      ],
      status: '',
      loading: false,
      newBossInfo: {
        licenseNumber: '',
        bossNumber: '',
        shopBrandNumber: []
      },
      declarationForm: {
        title: '经销权转让申请', //标题
        orderNumber: '', //单号
        zsOrderNumber: '', //招商单号
        optTime: '', //提单日期
        optJobNumber: '', //提单人工号
        optUserId: '', //提单人Id
        optUserName: '', //提单人
        optDeptId: '', //提单部门Id
        optDeptName: '', //提单部门名称
        remark: ''
      },
      fileRecordList: [], //备注附件
      dealerWarehouseCourier: {
        warehouseConsignee: '', // 仓库收货人
        warehouseConsignee_contact: '', // 联系方式
        warehouseAddress: '', // 仓库收货地址
        destinationPoint: '', // 运输目的地
        transportMode: '', // 运输方式
        transportCondition: '' // 运输条件
      },
      warehouse: {
        //仓库信息
        warehouseUser: '', //"string //仓库收货人"
        warehousePhone: '', //"string //仓库收货人联系方式"
        warehouseAddress: '', //"string //仓库收货人联系地址"
        deliveryAddress: '', //"string //运送目的地"
        deliveryType: '', //"string //运送目的地"
        transportType: '' //"string //装运条件"
      },
      city: '',
      realName: '',
      brandName: '',
      warehouseRules: {
        warehouseUser: [{ validator: validName, trigger: 'blur' }], // 仓库收货人
        warehousePhone: [{ validator: validPhone, trigger: 'blur' }] // 联系方式
      },
      assessmentfFiling: {
        directCompany: '', //直营公司
        closeDate: '', //预约关店时间
        fixedAssets: '', //固定资产处置情况
        fixedAssetsDate: '', //固定资产截止处置时间
        storeDeposit: '', //门店押金收回与否
        storeDepositDate: '', //门店押金收回截止处置时间
        storeDepositFileList: [], //上传门店押金收回附件
        orderProcessing: '', //未完结订单处理安排
        personPlacement: '', //人员安置情况
        stock: '', //存货（含饰品）处理情况
        centralCode: '', //关店利润中心编码
        businessLicenseCancel: '', //营业执照注销与否
        businessLicenseDate: '', //营业执照注销截止处置时间
        businessLicenseFileList: [], //上传营业执照注销附件
        totalLoss: '', //一次性损失（装修未摊完、人员遣散、存货等）
        storeSettlement: '', //商场代收款结清与否
        storeSettlementFileList: [], //上传商场代收款附件
        situation: '' //关店理由与情况介绍
      },
      shopNumberList: [],
      isCache: true
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState('dealershipTransfer', [
      'isOA',
      'authorizedCityParams',
      'historicalData',
      'businessLicense',
      'brandList'
    ])
  },
  watch: {
    userInfo: {
      handler(userInfo) {
        if (userInfo && !this.$route.query.id) {
          this.declarationForm = {
            ...this.declarationForm,
            optJobNumber: userInfo.code,
            optUserId: userInfo.accountId,
            optUserName: userInfo.realName,
            optDeptId: userInfo.deptId,
            optDeptName: userInfo.deptName
          }
        }
      },
      immediate: true
    }
  },
  created() {
    this.getBusinessData()
    if (this.$route.query.type && this.$route.query.type === 'direct') {
      this.channel = '10'
    } else {
      this.channel = '20'
    }
    if (this.$route.query.id) {
      this.getData()
    }
  },
  mounted() {
    this.isCache = !this.$route.query.id
    if (this.isCache) {
      if (this.$route.query.type && this.$route.query.type === 'direct') {
        const json = getSession('dealershipTransferDirect')
        if (!json) {
          return
        }
        this.getValue(json, true)
      } else {
        const json = getSession('dealershipTransfer')
        if (!json) {
          return
        }
        this.getValue(json, true)
      }
    }
  },
  beforeDestroy() {
    if (this.isCache) {
      this.submit(0, true)
    } else {
      removeSession('dealershipTransfer')
      removeSession('dealershipTransferDirect')
    }
  },
  methods: {
    ...mapMutations('dealershipTransfer', ['setStatus', 'setHistoricalData']),
    ...mapActions('user', ['getBusinessData']),
    ...mapActions('dealershipTransfer', ['getSenderList']),
    onChangeBusinessLicenseType(businessLicenseType) {
      this.$refs['newDealerInvoiceFlow'].businessLicenseType =
        businessLicenseType
      switch (String(businessLicenseType)) {
        case '0':
          this.$refs['newDealerInvoiceFlow'].form.payTaxesType = '1'
          break
        case '1':
          if (this.$refs['newDealerInvoiceFlow'].form.payTaxesType === '1') {
            this.$refs['newDealerInvoiceFlow'].form.payTaxesType = ''
          }
          break
        default:
          this.$refs['newDealerInvoiceFlow'].form.payTaxesType = ''
      }
    },
    changeBusinessLicense(obj) {
      this.license = { ...this.license, ...obj }
    },
    changeAuthorizedCityCode(val) {
      this.authorizedCityCode = val
    },
    async getBossInfo() {
      if (this.status === 40) {
        this.newBossInfo.bossNumber =
          this.historicalData.bossJoinModel.dealerBoss.bossNumber
        this.newBossInfo.licenseNumber =
          this.historicalData.bossJoinModel.dealerBusinessLicense.licenseNumber
        await getMasterDataList({
          businessLicenseId: this.historicalData.newBusinessLicenseId,
          authorizedCityCode: this.historicalData.authorizedCityCode
        }).then(res => {
          let { code, data } = res
          if (code != 0 || data.length === 0) return
          this.shopNumberList = data
          this.newBossInfo.shopBrandNumber = data.map(item => {
            return item.shopBrandNumber
          })
        })
      }
    },
    changeRealName(val) {
      this.realName = val
    },
    changeCity(val) {
      this.city = val
    },
    changeBrand(arr) {
      let brandName = this.brandList
        .filter(item => {
          return arr.includes(item.brandId)
        })
        .map(item => item.name)
      this.brandName = brandName.join(',')
      // console.log('品牌名',this.brandName)
    },
    changeDisabled(disabled) {
      this.$refs['newDealerInvoiceFlow'].disabled = disabled
      this.$refs['newDealerFlow'].disabled = disabled
    },
    async getData() {
      const fromId = this.$route.query.id
      const loading = this.$loading({
        lock: true,
        text: '加载中，请稍等',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      details({ fromId })
        .then(async res => {
          this.getValue(res.data, false)
        })
        .finally(() => {
          loading.close()
        })
    },
    getValue(data, isSession) {
      const {
        title, //标题
        orderNumber, //单号
        zsOrderNumber, //招商单号
        optTime, //提单日期
        optJobNumber, //提单人工号
        optUserId, //提单人Id
        optUserName, //提单人
        optDeptId, //提单部门Id
        optDeptName //提单部门名称
      } = isSession ? data.declarationForm : data
      if (!isSession) {
        const { status } = data
        this.status = status
        this.setStatus(status)
      }

      const historicalData = isSession ? data : JSON.parse(data.historicalData)
      const { remark = '', id = '' } = historicalData.declarationForm
      this.setHistoricalData(historicalData)
      this.declarationForm = {
        id,
        title, //标题
        orderNumber, //单号
        zsOrderNumber, //招商单号
        optTime, //提单日期
        optJobNumber, //提单人工号
        optUserId, //提单人Id
        optUserName, //提单人
        optDeptId, //提单部门Id
        optDeptName, //提单部门名称
        remark
      }
      this.fileRecordList = historicalData.fileRecordList
      if (historicalData.authorizedCity) {
        this.authorizedCityCode =
          historicalData.authorizedCity.authorizedCityCode
      }
      console.log('historicalData', historicalData)
      //匹配warehouse
      let { warehouse, assessmentfFiling, channel } = historicalData
      this.warehouse = { ...this.warehouse, ...warehouse }
      this.assessmentfFiling = {
        ...this.assessmentfFiling,
        ...assessmentfFiling
      }
      this.channel = channel
    },
    submit(status, isSession = false) {
      try {
        this.loading = true
        let form = {
          declarationForm: {},
          bossJoinModel: {
            dealerBoss: {}, //基础信息
            dealerBusinessLicense: {}, //营业执照开票资料
            dealerJoinCity: {}, //转让城市情况
            dealerTeamStructure: {}, //团队结构
            dealerWarehouseCourier: {} //货物及快递收货信息
          } //现经销商加盟情况
        }
        if (isSession) {
          form['declarationForm'] = Object.assign({}, this.declarationForm, {
            title: `经销权转让申请（${this.city},${this.realName},转让品牌：${
              this.brandName
            }）${this.channel === '10' ? '(直营)' : ''}`
          })
        } else {
          form['declarationForm'] = Object.assign(
            {},
            this.declarationForm,
            { status: status },
            {
              title: `经销权转让申请（${this.city},${this.realName},转让品牌：${
                this.brandName
              }）${this.channel === '10' ? '(直营)' : ''}`
            }
          )
        }
        form['fileRecordList'] = this.fileRecordList
        //原经销商信息dealerFlow
        const p1 = new Promise((resolve, reject) => {
          const dealerFlowForm = this.$refs['dealerFlow'].$refs['form']
          const formData = this.$refs['dealerFlow'].form
          const { brandRegionCode } = formData
          formData.brandRegionCode = brandRegionCode || null
          const callback = () => {
            form = Object.assign({}, form, formData)
            resolve()
          }
          if (status) {
            dealerFlowForm.validate(valid => {
              if (valid) {
                console.log('dealerFlow', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            dealerFlowForm.clearValidate()
            callback()
          }
        })
        //现经销商个人基础信息newDealerFlow
        const p2 = new Promise((resolve, reject) => {
          const newDealerFlowForm = this.$refs['newDealerFlow'].$refs['form']
          const formData = this.$refs['newDealerFlow'].form
          const callback = () => {
            let keyArr = [
              'channelType',
              'secondaryChannelType',
              'thirdChannelType',
              'ifRelevance',
              'joinNature'
            ]
            for (const key in formData) {
              if (keyArr.includes(key)) {
                form['bossJoinModel'][key] = formData[key]
              } else {
                form['bossJoinModel']['dealerBoss'][key] = formData[key]
              }
            }
            resolve()
          }
          if (status) {
            newDealerFlowForm.validate(valid => {
              if (valid) {
                console.log('newDealerFlow', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            newDealerFlowForm.clearValidate()
            callback()
          }
        })
        //现经销商营业执照信息newDealerLicenseFlow
        const p3 = new Promise((resolve, reject) => {
          const newDealerLicenseFlowForm =
            this.$refs['newDealerLicenseFlow'].$refs['form']
          const formData = this.$refs['newDealerLicenseFlow'].form
          const commitmentFile =
            this.$refs['newDealerLicenseFlow'].commitmentFile
          console.log('newDealerLicenseFlow', formData)
          const callback = () => {
            for (const key in formData) {
              if (key !== 'businessLicenseFile') {
                form['bossJoinModel']['dealerBusinessLicense'][key] =
                  formData[key]
              } else {
                if (formData[key].length) {
                  form['bossJoinModel'][key] = Object.assign(
                    {},
                    formData[key][0],
                    { functionAttribution: key }
                  )
                } else {
                  form['bossJoinModel'][key] = null
                }
              }
            }
            if (commitmentFile.length) {
              form['commitmentFile'] = Object.assign({}, commitmentFile[0], {
                functionAttribution: 'commitmentFile'
              })
            } else {
              form['commitmentFile'] = null
            }
            resolve()
          }
          if (status) {
            newDealerLicenseFlowForm.validate(valid => {
              if (valid) {
                console.log('newDealerLicenseFlow', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            newDealerLicenseFlowForm.clearValidate()
            callback()
          }
        })
        //现经销商开票信息newDealerInvoiceFlow
        const p4 = new Promise((resolve, reject) => {
          const newDealerInvoiceFlowForm =
            this.$refs['newDealerInvoiceFlow'].$refs['form']
          const formData = this.$refs['newDealerInvoiceFlow'].form
          const callback = () => {
            const arr = [
              'businessLicenseName',
              'creditCode',
              'businessLicenseAddress',
              'taxpayerProveFile',
              'bankFile'
            ]
            for (const key in formData) {
              if (arr.indexOf(key) === -1) {
                form['bossJoinModel']['dealerBusinessLicense'][key] =
                  formData[key]
                form['bossJoinModel']['dealerBusinessLicense'][
                  'bankAccountName'
                ] = this.license['businessLicenseName']
              } else {
                if (key === 'taxpayerProveFile' || key === 'bankFile') {
                  if (formData[key].length) {
                    form['bossJoinModel'][key] = Object.assign(
                      {},
                      formData[key][0],
                      { functionAttribution: key }
                    )
                  } else {
                    form['bossJoinModel'][key] = null
                  }
                }
              }
            }
            resolve()
          }
          if (status) {
            newDealerInvoiceFlowForm.validate(valid => {
              if (valid) {
                console.log('newDealerInvoiceFlow', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            newDealerInvoiceFlowForm.clearValidate()
            callback()
          }
        })
        //转让城市情况cityTransferFlow
        const p5 = new Promise((resolve, reject) => {
          const cityTransferFlowForm =
            this.$refs['cityTransferFlow'].$refs['form']
          const formData = this.$refs['cityTransferFlow'].form
          const callback = () => {
            form['bossJoinModel']['dealerJoinCity'] = formData
            form['bossJoinModel']['shopCreateType'] = formData.createShopType
            resolve()
          }
          if (status) {
            cityTransferFlowForm.validate(valid => {
              if (valid) {
                console.log('cityTransferFlow', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            cityTransferFlowForm.clearValidate()
            callback()
          }
        })
        //目前团队结构teamFlow
        const p6 = new Promise((resolve, reject) => {
          const teamFlowForm = this.$refs['teamFlow'].$refs['form']
          const formData = this.$refs['teamFlow'].form
          const callback = () => {
            form['bossJoinModel']['dealerTeamStructure'] = formData
            resolve()
          }
          if (status) {
            teamFlowForm.validate(valid => {
              if (valid) {
                console.log('teamFlow', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            teamFlowForm.clearValidate()
            callback()
          }
        })
        //转让事项确认transferFlow
        const p7 = new Promise((resolve, reject) => {
          const transferFlowForm = this.$refs['transferFlow'].$refs['form']
          const formData = this.$refs['transferFlow'].form
          const fieldNameArr = [
            'idCardFileList',
            'signChangeFileList',
            'promiseFileList',
            'changeFileList',
            'bankFlowFileList'
          ]
          const callback = () => {
            for (const key in formData.list[0]) {
              if (fieldNameArr.indexOf(key) == -1) {
                form[key] = formData.list[0][key]
              } else {
                form[key] = formData.list[0][key]
                if (form[key].length) {
                  form[key] = form[key].map(item => {
                    return {
                      ...item,
                      functionAttribution: key
                    }
                  })
                } else {
                  form[key] = null
                }
              }
            }
            resolve()
          }
          if (status) {
            transferFlowForm.validate(valid => {
              if (valid) {
                console.log('transferFlow', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            transferFlowForm.clearValidate()
            callback()
          }
        })
        //原经销商门店信息dealerStoreFlow
        const p8 = new Promise((resolve, reject) => {
          if (form['shopChange']) {
            const dealerStoreFlowForm =
              this.$refs['dealerStoreFlow'].$refs['form']
            const formData = this.$refs['dealerStoreFlow'].form
            const callback = () => {
              let arr = []
              let kaArr = []
              formData.list.map(item => {
                if (item.shopChange) {
                  let {
                    id,
                    shopType,
                    contactPerson = '',
                    contactPersonIdcard = '',
                    contactPersonPhone = '',
                    shopTel,
                    managementModel
                  } = item
                  let shopObj = {
                    id,
                    shopType,
                    shopTel,
                    managementModel,
                    contactPerson,
                    contactPersonIdcard,
                    contactPersonPhone
                  }
                  arr.push(shopObj)
                  kaArr.push(item.shopType)
                }
              })
              form['shopList'] = arr
              if (kaArr.length) {
                form['storeType'] = kaArr.join(',')
              }
              if (status) {
                if (form['shopList'].length > 0) {
                  resolve()
                } else {
                  reject('需要转让的门店不能为空')
                }
              } else {
                resolve()
              }
            }
            if (status) {
              dealerStoreFlowForm.validate(valid => {
                console.log('dealerStoreFlow', valid)
                if (valid) {
                  callback()
                } else {
                  this.loading = false
                  this.$nextTick(positionToError)
                  reject('必填项不能为空或格式有误，请检查填写内容')
                }
              })
            } else {
              dealerStoreFlowForm.clearValidate()
              callback()
            }
          } else {
            form['shopList'] = []
            form['storeType'] = ''
            resolve()
          }
        })
        //匹配 warehouse
        const p9 = new Promise((resolve, reject) => {
          const callback = () => {
            form['warehouse'] = Object.assign({}, this.warehouse)
            resolve()
          }
          if (status) {
            this.$refs['warehouse'].validate(valid => {
              if (valid) {
                console.log('warehouse', valid)
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            this.$refs['warehouse'].clearValidate()
            callback()
          }
        })

        //品牌推广费及品牌保证金收取brandCostFlow
        const p10 = new Promise((resolve, reject) => {
          const brandCostFlowForm = this.$refs['brandCostFlow'].$refs['form']
          const formData = this.$refs['brandCostFlow'].form
          // console.log('品牌',formData)
          const callback = () => {
            let arr = []
            arr = formData.list.map(item => {
              console.log(item)
              let list = item.fileRecordList.map(sitem => {
                return {
                  ...sitem,
                  functionAttribution: 'fileRecordList'
                }
              })
              return {
                ...item,
                fileRecordList: list
              }
            })
            // console.log(arr)
            form['bossJoinModel']['dealerMarginRecordList'] = arr
            resolve()
          }
          if (status) {
            brandCostFlowForm.validate(valid => {
              if (valid) {
                console.log('brandCostFlow', valid)
                let types = []
                formData.list.map(item => {
                  types.indexOf(item.type)===-1 &&types.push(item.type)
                })
                if(types.length!==formData.list.length){
                  this.$nextTick(positionToError)
                  reject('品牌推广费及品牌保证金收取类型不能重复')
                }
                callback()
              } else {
                this.loading = false
                this.$nextTick(positionToError)
                reject('必填项不能为空或格式有误，请检查填写内容')
              }
            })
          } else {
            brandCostFlowForm.clearValidate()
            callback()
          }
        })
        // 直营 assessmentfFiling
        const p11 = new Promise((resolve, reject) => {
          if (this.channel !== '10') {
            resolve()
          } else {
            const callback = () => {
              const keys = [
                'storeDepositFileList',
                'businessLicenseFileList',
                'storeSettlementFileList'
              ]
              const obj = {
                storeDepositFileList: [],
                businessLicenseFileList: [],
                storeSettlementFileList: []
              }
              for (const key in this.assessmentfFiling) {
                if (keys.indexOf(key) !== -1) {
                  if (this.assessmentfFiling[key]?.length) {
                    let list = this.assessmentfFiling[key].map(item => {
                      return {
                        ...item,
                        functionAttribution: key
                      }
                    })
                    obj[key] = list
                  }
                }
              }
              form['assessmentfFiling'] = Object.assign(
                {},
                this.assessmentfFiling,
                obj
              )
              resolve()
            }
            if (status) {
              this.$refs['assessmentfFiling'].validate(valid => {
                if (valid) {
                  console.log('assessmentfFiling', valid)
                  callback()
                } else {
                  this.loading = false
                  this.$nextTick(positionToError)
                  reject('必填项不能为空或格式有误，请检查填写内容')
                }
              })
            } else {
              this.$refs['assessmentfFiling'].clearValidate()
              callback()
            }
          }
        })
        if (this.channel === '10') {
          form['channel'] = '10'
        } else {
          form['channel'] = '20'
        }
        Promise.all([
          p1,
          p2,
          p3,
          p4,
          p5,
          p6,
          p7,
          p8,
          p9,
          p10,
          this.channel === '10' ? p11 : ''
        ])
          .then(res => {
            if (!this.businessLicense) {
              form['newBusinessLicenseId'] = ''
              if (
                this.status === 20 &&
                JSON.stringify(this.historicalData) !== '{}'
              ) {
                const { bossJoinModel } = this.historicalData
                const { dealerBoss, dealerBusinessLicense } = bossJoinModel
                const { licenseNumber = '' } = dealerBusinessLicense
                if (dealerBoss['bossNumber']) {
                  form['bossJoinModel']['dealerBoss']['bossNumber'] =
                    dealerBoss['bossNumber']
                }
                if (licenseNumber) {
                  form['bossJoinModel']['dealerBusinessLicense'][
                    'licenseNumber'
                  ] = licenseNumber
                }
              }
            } else {
              form['newBusinessLicenseId'] = this.businessLicense.id
            }

            console.log('验证通过', form)
            if (isSession) {
              if (this.channel === '10') {
                setSession(
                  'dealershipTransferDirect',
                  JSON.stringify({ isCache: isSession, ...form })
                )
              } else {
                setSession(
                  'dealershipTransfer',
                  JSON.stringify({ isCache: isSession, ...form })
                )
              }
              return
            }
            saveFranchiseChange(form).then(res => {
              if (res.code === 0) {
                this.isCache = false
                if (status) {
                  this.$alert('流程发布中', '提示', {
                    confirmButtonText: '确定',
                    type: 'success',
                    callback: action => {
                      this.$router.replace({
                        name: 'myApply'
                      })
                    }
                  })
                } else {
                  this.$message.success('保存成功')
                  this.$router.replace({
                    name: 'draft'
                  })
                }
              } else {
                this.$message.error(res.msg)
              }
            })
          })
          .catch(e => {
            console.log(e)
            this.$message.error(e)
          })
          .finally(() => {
            this.loading = false
          })
      } catch (e) {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/flowForm.scss';
</style>
