<template>
  <FlowForm
    ref="flowFormRef"
    :model="form"
    :on-submit="onSubmit"
    :get-detail="getDetail"
    title="客户主数据修改申请表"
    :tips="[
      '1、客户主数据修改申请表：针对实控人信息/执照/门店等信息需要修改',
      '2、经销商更换执照，如果是原执照不再使用，请把对应的送达方都选上，否则没有选择的送达方还是以原执照对应',
      '如：经销商用A执照代理V6和经典2个送达方，现在A执照注销了，换成B执照，那么如果经销商在送达方只选择V6的送达方变更，那么流程归档后经典的送达方还是挂在注销的A执照下面',
      '3、经销商现需要用2个执照代理，那么更换执照只需要把单独代理的送达方选上即可',
      '如：经销商原来用A执照代理V6和经典2个送达方，现在想用B执照单独代理V6，那么送达方只需要选择V6的送达方更换执照即可',
      '4、门店地址更换仅针对系统的门店地址录入错误需要修改，如果经典经销商想更换门店位置并且需要重新装修，本流程不适用，请走“慕思经典经销商门店管理申请表”+“ 经销商撤店申请流程”',
      '5、门店资料更改只能选择正在营业状态的门店',
      '6、实控人信息修正只针对原实控人下面的具体信息做修正（如手机号、籍贯等），因此身份证号和姓名不更新',
      '7、如合伙人需求更换，应属于转让范畴，请选择【经销权转让申请表】'
    ]">
    <RequiredFormItemInput
      v-for="(value, key) in {
        title: '标题',
        orderNumber: '单号',
        zsOrderNumber: '招商单号'
      }"
      :key="key"
      delay-update
      class="formItem"
      :prop="`dealerDeclarationForm[${key}]`"
      :value="key == 'title' ? flowTitle : form.dealerDeclarationForm[key]"
      :label="value"
      placeholder="/"
      disabled
      :required="false" />
    <RequiredFormItemInput
      v-for="(value, key) in {
        finalLicenseNumber: '最终售达方编号',
        finalBusinessLicenseName: '最终售达方名称'
      }"
      :key="`dealerDeclarationForm${key}`"
      delay-update
      class="formItem"
      :prop="`[${key}]`"
      :value="form[key]"
      :label="value"
      placeholder="/"
      disabled
      :required="false" />
    <RequiredFormItemInput
      v-for="(value, key) in {
        optJobNumber: '提单人工号',
        optUserName: '提单人',
        optDeptName: '提单部门',
        optTime: '提单日期'
      }"
      :key="`dealerDeclarationForm${key}`"
      v-model.trim="form.dealerDeclarationForm[key]"
      delay-update
      class="formItem"
      :prop="`dealerDeclarationForm[${key}]`"
      :label="value"
      disabled
      placeholder="/" />
    <RequiredModuleFormItemSelect
      ref="applyTypeRef"
      v-model="form.applyTypeString"
      type="shop_application_type"
      prop="applyTypeString"
      class="formItem"
      label="申请类型"
      value-key="content"
      @onChangeItem="onApplyTypeChange" />
    <RequiredFormItemSelect
      ref="changeReasonTypeRef"
      v-model="form.changeReasonType"
      prop="changeReasonType"
      class="formItem"
      label="变更原因"
      label-key="label"
      value-key="value"
      disabled-key="disabled"
      :data-list="changeReasonList"
      :rules="changeReasonRules"
      @change="onChangeReason" />
    <RequiredFormItemInput
      v-model.trim="form.changeReasonText"
      delay-update
      class="formItem"
      prop="changeReasonText"
      label="变更原因具体描述" />

    <RequiredFormItemSelect
      ref="dealerBusinessLicenseRef"
      v-model="form.dealerBusinessLicenseId"
      class="formItem"
      prop="dealerBusinessLicenseId"
      label="售达方"
      label-key="licenseNumber"
      value-key="id"
      :select-props="{
        filterable: true,
        remote: true,
        'popper-class': 'custom-business-select'
      }"
      :total-count="businessCountTotal"
      :get-data-list="getBusinessLicense"
      :get-next-list="getBusinessLicense"
      @onOptionsListNotFound="onNotFoundBusinessLicense"
      @change="onLicenseChange">
      <template v-slot="{ scope }">
        <span>{{ scope.row.licenseNumber }}</span>
        <small>{{ scope.row.businessLicenseName }}</small>
      </template>
    </RequiredFormItemSelect>
    <RequiredFormItemInput
      v-model.trim="form.dealerBusinessLicenseName"
      delay-update
      class="formItem"
      prop="dealerBusinessLicenseName"
      label="售达方名称"
      :input-props="{ readonly: true }"
      :required="false" />
    <RequiredFormItemSelect
      ref="authorizedCityRef"
      v-model="form.authorizedCityName"
      prop="authorizedCityName"
      class="formItem"
      label="授权城市"
      :get-data-list="getAuthorizedCity"
      :getDataListParams="form.dealerBusinessLicenseId"
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      @changeSelectedItem="onAuthorizedCityChange">
      <template v-slot="{ scope }">
        <span>
          {{ `${scope.row.province}${scope.row.authorizedCityName || ''}` }}
        </span>
      </template>
    </RequiredFormItemSelect>
    <RequiredFormItemSelect
      v-model="form.marketCenter"
      prop="marketCenter"
      class="formItem"
      label="营销中心"
      label-key="smallName"
      value-key="smallCode"
      :required="isClassics"
      :get-data-list="getMarketCenterList"
      :getDataListParams="authorizedCityInfo" />
    <RequiredFormItemSelect
      v-model="form.brandRegionCode"
      prop="brandRegionCode"
      class="formItem"
      label="品牌区域编码"
      label-key="brandRegionCode"
      value-key="brandRegionCode"
      :required="isClassics"
      :get-data-list="getBrandModeList"
      :getDataListParams="`${form.bigBrandId},${authorizedCityInfo.provinceCode},${authorizedCityInfo.cityCode},${authorizedCityInfo.areaCode},${authorizedCityInfo.townCode}`" />
    <RequiredFormItemSelect
      v-if="changeReasonTypeMap([2, 3, 4, 6])"
      v-model="form.senderListIds"
      class="large formItem"
      prop="senderListIds"
      label="需要更改的送达方"
      placeholder="请选择需要更改的送达方"
      labelKey="shopBrandNumber"
      valueKey="id"
      filterable
      multiple
      labelTip="(1、原执照不再使用请把所有送达方都选上2、用两个执照合作请选择需要变更的送达方)"
      :getDataList="getSenderList"
      :getDataListParams="form.dealerBusinessLicenseId"
      :disabled="!form.dealerBusinessLicenseId"
      @onOptionsListNotFound="onSendersNotFound">
      <template #default="{ scope }">
        <label>{{ scope.row.senderName }}</label>
        <label>{{ scope.row.shopBrandNumber }}</label>
      </template>
    </RequiredFormItemSelect>
    <RequiredFormItemSelect
      v-if="changeReasonTypeMap([9])"
      class="large formItem"
      prop="seriesList"
      label="需要更改的授权系列"
      :value="form.seriesList"
      :select-props="{
        labelKey: 'seriesName',
        valueKey: 'seriesId',
        multiple: true,
        clearable: true
      }"
      :rules="seriesListRules"
      :getDataListParams="`${form.dealerBusinessLicenseId},${form.authorizedCityCode}`"
      :get-data-list="getAuthorizedCityBrandList"
      :value-translate="
        list =>
          list && Object.prototype.toString.call(list) === '[object Array]'
            ? list.map(item => item.seriesId)
            : []
      "
      @onOptionsListNotFound="(search, callback) => callback(form.seriesList)"
      @change="
        list => {
          if (list.length == 0) form.seriesList = []
        }
      "
      @changeSelectedItem="
        list => {
          if (list) form.seriesList = list
        }
      " />
    <template v-if="changeReasonTypeMap([2, 3, 4, 5, 6, 9])">
      <HeaderTitle
        id="anchor-before-yyzzxx"
        style="margin-top: 20px"
        title="变更前"
        :hidden-divider="false" />
      <HeaderTitle style="margin-top: 20px" title="经销商及营业执照信息" />

      <RequiredFormItemInput
        v-for="(value, key) in {
          creditCode: '统一社会信用代码',
          businessLicenseName: '营业执照名称'
        }"
        :key="`yyzzbefore${key}`"
        v-model.trim="form.beforeChangedealerBusinessLicense[key]"
        disabled
        delay-update
        class="formItem"
        :label="value"
        :required="false" />
      <RequiredModuleFormItemSelect
        v-model="form.beforeChangedealerBusinessLicense.businessLicenseType"
        class="formItem"
        prop="businessLicenseType"
        label="营业执照类型"
        type="business_license_type"
        disabled
        :required="false" />
      <RequiredFormItemInput
        v-if="changeReasonTypeMap([2, 4, 6, 9])"
        v-model.trim="form.beforeChangedealerBusinessLicense.registeredCapital"
        disabled
        delay-update
        class="formItem"
        prop="beforeChangedealerBusinessLicense.registeredCapital"
        label="注册资本（万元）"
        :validations="['money']" />
      <RequiredFormItemInput
        v-for="(value, key) in {
          legalPersonName: '法人',
          legalPersonContact: '法人联系方式',
          registerDate: '注册日期',
          businessTerm: '营业期限',
          businessLicenseAddress: '营业执照地址（住所）',
          businessScope: '经营范围',
          registrationAuthority: '登记机关',
          certificatesRecAddress: '函证接收地址'
        }"
        :key="`yyzzbefore${key}`"
        v-model.trim="form.beforeChangedealerBusinessLicense[key]"
        disabled
        delay-update
        class="formItem"
        :label="value"
        :required="false" />

      <HeaderTitle
        id="anchor-before-jxsgrjcxx"
        style="margin-top: 20px"
        title="经销商个人基础信息" />

      <RequiredFormItemInput
        v-for="(value, key) in {
          idCard: '身份证',
          realName: '实际控股人',
          contactInfo: '实际控股人联系方式',
          age: '年龄',
          nativePlace: '籍贯'
        }"
        :key="`grxxbefore${key}`"
        v-model.trim="form.beforeChangedealerBusinessLicense[key]"
        disabled
        delay-update
        class="formItem"
        :label="value"
        :required="false" />
      <RequiredModuleFormItemSelect
        v-for="userItem in [
          { props: 'sex', type: 'sex', label: '性别' },
          { props: 'education', type: 'education', label: '学历' },
          {
            props: 'maritalStatus',
            type: 'marital_status',
            label: '婚姻状况'
          }
        ]"
        :key="`beforeChangedealerBusinessLicense${userItem.props}`"
        v-model="form.beforeChangedealerBusinessLicense[userItem.props]"
        class="formItem"
        disabled
        :prop="`beforeChangedealerBusinessLicense${userItem.props}`"
        :label="userItem.label"
        :type="userItem.type"
        :required="false" />
      <HeaderTitle
        id="anchor-before-kpxx"
        style="margin-top: 20px"
        title="开票信息" />
      <RequiredFormItemInput
        v-for="(value, key) in {
          businessLicenseName: '账户名称',
          creditCode: '纳税人识别号',
          businessLicenseAddress: '营业执照地址',
          taxpayerPhone: '纳税人电话'
        }"
        v-if="changeReasonTypeMap([2, 4, 6, 9])"
        :key="`kpxxbefore${key}`"
        v-model.trim="form.beforeChangedealerBusinessLicense[key]"
        disabled
        delay-update
        class="formItem"
        :required="false"
        :prop="`beforeChangedealerBusinessLicense[${key}]`"
        :label="value"
        :validations="validations[key] || []" />
      <RequiredModuleFormItemSelect
        v-model="form.beforeChangedealerBusinessLicense.payTaxesType"
        class="formItem"
        prop="payTaxesType"
        label="纳税类型"
        type="pay_taxes_type"
        disabled
        :required="false" />
      <RequiredModuleFormItemSelect
        v-model="form.beforeChangedealerBusinessLicense.billType"
        class="formItem"
        prop="billType"
        label="发票类型"
        module="public"
        type="invoice_type"
        disabled
        :required="false"
        :get-data-list-params="{ ifDelete: '-1' }" />
      <RequiredBankFormItemSelect
        v-model="form.beforeChangedealerBusinessLicense.bank"
        :required="false"
        :prop="`beforeChangedealerBusinessLicense.bank`"
        class="formItem"
        label="开户行"
        disabled />
      <RequiredFormItemInput
        v-for="(value, key) in {
          bankCode: '开户行编码',
          bankAccount: '银行账号',
          personLiableName: '税务负责人',
          personLiablePhone: '税务负责人电话',
          eMail: '接收电子发票邮箱'
        }"
        :key="`kpxxbefore${key}`"
        v-model.trim="form.beforeChangedealerBusinessLicense[key]"
        disabled
        delay-update
        class="formItem"
        :label="value"
        :required="false" />

      <HeaderTitle
        v-if="changeReasonTypeMap([2, 3, 4, 5, 6, 9])"
        id="anchor-after-yyzzxx"
        style="margin-top: 20px"
        title="变更后">
      </HeaderTitle>
      <HeaderTitle
        v-if="changeReasonTypeMap([2, 4, 6, 9])"
        style="margin-top: 20px"
        title="经销商及营业执照信息更改" />

      <template
        v-if="
          changeReasonTypeMap([4, 9]) &&
          $route.path == '/flow/customerMainChange'
        ">
        <RequiredFormItemUploadFile
          v-model="businessLicenseFile"
          props="businessLicenseFile"
          class="formItem"
          :required="false"
          label="上传营业执照"
          limit-size="4"
          :on-preview="onPreview">
          <div slot="describe" style="color: red">
            先上传营业执照可智能识别出营业执照信息。
          </div>
          <div slot="tip">营业执照图片不能超过2M</div>
        </RequiredFormItemUploadFile>
      </template>

      <template v-if="changeReasonTypeMap([2, 4, 6, 9])">
        <RequiredFormItemInput
          v-for="(value, key) in {
            creditCode: '统一社会信用代码',
            businessLicenseName: '营业执照名称'
          }"
          :key="`yyzzafter${key}`"
          v-model.trim="form.afterChangeDealerBusinessLicense[key]"
          delay-update
          class="formItem"
          :prop="`afterChangeDealerBusinessLicense[${key}]`"
          :label="value"
          :validations="validations[key] || []"
          :rules="creditCodeRules"
          :disabled="key == `creditCode` && changeReasonTypeMap([2, 6])"
          :formatter="value => value.replace(/\s+/g, '')" />
        <RequiredModuleFormItemSelect
          v-model="form.afterChangeDealerBusinessLicense.businessLicenseType"
          class="formItem"
          prop="afterChangeDealerBusinessLicense.businessLicenseType"
          label="营业执照类型"
          type="business_license_type" />
        <RequiredFormItemInput
          v-model.trim="form.afterChangeDealerBusinessLicense.registeredCapital"
          delay-update
          class="formItem"
          prop="afterChangeDealerBusinessLicense.registeredCapital"
          label="注册资本（万元）"
          :input-props="{
            type: 'number'
          }" />
        <template
          v-for="(value, key) in {
            legalPersonName: '法人',
            legalPersonContact: '法人联系方式',
            registerDate: '注册日期',
            businessTerm: '营业期限',
            businessLicenseAddress: '营业执照地址（住所）',
            businessScope: '经营范围',
            registrationAuthority: '登记机关',
            certificatesRecAddress: '函证接收地址'
          }">
          <component
            :is="
              key === 'registerDate'
                ? 'RequiredFormItemDatePicker'
                : 'RequiredFormItemInput'
            "
            v-model.trim="form.afterChangeDealerBusinessLicense[key]"
            delay-update
            class="formItem"
            :prop="`afterChangeDealerBusinessLicense[${key}]`"
            :label="value"
            :validations="validations[key] || []"
            :disabled="key == `legalPersonName` && changeReasonTypeMap([6])" />
        </template>
      </template>

      <template v-if="changeReasonTypeMap([3, 5])">
        <HeaderTitle
          id="anchor-after-jxsgrjcxx"
          style="margin-top: 20px"
          title="经销商个人基础信息更改" />
        <RequiredFormItemInput
          v-for="(value, key) in {
            idCard: '身份证',
            realName: '实际控股人',
            contactInfo: '实际控股人联系方式'
          }"
          :key="`grxxafter${key}`"
          v-model.trim="form.afterChangeDealerBusinessLicense[key]"
          delay-update
          class="formItem"
          :prop="`afterChangeDealerBusinessLicense[${key}]`"
          :label="value"
          :validations="validations[key] || []"
          :disabled="key != 'contactInfo' && changeReasonTypeMap([5])" />
        <RequiredFormItemInput
          v-for="(value, key) in {
            age: '年龄',
            nativePlace: '籍贯'
          }"
          :key="`grxxafter${key}`"
          v-model.trim="form.afterChangeDealerBusinessLicense[key]"
          delay-update
          class="formItem"
          :prop="`afterChangeDealerBusinessLicense[${key}]`"
          :label="value"
          :validations="validations[key] || []" />
        <RequiredModuleFormItemSelect
          v-for="userItem in [
            { props: 'sex', type: 'sex', label: '性别' },
            { props: 'education', type: 'education', label: '学历' },
            {
              props: 'maritalStatus',
              type: 'marital_status',
              label: '婚姻状况'
            }
          ]"
          :key="`afterChangeDealerBusinessLicense.${userItem.props}`"
          v-model="form.afterChangeDealerBusinessLicense[userItem.props]"
          class="formItem"
          :prop="`afterChangeDealerBusinessLicense.${userItem.props}`"
          :label="userItem.label"
          :type="userItem.type"
          :required="userItem.props == 'sex'" />
      </template>

      <template v-if="changeReasonTypeMap([2, 4, 6, 9])">
        <HeaderTitle
          id="anchor-after-kpxx"
          style="margin-top: 20px"
          title="开票信息更改" />
        <RequiredFormItemInput
          v-for="(value, key) in {
            businessLicenseName: '账户名称',
            creditCode: '纳税人识别号',
            businessLicenseAddress: '营业执照地址',
            taxpayerPhone: '纳税人电话'
          }"
          :key="`kpxxafter${key}`"
          v-model.trim="form.afterChangeDealerBusinessLicense[key]"
          delay-update
          class="formItem"
          :prop="`afterChangeDealerBusinessLicense[${key}]`"
          :label="value"
          :validations="validations[key] || []"
          :disabled="['businessLicenseName', 'creditCode'].includes(key)"
          :formatter="
            key == 'businessLicenseName'
              ? value => value.replace(/\s+/g, '')
              : null
          " />
        <RequiredModuleFormItemSelect
          v-model="form.afterChangeDealerBusinessLicense.payTaxesType"
          class="formItem"
          prop="afterChangeDealerBusinessLicense.payTaxesType"
          label="纳税类型"
          type="pay_taxes_type" />
        <RequiredModuleFormItemSelect
          v-model="form.afterChangeDealerBusinessLicense.billType"
          class="formItem"
          prop="afterChangeDealerBusinessLicense.billType"
          label="发票类型"
          module="public"
          type="invoice_type"
          disabled-key="ifDelete"
          :get-data-list-params="{ ifDelete: '-1' }"
          :filter-data-list="dealerBusinessLicenseBillTypeFilterDataList" />
        <RequiredBankFormItemSelect
          v-model="form.afterChangeDealerBusinessLicense.bank"
          :prop="`afterChangeDealerBusinessLicense.bank`"
          class="formItem"
          label="开户行"
          :validations="validations['bank'] || []"
          @changeSelectedItem="onChangeSelectedItemBank" />
        <RequiredFormItemInput
          v-for="(value, key) in {
            bankCode: '开户行编码',
            bankAccount: '银行账号',
            personLiableName: '税务负责人',
            personLiablePhone: '税务负责人电话',
            eMail: '接收电子发票邮箱'
          }"
          :key="`kpxxafter${key}`"
          v-model.trim="form.afterChangeDealerBusinessLicense[key]"
          delay-update
          class="formItem"
          :disabled="['bankCode'].includes(key)"
          :prop="`afterChangeDealerBusinessLicense[${key}]`"
          :label="value"
          :validations="validations[key] || []" />
      </template>

      <div>
        <RequiredFormItemUploadFile
          v-model="form.newBusinessLicenseBankDetailFileList"
          class="formItem"
          :required="false"
          prop="newBusinessLicenseBankDetailFileList"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview">
          <div slot="describe">新执照对应的公户汇款流水单(品推费/保证金)</div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>

        <RequiredFormItemUploadFile
          v-model="form.bossChangeAgreementFileList"
          class="formItem"
          :required="false"
          prop="bossChangeAgreementFileList"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview">
          <div slot="describe">实控人更换协议申请书</div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>

        <RequiredFormItemUploadFile
          v-model="form.taxpayerProveFileList"
          class="formItem"
          :required="changeReasonTypeMap([2, 4, 6, 9])"
          prop="taxpayerProveFileList"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview">
          <div slot="describe">
            1：一般纳税人登记证 或2：税务登记事项通知书或
            3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>

        <RequiredFormItemUploadFile
          v-model="form.bankFileList"
          class="formItem"
          :required="false"
          prop="bankFileList"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview">
          <div slot="describe">开户证明或者网银截图(能显示开户银行全称)</div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>

        <RequiredFormItemUploadFile
          v-if="changeReasonTypeMap([2])"
          v-model="form.newBusinessLicenseFileList"
          class="formItem"
          :required="false"
          prop="newBusinessLicenseFileList"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview">
          <div slot="describe">新营业执照和法人变更协议</div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          v-if="changeReasonTypeMap([4, 9])"
          v-model="form.businessLicenseFileList"
          class="formItem"
          :required="false"
          prop="businessLicenseFileList"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview">
          <div slot="describe">营业执照照片</div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          v-if="changeReasonTypeMap([3, 5])"
          v-model="form.actualControllerPromiseFileList"
          class="formItem"
          :required="changeReasonTypeMap([5])"
          prop="actualControllerPromiseFileList"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview">
          <div slot="describe" style="margin-bottom: 10px">
            <p>实控人承诺函</p>
            <el-button @click="openActualFile"> 下载模板 </el-button>
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
      </div>
    </template>

    <div v-if="changeReasonTypeMap([1, 7, 8])">
      <HeaderTitle
        id="anchor-before-mdxx"
        style="margin-top: 20px"
        title="变更前" />
      <HeaderTitle style="margin-top: 20px" title="门店信息" />
      <el-table
        fit
        border
        :data="[form.beforeDealerShop || {}]"
        style="width: 100%">
        <el-table-column min-width="180" prop="id" label="门店名称">
          <template v-slot="{ row, column }">
            <RequiredFormItemSelect
              v-model="row.id"
              class="formItem"
              prop="beforeDealerShop.id"
              label-key="shopName"
              value-key="id"
              :label="column.label"
              :show-label="false"
              :get-data-list="getShopList"
              :getDataListParams="`${form.dealerBusinessLicenseId},${form.authorizedCityCode}`"
              @onChangeItem="onShopChange" />
          </template>
        </el-table-column>
        <el-table-column prop="parentShopNumber" label="物理店编号" width="160">
          <RequiredFormItemInput
            v-model="scope.row.parentShopNumber"
            slot-scope="scope"
            delay-update
            placeholder="系统生成"
            :show-label="false"
            :required="false"
            prop="beforeDealerShop.parentShopNumber"
            label="物理店编号"
            type="number"
            disabled />
        </el-table-column>
        <el-table-column min-width="120" prop="shopNumber" label="门店编号" />
        <el-table-column min-width="180" prop="longitude" label="门店经纬度">
          <template v-slot="{ row }">
            <span>{{ row.longitude }},{{ row.latitude }}</span>
          </template>
        </el-table-column>

        <el-table-column min-width="180" prop="storeNature">
          <template v-slot:header>
            <span>店态性质</span>
            <i
              style="color: #000; cursor: pointer"
              :title="TipsText.storeNatureTipsText"
              class="el-icon-question" />
          </template>
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-model="row.storeNature"
              disabled
              class="formItem"
              type="shop_nature_code"
              :label="column.label"
              :show-label="false" />
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="渠道类型" min-width="180">
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-model="row.channel"
              disabled
              class="formItem"
              type="cooperation_code"
              :show-label="false"
              :label="column.label" />
          </template>
        </el-table-column>
        <el-table-column min-width="180" prop="shopType">
          <template v-slot:header>
            <span>渠道性质</span>
            <i
              style="color: #000; cursor: pointer"
              :title="TipsText.shopTypeTipsText"
              class="el-icon-question" />
          </template>
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-model="row.shopType"
              disabled
              class="formItem"
              type="store_type"
              :label="column.label"
              :show-label="false" />
          </template>
        </el-table-column>
        <el-table-column
          v-for="(labelItem, labelKey) in {
            shopTypeValue: '渠道性质二级分类',
            marketName: '卖场名称',
            marketType: '卖场类型',
            shopPositionLevel: '门店位置等级',
            province: '省份',
            city: '地级市',
            area: '区/县',
            street: '道路/街道',
            shopFloor: '门店楼层',
            shopFloorNumber: '门店铺号'
          }"
          :key="labelKey"
          min-width="120"
          :prop="labelKey"
          :label="labelItem" />
        <el-table-column min-width="200" prop="address" label="门店地址">
          <template v-slot="{ row }">
            {{ shopAddress(row) }}
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="brandName" label="门店授权品牌">
          <template v-if="form.afterDealerShopBrandList.length > 0">
            {{ brandNameJoin }}
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="shopArea" label="门店实用面积" />
        <el-table-column
          v-if="changeReasonTypeMap([8])"
          label="门店租金（万/年）"
          width="180"
          prop="shopRent" />
        <el-table-column label="门店联系方式" width="180" prop="shopTel" />
        <el-table-column label="经营模式" width="180">
          <template v-slot="{ row }">
            <RequiredModuleFormItemSelect
              v-model="row.managementModel"
              class="formItem"
              type="management_model"
              disabled />
          </template>
        </el-table-column>
        <template v-if="form.beforeDealerShop.managementModel == 20">
          <el-table-column
            v-for="(dealerLabel, dealerKey) in {
              contactPerson: '二级分销商',
              contactPersonIdcard: '二级分销商身份证',
              contactPersonPhone: '二级分销商联系方式'
            }"
            :key="dealerKey"
            min-width="180"
            :prop="dealerKey"
            :label="dealerLabel" />
        </template>
        <template v-if="changeReasonTypeMap([7, 8])">
          <el-table-column
            v-for="(labelItem, labelKey) in {
              openingTime: '开业时间',
              decorationTime: '最新装修时间'
            }"
            :key="labelKey"
            min-width="180"
            :prop="labelKey"
            :label="labelItem" />
        </template>
      </el-table>

      <HeaderTitle
        id="anchor-after-mdxx"
        style="margin-top: 20px"
        title="变更后" />
      <HeaderTitle style="margin-top: 20px" title="门店信息更改" />
      <el-table
        fit
        border
        :data="[form.afterDealerShop || {}]"
        style="width: 100%">
        <el-table-column min-width="180" prop="shopName" label="门店名称">
          <template
            v-if="
              form.afterDealerShopBrandList.length > 0 &&
              Object.keys(form.afterDealerShop).length > 0
            "
            v-slot="{ row }">
            {{
              (form.afterDealerShop.shopName = `${shopNameJoin(
                row
              )}（${brandNameJoin}）专卖店`)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="parentShopNumber" label="物理店编号" width="160">
          <RequiredFormItemInput
            v-model="scope.row.parentShopNumber"
            slot-scope="scope"
            delay-update
            placeholder="/"
            :show-label="false"
            :required="false"
            prop="parentShopNumber"
            label="物理店编号"
            type="number"
            :disabled="changeReasonTypeMap([1])" />
        </el-table-column>
        <el-table-column min-width="120" prop="shopNumber" label="门店编号" />
        <el-table-column min-width="180" prop="longitude" label="门店经纬度">
          <template v-slot="{ row }">
            <RequiredFormItem prop="afterDealerShop.longitude" class="formItem">
              <PopupForm
                ref="mapPopupRef"
                title="选择地址"
                :columns="selectLocationColumns"
                :onSubmit="
                  params => {
                    row.longitude = params.longitude
                    row.latitude = params.latitude
                    return true
                  }
                ">
                <template #trigger>
                  <span style="margin-right: 20px; font-size: 12px">
                    {{ row.longitude }},{{ row.latitude }}
                  </span>
                  <el-button
                    v-if="changeReasonTypeMap([7, 8]) && !isOA"
                    size="mini"
                    @click="openMap">
                    修改经纬度
                  </el-button>
                </template>
              </PopupForm>
            </RequiredFormItem>
          </template>
        </el-table-column>

        <el-table-column min-width="180" prop="storeNature">
          <template v-slot:header>
            <span>店态性质</span>
            <i
              style="color: #000; cursor: pointer"
              :title="TipsText.storeNatureTipsText"
              class="el-icon-question" />
          </template>
          <template v-slot="{ row }">
            <RequiredModuleFormItemSelect
              v-model="row.storeNature"
              class="formItem"
              prop="afterDealerShop.storeNature"
              type="shop_nature_code"
              disabled />
          </template>
        </el-table-column>
        <el-table-column prop="channel" label="渠道类型" min-width="180">
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-model="row.channel"
              class="formItem"
              type="cooperation_code"
              prop="afterDealerShop.channel"
              :show-label="false"
              :label="column.label"
              :disabled="changeReasonTypeMap([1, 7])" />
          </template>
        </el-table-column>
        <el-table-column min-width="180" prop="shopType" label="渠道性质">
          <template v-slot:header="{ column }">
            <span>{{ column.label }}</span>
            <i
              style="color: #000; cursor: pointer"
              :title="TipsText.shopTypeTipsText"
              class="el-icon-question" />
          </template>
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-model="row.shopType"
              class="formItem"
              prop="afterDealerShop.shopType"
              type="store_type"
              :label="column.label"
              :show-label="false"
              :select-listeners="{
                change: changeShopType
              }"
              :disabled="changeReasonTypeMap([1])"
              @onChangeItem="onShopTypeChange" />
          </template>
        </el-table-column>
        <el-table-column
          min-width="240"
          prop="shopTypeValue"
          label="渠道性质二级分类">
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-if="shopTypeMap(['SJ001', 'SJ003', 'SJ007', 'SJ008', 'SJ009'])"
              v-model="row.shopTypeValue"
              required
              class="formItem"
              value-key="content"
              type="secondary_store_type"
              prop="afterDealerShop.shopTypeValue"
              :label="column.label"
              :show-label="false"
              :parent-code="row.shopType"
              :disabled="changeReasonTypeMap([1])"
              @onChangeItem="
                () => {
                  if (shopTypeMap(['SJ001', 'SJ008'])) {
                    row.marketName = ''
                    row.marketType = ''
                  }
                }
              " />
            <RequiredFormItemInput
              v-if="shopTypeMap(['SJ005'])"
              v-model.trim="row.shopTypeValue"
              required
              class="formItem"
              prop="afterDealerShop.shopTypeValue"
              :label="column.label"
              :show-label="false"
              :disabled="!row.shopType || changeReasonTypeMap([1])" />
          </template>
        </el-table-column>
        <el-table-column min-width="240" prop="marketName" label="卖场名称">
          <template v-slot="{ row, column }">
            <RequiredFormItemInput
              v-if="!shopTypeMap(['SJ001', 'SJ008'])"
              v-model="row.marketName"
              readonly
              required
              prop="afterDealerShop.marketName"
              class="formItem"
              :label="column.label"
              :show-label="false" />
            <RequiredModuleFormItemSelect
              v-else
              v-model="row.marketName"
              class="formItem"
              value-key="content"
              type="dealer_shop.market_name"
              prop="afterDealerShop.marketName"
              :label="column.label"
              :show-label="false"
              :parent-content="row.shopTypeValue"
              :disabled="!row.shopTypeValue || changeReasonTypeMap([1])"
              :getDataListParams="{
                moduleBrand: 'C',
                ifDelete: 0,
                explainJson: form?.authorizedCityName
              }"
              @onChangeItem="
                e => {
                  row.marketType = e?.typeDetail ?? ''
                }
              " />
          </template>
        </el-table-column>
        <el-table-column label="卖场类型" prop="marketType" />
        <el-table-column
          label="门店位置等级"
          prop="shopPositionLevel"
          min-width="160">
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-if="shopTypeMap(['SJ001', 'SJ008', 'SJ009'])"
              v-model="row.shopPositionLevel"
              class="formItem"
              type="shop_level"
              prop="afterDealerShop.shopPositionLevel"
              :label="column.label"
              :show-label="false"
              :getDataListParams="{ moduleBrand: 'C' }"
              :parentCode="row.shopType === 'SJ009' ? row.shopType : '0'"
              :disabled="changeReasonTypeMap([1, 7])" />
          </template>
        </el-table-column>
        <el-table-column
          v-for="(labelItem, labelKey) in {
            province: '省份',
            city: '地级市'
          }"
          :key="labelKey"
          min-width="120"
          :prop="labelKey"
          :label="labelItem" />

        <el-table-column
          v-for="formItem in [
            { props: 'area', label: '区/县' },
            { props: 'street', label: '道路/街道' },
            { props: 'shopFloor', label: '门店楼层' },
            { props: 'shopFloorNumber', label: '门店铺号' }
          ]"
          :key="formItem.props"
          :min-width="formItem.props === 'street' ? 180 : 120"
          :prop="formItem.props"
          :label="formItem.label">
          <template v-if="formItem.props === 'street'" v-slot:header>
            <span>{{ formItem.label }}</span>
            <i
              style="color: #000; cursor: pointer"
              :title="TipsText.streetTipsText"
              class="el-icon-question" />
          </template>
          <template v-slot="{ row }">
            <RequiredFormItemInput
              v-model.trim="row[formItem.props]"
              delay-update
              class="formItem"
              :label="formItem.label"
              :show-label="false"
              :prop="`afterDealerShop.${formItem.props}`"
              :disabled="changeReasonTypeMap([1])"
              :input-props="
                formItem.props === 'street'
                  ? { type: 'textarea', autosize: true }
                  : {}
              "
              :placeholder="`请输入${formItem.label}`" />
          </template>
        </el-table-column>

        <el-table-column min-width="200" prop="address" label="门店地址">
          <template v-slot="{ row }">
            {{ shopAddress(row) }}
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="brandName" label="门店授权品牌">
          <template v-if="form.afterDealerShopBrandList.length > 0">
            {{ brandNameJoin }}
          </template>
        </el-table-column>
        <el-table-column min-width="120" prop="shopArea" label="门店实用面积">
          <template
            v-if="
              form.afterDealerShopBrandList.length > 0 &&
              changeReasonTypeMap([1])
            ">
            {{
              (form.afterDealerShop.shopArea = form.afterDealerShopBrandList
                .map(option => option.brandAreaChange)
                .reduce((a, b) => Number(a || 0) + Number(b || 0)))
            }}
          </template>
          <RequiredFormItemInput
            v-if="changeReasonTypeMap([8])"
            v-model.trim="scope.row.shopArea"
            slot-scope="scope"
            delay-update
            prop="afterDealerShop.shopArea"
            :show-label="false"
            :input-props="{ type: 'number' }" />
        </el-table-column>

        <el-table-column
          v-if="changeReasonTypeMap([8])"
          label="门店租金（万/年）"
          width="180"
          prop="shopRent">
          <RequiredFormItemInput
            v-model.trim="scope.row.shopRent"
            slot-scope="scope"
            delay-update
            prop="afterDealerShop.shopRent"
            :show-label="false"
            :input-props="{ type: 'number' }" />
        </el-table-column>
        <el-table-column label="门店联系方式" width="180" prop="shopTel">
          <RequiredFormItemInput
            v-model.trim="scope.row.shopTel"
            slot-scope="scope"
            delay-update
            prop="afterDealerShop.shopTel"
            :show-label="false"
            :input-props="{ type: 'number' }"
            :validations="['phone']"
            :disabled="changeReasonTypeMap([1])" />
        </el-table-column>
        <el-table-column label="经营模式" width="180">
          <template v-slot="{ row, column }">
            <RequiredModuleFormItemSelect
              v-model="row.managementModel"
              class="formItem"
              prop="afterDealerShop.managementModel"
              type="management_model"
              :label="column.label"
              :show-label="false"
              :disabled="changeReasonTypeMap([1])" />
          </template>
        </el-table-column>
        <template v-if="form.afterDealerShop.managementModel == 20">
          <el-table-column
            v-for="dealerItem in [
              {
                label: '二级分销商',
                props: 'contactPerson',
                validations: ['name'],
                placeholder: '请填写联系人'
              },
              {
                label: '二级分销商身份证',
                props: 'contactPersonIdcard',
                validations: ['user_id'],
                placeholder: '请填写联系人身份证'
              },
              {
                label: '二级分销商联系方式',
                props: 'contactPersonPhone',
                validations: ['phone'],
                placeholder: '请输入联系方式'
              }
            ]"
            :key="dealerItem.props"
            min-width="180"
            :prop="dealerItem.props"
            :label="dealerItem.label">
            <template v-slot="{ row, column }">
              <RequiredFormItemInput
                v-model.trim="row[dealerItem.props]"
                delay-update
                class="formItem"
                :label="column.label"
                :show-label="false"
                :prop="`afterDealerShop.${dealerItem.props}`"
                :validations="dealerItem.validations"
                :disabled="changeReasonTypeMap([1])"
                :placeholder="dealerItem.placeholder" />
            </template>
          </el-table-column>
        </template>
        <template v-if="changeReasonTypeMap([7, 8])">
          <el-table-column min-width="200" prop="openingTime" label="开业时间">
            <template v-slot="{ row, column }">
              <RequiredFormItemDatePicker
                v-model.trim="row.openingTime"
                delay-update
                class="formItem"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                prop="afterDealerShop.openingTime"
                :label="column.label"
                :show-label="false" />
            </template>
          </el-table-column>
          <el-table-column
            min-width="200"
            prop="decorationTime"
            label="最新装修时间">
            <template v-slot="{ row, column }">
              <RequiredFormItemDatePicker
                v-model.trim="row.decorationTime"
                delay-update
                class="formItem"
                type="datetime"
                format="yyyy-MM-dd HH:mm:ss"
                prop="afterDealerShop.decorationTime"
                :label="column.label"
                :show-label="false" />
            </template>
          </el-table-column>
        </template>
      </el-table>
      <template v-if="changeReasonTypeMap([1, 7])">
        <HeaderTitle
          id="anchor-mdmx"
          style="margin-top: 20px"
          title="门店明细" />
        <el-table
          fit
          border
          :data="form.afterDealerShopBrandList"
          style="width: 100%">
          <el-table-column width="50" type="index" label="序号" />
          <el-table-column prop="shopName" label="门店名称">
            <template
              v-if="Object.keys(form.afterDealerShop).length > 1"
              v-slot="{ row }">
              {{
                `${shopNameJoin(form.afterDealerShop)}${
                  row.brandName || ''
                }专卖店`
              }}
            </template>
          </el-table-column>
          <el-table-column
            prop="parentShopNumber"
            label="物理店编号"
            width="160">
            <RequiredFormItemInput
              v-model="form.afterDealerShop.parentShopNumber"
              slot-scope="scope"
              delay-update
              placeholder="/"
              :show-label="false"
              :required="false"
              prop="parentShopNumber"
              label="物理店编号"
              type="number"
              disabled />
          </el-table-column>
          <el-table-column prop="shopNumber" label="门店编号" />
          <el-table-column prop="brandName" label="门店授权品牌" />
          <el-table-column prop="brandArea" label="原来面积" />
          <el-table-column
            prop="brandAreaChange"
            label="修改后的面积"
            min-width="120px">
            <template v-slot="{ row, $index, column }">
              <RequiredFormItemInput
                v-model.number.trim="row.brandAreaChange"
                delay-update
                class="formItem"
                :show-label="false"
                :label="column.label"
                :disabled="changeReasonTypeMap([7])"
                :prop="`afterDealerShopBrandList[${$index}].brandAreaChange`" />
            </template>
          </el-table-column>
          <el-table-column prop="sampleSets" label="原样品套数" />
          <el-table-column
            prop="sampleSetsChange"
            label="修改后的样品套数"
            min-width="120px">
            <template v-slot="{ row, $index, column }">
              <RequiredFormItemInput
                v-model.number.trim="row.sampleSetsChange"
                delay-update
                class="formItem"
                :label="column.label"
                :show-label="false"
                :disabled="changeReasonTypeMap([7])"
                :prop="`afterDealerShopBrandList[${$index}].sampleSetsChange`" />
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <template v-if="changeReasonTypeMap([1, 8])">
      <div style="height: 20px" />
      <RequiredFormItemUploadFile
        v-model="form.CADFileList"
        class="formItem"
        :required="false"
        prop="CADFileList"
        label="上传附件"
        limit-size="400"
        :on-preview="onPreview">
        <div slot="describe">CAD图纸附件</div>
        <div slot="tip">单个文件不能超过400M</div>
      </RequiredFormItemUploadFile>
    </template>

    <remark-item
      v-model.trim="form.dealerDeclarationForm.remark"
      style="margin-top: 40px"
      :file-list="form.fileRecordList"
      @onUpload="
        file => {
          form.fileRecordList = file.map(item => item.response)
        }
      " />
    <AnchorNavigate
      :options="
        changeReasonTypeMap([1, 7, 8])
          ? [
              { id: 'anchor-before-mdxx', title: '门店信息' },
              { id: 'anchor-after-mdxx', title: '门店信息更改' },
              { id: 'anchor-mdmx', title: '门店明细' },
              { id: 'anchor-bz', title: '备注' }
            ]
          : [
              { id: 'anchor-before-yyzzxx', title: '变更前-经销商相关信息' },
              { id: 'anchor-after-yyzzxx', title: '变更后-经销商相关信息' },
              { id: 'anchor-bz', title: '备注' }
            ]
      " />
  </FlowForm>
</template>

<script>
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import 'element-ui/lib/theme-chalk/display.css'
import moment from 'moment'
import HeaderTitle from './components/headerTitle.vue'
import RequiredFormItem from '@/components/CustomFormItem/requiredFormItem.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput.vue'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect.vue'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile.vue'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect.vue'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect.vue'
import AnchorNavigate from '@/components/anchorNavigate.vue'
import FlowForm from './components/flowForm'
import RemarkItem from './components/remarkItem'
import * as CustomerEditApi from '@/api/flow/customerEdit'
import * as BusinessApi from '@/api/attractPlatform/business'
import * as UserApi from '@/api/user/'
import * as CommonApi from '@/api/common/'
import { getBusinessBrandList } from '@/api/businessOos'
import TipsText from './data/tips_text.js'
import { ocrBizlicense } from '@/api/flow/index'
import { getSenderListWithIds } from '@/api/businessOos'
import { stringToArray } from '@/components/TablePro/fn'
import PopupForm, { renderFormItem } from '@/components/FormPro/PopupForm.vue'
import QQMap from '@/components/Map/qqmap'
import { isJSON } from '@/utils/is'

export default {
  name: 'CustomerMainChange',
  components: {
    HeaderTitle,
    RequiredFormItem,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequiredBankFormItemSelect,
    AnchorNavigate,
    FlowForm,
    RemarkItem,
    PopupForm,
    QQMap
  },
  data() {
    return {
      businessLicenseFile: [], // 营业执照url
      form: {
        // 经销商个人基础信息
        dealerDeclarationForm: {
          id: '', // 表单id，如果有则返回，如果没有则代表新建
          title: '', // 标题
          orderNumber: '', // 单号
          optJobNumber: '', // 提单人工号
          optUserId: '', // 提单人id
          optUserName: '', // 提单人工号
          optDeptId: '', // 提单部门id
          optDeptName: '', // 提单部门
          optTime: moment().format('YYYY-MM-DD HH:mm:ss'), // 提单日期
          urgentLevel: '', // 0正常1紧急
          urgentExplain: '',
          statusExplain: ''
        },
        changeReasonType: '', // 变更原因类型，10经销商及营业执照资料、20经销商联系资料、30门店资料
        changeReason: '',
        changeReasonText: '', // 变更原因具体描述
        dealerBusinessLicenseId: null, // 售达方id
        dealerBusinessLicenseName: '', // 售达方名称
        authorizedCityCode: '', // 授权城市编码
        authorizedCityName: '', // 授权城市名称
        applyTypeString: '', // 申请类型
        senderListIds: [], // 需要更改的的送达方
        beforeChangedealerBusinessLicense: {}, // 变更前经销商信息
        afterChangeDealerBusinessLicense: {}, // 变更后的经销商信息
        newBusinessLicenseBankDetailFileList: [], // 新执照对应的公户汇款流水单
        bossChangeAgreementFileList: [], // 实控人更换协议申请书
        taxpayerProveFileList: [], // 1：一般纳税人登记证 或2：税务登记事项通知书或3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片
        bankFileList: [], // 开户证明或者网银截图
        // 门店信息
        beforeDealerShop: {},
        afterDealerShop: {},
        beforeDealerShopBrandList: [],
        afterDealerShopBrandList: [],
        fileRecordList: [] // 最后的附件
      },
      businesslicenseList: [], // 售达方列表
      authorizedCity: [], // 授权城市
      senderList: [], // 送达方列表
      requiredRule: {
        required: true,
        message: '必填项',
        trigger: ['blur', 'change']
      },
      shopList: [], // 门店列表
      isdisplayShopType: '', // 是否展示店
      businessCountTotal: null, // 售达方总条数
      anchorNavigateOptions: [],
      TipsText,
      marketCenterResult: [], // 营销中心列表
      authorizedCityInfo: {}, // 记录当前授权城市信息
      authorizedCityBrandList: [], // 所有可以变更的授权系列
      selectLocationColumns: [
        {
          name: '_latitude_longitude',
          search: {
            transform(e) {
              console.log('e', e)
              return e
                ? {
                    latitude: e.latitude,
                    longitude: e.longitude
                  }
                : {}
            }
          },
          renderFormItem: renderFormItem((h, context) => {
            return (
              context.$attrs.value && (
                <QQMap
                  ref="mapRef"
                  center={
                    context.$attrs.value.longitude ? context.$attrs.value : null
                  }
                  onOnChange={e => {
                    context.$listeners.input(e)
                  }}
                />
              )
            )
          })
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapGetters('dealershipTransfer', ['isOA']),
    ...mapGetters('commonConfig', ['bigBrandMap', 'seriesMap']),
    isEditStatus() {
      // dealerDeclarationForm.status 0草稿，5提交中，9发布失败/OA失败，10已发布，20退回/重审，30已失效/作废，40已归档
      const { status = 0 } = this.form.dealerDeclarationForm
      return [0, 20].includes(status) // 0,20 请求下拉框数据
    },
    // 是经典
    isClassics() {
      return ['慕思经典', '慕思经典-新渠道', '慕思经典+新渠道'].includes(
        this.form?.applyTypeString
      )
    },
    changeReasonList() {
      const isEngineering = this.form?.applyTypeString === '慕思工程'
      return [
        {
          value: 1,
          label: '门店信息变更-面积',
          disabled: this.isClassics || isEngineering
        },
        {
          value: 7,
          label: '门店信息变更-开业时间、最新装修时间、地址变更',
          disabled: this.isClassics || isEngineering
        },
        {
          value: 8,
          label: '门店基础信息变更',
          disabled: !this.isClassics || isEngineering
        },
        {
          value: 2,
          label: '原执照信息变更（不换执照）-换法人',
          disabled: isEngineering
        },
        {
          value: 6,
          label: '原执照信息变更（不换执照）-不换法人',
          disabled: false
        },
        {
          value: 3,
          label: '合伙人变更（不换执照）',
          disabled: this.isClassics || isEngineering
        },
        { value: 4, label: '更换合作执照', disabled: isEngineering },
        { value: 5, label: '实控人信息修正', disabled: false },
        {
          value: 9,
          label: '新增营业执照',
          disabled:
            !this.isClassics || process.env.VUE_APP_PLATNAME === 'agency_'
        }
      ]
    },
    // 判断当前变更原因类型是否在数组内
    changeReasonTypeMap() {
      return (arr = []) => arr.includes(this.form.changeReasonType)
    },
    // shopType是否在arr集合中
    shopTypeMap() {
      return (arr = []) => arr.includes(this.form?.afterDealerShop?.shopType)
    },
    flowTitle() {
      const { optUserName } = this.form.dealerDeclarationForm
      return `客户主数据修改申请表(${this.form.authorizedCityName}，${
        optUserName || this.userInfo.realName
      }，申请类型：${this.form.changeReason})`
    },
    brandNameJoin() {
      if (!this.form.afterDealerShopBrandList) return ''
      return this.form.afterDealerShopBrandList
        .map(item => item.brandName)
        .join('/')
    },
    shopAddress() {
      return row =>
        `${row.province || ''}${row.city || ''}${row.area || ''}${
          row.street || ''
        }${row.shopTypeValue || ''}${row.shopFloor || ''}${
          row.shopFloorNumber || ''
        }`
    },
    shopNameJoin() {
      return data => {
        const {
          city = '',
          area = '',
          street = '',
          shopTypeValue = '',
          shopFloor = '',
          shopFloorNumber = ''
        } = data
        return `${city}${area}${street}${shopTypeValue}${shopFloor}${shopFloorNumber}`
      }
    },
    validations() {
      const isEditIdCard =
        this.form.afterChangeDealerBusinessLicense.idCard !=
        this.form.beforeChangedealerBusinessLicense.idCard
      const isEditContactInfo =
        this.form.afterChangeDealerBusinessLicense.contactInfo !=
        this.form.beforeChangedealerBusinessLicense.contactInfo
      return {
        realName: ['name'],
        contactInfo:
          isEditIdCard || isEditContactInfo
            ? ['phone', 'check_boss_number']
            : ['phone'],
        idCard: isEditIdCard ? ['user_id', 'check_boss_id_card'] : ['user_id'],
        bankAccount: ['bank_account'],
        personLiableName: ['name'],
        personLiablePhone: ['phone'],
        eMail: ['email'],
        renovationLiablePerson: ['name'],
        renovationPersonPhone: ['phone'],
        contactPerson: ['name'],
        contactPersonIdcard: ['user_id'],
        contactPersonPhone: ['phone'],
        warehouseUser: ['name'],
        warehousePhone: ['phone'],
        legalPersonContact: ['phone'],
        age: ['age'],
        account: ['bank_account'],
        creditCode: ['credit_code']
        // taxpayerPhone: ['phone']
      }
    },
    // 变更原因规则校验
    changeReasonRules() {
      return [
        {
          message: '该项变更原因已废弃',
          validator: (rule, value, callback) => {
            if (value === 3 && this.isClassics)
              callback(new Error(rule.message))
            callback()
          }
        },
        {
          message: '数据变更，请重新选择变更原因',
          validator: (rule, value, callback) => {
            if ([1, 7].includes(value) && this.isClassics) {
              callback(new Error(rule.message))
            }
            callback()
          }
        }
      ]
    },
    creditCodeRules() {
      return [
        {
          validator: (rule, value, callback) => {
            if (
              value == this.form.beforeChangedealerBusinessLicense.creditCode
            ) {
              callback(new Error('变更后的统一社会信用代码不能跟变更前的一致'))
            } else {
              callback()
            }
          }
        }
      ]
    },
    seriesListRules() {
      return [
        {
          message: '可以多选，但不允许全选',
          validator: (rule, value, callback) => {
            console.log(value.length, this.authorizedCityBrandList.length)
            if (
              value.length &&
              value.length == this.authorizedCityBrandList.length
            ) {
              callback(new Error(rule.message))
            } else {
              callback()
            }
          }
        }
      ]
    }
  },
  watch: {
    businessLicenseFile(arr) {
      // OCR识别营业执照
      if (arr.length && arr[0]['url']) {
        const params = { imgUrl: arr[0]['url'], appId: 'wx27748066d2817941' }
        ocrBizlicense(params).then((res = {}) => {
          if (res.data.errcode == 0) {
            this.disabled = false
            const data = res.data
            const registerDate = moment(
              data.registered_date.replace(/日|\s/g, '').replace(/年|月/g, '-')
            ).format('YYYY-MM-DD HH:mm:ss')
            this.form = {
              ...this.form,
              registeredCapital: data.registered_capital, // 注册资本
              afterChangeDealerBusinessLicense: {
                ...this.form.afterChangeDealerBusinessLicense,
                creditCode: data.reg_num,
                businessLicenseName: data.enterprise_name,
                businessLicenseType: data.type_of_enterprise.includes(
                  '个体工商户'
                )
                  ? '0'
                  : '1', // 营业执类型
                legalPersonName: data.legal_representative,
                registerDate:
                  registerDate.toLocaleLowerCase() == 'invalid date'
                    ? ''
                    : registerDate, // 注册日期
                businessTerm: data.valid_period, // 营业期限
                businessLicenseAddress: data.address, // 营业执照地址
                businessScope: data.business_scope // 经营范围
              }
            }
          } else {
            if (res.data.errcode == 101003) return
            this.$message.warning(
              '您上传的营业执照未能识别成功，请检查营业执照图片是否清晰或手工填写信息。'
            )
          }
        })
      }
    }
  },
  methods: {
    async getDetail(type, cachedFormData) {
      if (type === 'create') {
        const { code, realName, deptId, accountId, deptName } = this.userInfo
        this.form = {
          ...this.form,
          dealerDeclarationForm: {
            orderNumber: '',
            optUserId: accountId,
            optUserName: realName,
            optJobNumber: code,
            optDeptId: deptId || '',
            optDeptName: deptName || '',
            optTime: moment().format('YYYY-MM-DD HH:mm:ss')
          },
          afterDealerShopBrandList: [],
          ...cachedFormData
        }
      } else {
        await this.initDetail()
      }
    },
    onSericeListChange(list) {
      console.log('inputValue', list)
    },
    async initDetail() {
      const { id } = this.$route.query
      const formData = new FormData()
      formData.append('formId', id)
      const { code, data } = await CustomerEditApi.getDetail(formData)
      if (code != 0) return

      console.log('init', data)

      const {
        beforeDealerShop = {},
        afterDealerShop = {},
        beforeDealerShopBrandList = [],
        afterDealerShopBrandList = [],
        changeReasonType,
        beforeChangedealerBusinessLicense,
        afterChangeDealerBusinessLicense,
        senderList = [],
        senderListIds = [],
        beforeChangedealerBoss,
        afterChangeDealerBoss,
        dealerBusinessLicenseId,
        finalBusinessLicenseId,
        fileRecordList = [],
        ...args
      } = data
      // 必须先设置变更原因，方便后续判断填充数据
      this.form.changeReasonType = changeReasonType
      if (this.changeReasonTypeMap([2, 3, 4, 5, 6, 9])) {
        this.form = {
          ...this.form,
          ...data,
          senderListIds:
            senderListIds.length == 0
              ? senderList.map(item => item.id)
              : senderListIds,
          beforeDealerShop,
          afterDealerShop,
          beforeDealerShopBrandList,
          afterDealerShopBrandList,
          changeReasonType,
          beforeChangedealerBusinessLicense: {
            ...beforeChangedealerBoss,
            ...beforeChangedealerBusinessLicense
          },
          afterChangeDealerBusinessLicense: {
            ...afterChangeDealerBusinessLicense,
            ...afterChangeDealerBoss
          }
        }
      } else {
        this.form = {
          ...this.form,
          ...args,
          changeReasonType,
          beforeDealerShop: { ...beforeDealerShop },
          afterDealerShop: afterDealerShop || {},
          beforeDealerShopBrandList,
          afterDealerShopBrandList: afterDealerShopBrandList.map((item, i) => {
            let brand = beforeDealerShopBrandList.find(
              option => item.brandId == option.brandId
            )
            if (!brand) brand = beforeDealerShopBrandList[i]
            return {
              ...item,
              ...afterDealerShop,
              brandAreaChange: item.brandArea,
              sampleSetsChange: item.sampleSets,
              brandArea: brand.brandArea,
              sampleSets: brand.sampleSets
            }
          })
        }
      }

      this.form = {
        ...this.form,
        changeReasonType,
        dealerBusinessLicenseId,
        fileRecordList
      }
    },
    // 售达方
    async getBusiness(params) {
      const { code, data } = await UserApi.getBusiness(params)
      if (code != 0) return
      return data
    },
    // 送达方
    async getSenderList({ getDataListParams, searchText }) {
      const { data = [] } = await CommonApi.getSenderList({
        businessLicenseId: getDataListParams
      })
      this.senderList = data
      return data
    },
    async onSendersNotFound(e, callback) {
      callback(
        await getSenderListWithIds(this.form.senderListIds || []).then(res => {
          return res.data
        })
      )
    },
    // 售达方下拉加载使用
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    // 售达方当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    // 根据授权城市获取营销中心
    async getMarketCenterList({ getDataListParams }) {
      if (this.isOA || !this.isEditStatus) return []
      if (Object.keys(getDataListParams).length == 0) {
        const { marketCenter, marketCenterCode, brandRegionCode, ...formData } =
          this.form
        this.form = { ...formData }
        return []
      }
      const { cityCode, provinceCode, areaCode } = getDataListParams
      const data = await CommonApi.requestGetSizeBarListByDept({
        cityCode,
        provinceCode,
        areaCode
      }).then(res => res.data || {})
      const { smallName = '', smallCode = '' } = data

      this.form = {
        ...this.form,
        marketCenter: smallName,
        marketCenterCode: smallCode
      }
      return [data]
    },
    // 获取授权城市
    async getAuthorizedCity({ getDataListParams }) {
      const data = await CommonApi.getAuthorizedCityData({
        businessLicenseId: getDataListParams,
        authorizedCityCode: '',
        ifEnabled: 0
      }).then(res => res.data || [])
      this.onAuthorizedCityChange(data[0])
      return data
    },
    // 授权城市改变
    async onAuthorizedCityChange(value = {}) {
      if (this.isOA || !this.isEditStatus) return
      this.authorizedCityInfo = value
      const { authorizedCityCode = '', authorizedCityName = '', city } = value
      this.form = {
        ...this.form,
        authorizedCityCode,
        authorizedCityName,
        authorizedCity: city
      }
    },
    // 获取品牌区域编码
    async getBrandModeList({ getDataListParams }) {
      const [brandIds, provinceCode, cityCode, areaCode, townCode] =
        stringToArray(getDataListParams)
      if (!brandIds || provinceCode === 'undefined' || !this.isEditStatus)
        return []
      try {
        // 发起请求
        const { data } = await CommonApi.requestCityAndBrandFindMarket({
          brandIds,
          provinceCode,
          cityCode,
          areaCode,
          townCode
        })

        // 检查返回数据类型并设置 form.brandRegionCode
        const formattedData = Array.isArray(data) ? data : [data]
        this.form = {
          ...this.form,
          brandRegionCode: formattedData[0]?.brandRegionCode || null
        }
        return formattedData
      } catch (error) {
        console.error('Failed to fetch brand mode list:', error)
        return []
      }
    },
    // 获取可以变更的授权系列
    async getAuthorizedCityBrandList({ getDataListParams }) {
      const [businessLicenseId, authorizedCityCode] =
        stringToArray(getDataListParams)
      const data = await getBusinessBrandList({
        businessLicenseId,
        authorizedCityCode,
        ifEnabled: this.isEditStatus ? 0 : ''
      }).then(res => {
        this.authorizedCityBrandList = (res.data || []).map(item => ({
          seriesId: item.brandId,
          seriesCode: item.brandCode,
          seriesName: item.brandName
        }))
        return this.authorizedCityBrandList
      })
      return data
    },
    // 获取门店
    async getShopList({ getDataListParams }) {
      const [businessLicenseId, authorizedCityCode] =
        stringToArray(getDataListParams)
      if (businessLicenseId == '' || authorizedCityCode == '') return []
      const res = await CommonApi.getShopList({
        businessLicenseId,
        authorizedCityCode
      })
      const { code, data = [] } = res
      if (code != 0 || data.length == 0) {
        if (this.form?.dealerDeclarationForm?.status != 40) {
          this.form = {
            ...this.form,
            beforeDealerShop: {},
            afterDealerShop: {},
            beforeDealerShopBrandList: [],
            afterDealerShopBrandList: []
          }
        }
      }
      console.log('getShopList', data)
      return data
    },
    // 申请类型变更
    onApplyTypeChange(value) {
      if (!this.isEditStatus) return
      this.form = {
        ...this.form,
        applyType: value.code,
        bigBrandId: value.explainJson,
        changeReasonType: this.changeReasonTypeMap(
          this.changeReasonList
            .filter(item => !item.disabled)
            .map(item => item.value)
        )
          ? this.form.changeReasonType
          : ''
      }
    },
    // 原因变更
    onChangeReason(value) {
      if (!this.isEditStatus) return
      const { label } = this.changeReasonList.find(item => item.value == value)
      this.form = {
        ...this.form,
        changeReason: label
      }
      // 原因变更重新填充数据
      if (!this.form.dealerBusinessLicenseId) return
      this.onLicenseChange(this.form.dealerBusinessLicenseId)
    },
    // 售达方改变
    async onLicenseChange(value) {
      try {
        this.$refs.flowFormRef.setLoading(true)
        // this.$refs.flowFormRef.$refs.ruleForm.resetFields()
        this.form = {
          ...this.form,
          dealerBusinessLicenseId: value
        }
        // 是否经销商相关信息改变
        const [business = null] = await this.getBusiness({
          id: value,
          shopStatus: -1
        })
        if (!business) return
        const {
          bossId,
          bossFormId,
          bossName,
          createTime,
          declarationFormId,
          email,
          ifEnabled,
          licenseNumber,
          oaAccount,
          shopDetailsList,
          statusExplain,
          updateTime,
          realName,
          idCard,
          contactInfo,
          businessLicenseName,
          creditCode,
          legalPersonName,
          ...obj
        } = business
        const souceBusinessLicense = {
          ...obj,
          eMail: email,
          bossId,
          realName,
          idCard,
          contactInfo,
          businessLicenseName,
          creditCode,
          legalPersonName
        }
        this.form = {
          ...this.form,
          dealerBusinessLicenseName: businessLicenseName,
          beforeChangedealerBusinessLicense: {
            ...this.form.beforeChangedealerBusinessLicense,
            ...souceBusinessLicense
          },
          senderListIds: this.senderList.map(item => item.id)
        }
        if (this.changeReasonTypeMap([3])) {
          this.form = {
            ...this.form,
            afterChangeDealerBusinessLicense: {
              ...this.form.afterChangeDealerBusinessLicense,
              ...souceBusinessLicense
            }
          }
          setTimeout(() => {
            const ref = this.$refs.flowFormRef.$refs.ruleForm
            ref &&
              ref.validateField([
                'afterChangeDealerBusinessLicense[idCard]',
                'afterChangeDealerBusinessLicense[contactInfo]'
              ])
          }, 500)
        } else if (this.changeReasonTypeMap([2, 6])) {
          this.form = {
            ...this.form,
            afterChangeDealerBusinessLicense: {
              creditCode,
              legalPersonName
            }
          }
        } else if (this.changeReasonTypeMap([1, 7, 8])) {
          // 重新获取门店数据
          const data = this.getShopList({
            getDataListParams: `${value},${this.form.authorizedCityCode}`
          })
          this.onShopChange(data[0])
        } else if (this.changeReasonTypeMap([9])) {
          this.form = { ...this.form, seriesList: [] }
        } else {
          this.form = {
            ...this.form,
            afterChangeDealerBusinessLicense: {
              ...this.form.afterChangeDealerBusinessLicense,
              realName,
              idCard,
              contactInfo
            }
          }
        }
      } finally {
        this.$refs.flowFormRef.setLoading(false)
      }
    },
    // 门店改变
    onShopChange(item = null) {
      if (!item || this.isOA || !this.isEditStatus) return
      const { shopBrandDetailsList = [] } = item
      const shopDetail = shopBrandDetailsList.map(option => ({
        ...option,
        shopNumber: item.shopNumber,
        shopFloorNumber: item.shopFloorNumber,
        shopName: item.shopName,
        brandAreaChange: '',
        sampleSetsChange: ''
      }))
      this.form = {
        ...this.form,
        beforeDealerShop: { ...item },
        afterDealerShop: { ...item },
        beforeDealerShopBrandList: shopDetail.map(
          ({ brandId, brandArea, sampleSets }) => ({
            brandId,
            brandArea,
            sampleSets
          })
        ),
        afterDealerShopBrandList: [...shopDetail]
      }
      this.changeShopType()
    },
    changeShopType() {
      this.form.afterDealerShop.shopTypeValue = ''
      this.$nextTick(() => {
        this.$refs['flowFormRef'].$refs['ruleForm'].clearValidate(
          'afterDealerShop.shopTypeValue'
        )
      })
    },
    // 发票类型数据筛选
    dealerBusinessLicenseBillTypeFilterDataList(list) {
      if (list.length == 0) return []
      let tempList = list
      const { payTaxesType, billType } =
        this.form?.afterChangeDealerBusinessLicense ?? {}

      if (this.isOA) {
        return tempList
      }

      switch (String(payTaxesType)) {
        case '0':
          // 一般纳税人
          tempList = list.filter(item => {
            return ['1', '5'].includes(item.code)
          })
          break
        case '1':
        case '2':
          // 定额纳税人
          // 小规模纳税人
          tempList = list.filter(item => {
            return ['2', '6'].includes(item.code)
          })
          break
      }
      if (!tempList.some(item => item.code == billType)) {
        this.form.afterChangeDealerBusinessLicense.billType = ''
      }

      return tempList
    },
    // 渠道性质变化
    onShopTypeChange(e) {
      const bool = this.shopTypeMap(['SJ001', 'SJ008']) || !e
      this.form = {
        ...this.form,
        afterDealerShop: {
          ...this.form.afterDealerShop,
          marketName: bool
            ? ''
            : `${this.form?.authorizedCity || ''} ${e?.content ?? ''}`,
          marketType: bool ? '' : e?.content,
          shopPositionLevel: ''
        }
      }
    },
    onChangeSelectedItemBank(bankItem) {
      this.form = {
        ...this.form,
        afterChangeDealerBusinessLicense: {
          ...this.form.afterChangeDealerBusinessLicense,
          bankCode: bankItem ? bankItem.CODE : '',
          bankCountryCode: bankItem ? bankItem.AREACODE : ''
        }
      }
    },
    openMap() {
      console.log(this.$refs.mapPopupRef)
      this.$refs.mapPopupRef.onShow({
        _latitude_longitude: this.form.afterDealerShop.longitude
          ? {
              longitude: this.form.afterDealerShop.longitude,
              latitude: this.form.afterDealerShop.latitude
            }
          : {}
      })
    },
    onPreview(file) {
      window.open(file.url)
    },
    async converSysConfigListResult(paramKey, presetResult = '') {
      const { page } = await CommonApi.getSysConfigList({ paramKey })
      return isJSON(page?.list[0]?.paramValue)
        ? JSON.parse(page?.list[0]?.paramValue)
        : page?.list[0]?.paramValue || presetResult
    },
    async openActualFile() {
      const fileUrl = await this.converSysConfigListResult(
        'CLOUD_DERUCCI_SYSTEM_COMMITMENT_LETTER'
      )
      window.open(fileUrl)
    },
    getFlowData() {
      if (this.changeReasonTypeMap([2, 3, 4, 5, 6, 9])) {
        const senderList = this.senderList
          .filter(item => this.form.senderListIds.includes(item.id))
          .map(({ id, senderName, shopBrandNumber }) => ({
            id,
            senderName,
            shopBrandNumber
          }))
        const {
          beforeDealerShop,
          afterDealerShop,
          beforeDealerShopBrandList,
          afterDealerShopBrandList,
          beforeChangedealerBusinessLicense,
          afterChangeDealerBusinessLicense,
          dealerDeclarationForm,
          authorizedCity, // 用做卖场名称请求数据
          ...data
        } = this.form
        return {
          ...data,
          senderList,
          dealerDeclarationForm: {
            ...dealerDeclarationForm,
            title: this.flowTitle
          },
          beforeChangedealerBusinessLicense,
          afterChangeDealerBusinessLicense: {
            ...afterChangeDealerBusinessLicense,
            bankAccountName:
              afterChangeDealerBusinessLicense.businessLicenseName // 银行账户名
          },
          beforeChangedealerBoss: {
            realName: beforeChangedealerBusinessLicense.realName,
            contactInfo: beforeChangedealerBusinessLicense.contactInfo,
            idCard: beforeChangedealerBusinessLicense.idCard,
            id: beforeChangedealerBusinessLicense.bossId,
            age: beforeChangedealerBusinessLicense.age,
            nativePlace: beforeChangedealerBusinessLicense.nativePlace,
            sex: beforeChangedealerBusinessLicense.sex,
            education: beforeChangedealerBusinessLicense.education,
            maritalStatus: beforeChangedealerBusinessLicense.maritalStatus
          },
          afterChangeDealerBoss: {
            realName: afterChangeDealerBusinessLicense.realName,
            contactInfo: afterChangeDealerBusinessLicense.contactInfo,
            idCard: afterChangeDealerBusinessLicense.idCard,
            id: afterChangeDealerBusinessLicense.bossId,
            age: afterChangeDealerBusinessLicense.age,
            nativePlace: afterChangeDealerBusinessLicense.nativePlace,
            sex: afterChangeDealerBusinessLicense.sex,
            education: afterChangeDealerBusinessLicense.education,
            maritalStatus: afterChangeDealerBusinessLicense.maritalStatus
          }
        }
      } else {
        const {
          beforeChangedealerBusinessLicense,
          afterChangeDealerBusinessLicense,
          beforeDealerShop = {},
          afterDealerShop = {},
          afterDealerShopBrandList = [],
          senderListIds,
          senderList,
          dealerDeclarationForm,
          beforeChangedealerBoss,
          authorizedCity,
          ...data
        } = this.form
        return {
          ...data,
          dealerDeclarationForm: {
            ...dealerDeclarationForm,
            title: this.flowTitle
          },
          beforeDealerShop,
          afterDealerShop,
          afterDealerShopBrandList: afterDealerShopBrandList.map(
            ({ brandId, brandAreaChange, sampleSetsChange, brandName }) => {
              return {
                brandId,
                brandArea: brandAreaChange,
                sampleSets: sampleSetsChange,
                brandName
              }
            }
          )
        }
      }
    },
    async onSubmit(type) {
      const flowData = this.getFlowData()
      const {
        marketCenter = '',
        marketCenterCode = '',
        senderListIds = [],
        senderList = []
      } = flowData

      const { data } = await CustomerEditApi.save(flowData)
      if (type !== 'submit') {
        return formId
      }
      // 提交时进行额外校验
      if (this.isClassics && (marketCenter == '' || marketCenterCode == '')) {
        const message = '营销中心不能为空，请重新选择申请类型、营销中心'
        this.$message.warning(message)
        throw new Error(message)
      }
      if (
        (senderListIds.length == 0 || senderList.length == 0) &&
        this.changeReasonTypeMap([2, 3, 4, 6])
      ) {
        const message = '需要更改的送达方不能为空，请重新选择'
        this.$message.warning(message)
        throw new Error(message)
      }

      const formId = data.id
      const formData = new FormData()
      formData.append('formId', formId)
      return CustomerEditApi.submit(formData)
    }
  }
}
</script>

<style scoped lang="scss">
.tips {
  font-size: 12px;
  color: #999;
}
.row {
  display: flex;
  flex-direction: column;
}
.row-wrap::v-deep.el-row--flex {
  flex-wrap: wrap;
}
.oa-select::v-deep .el-input.is-disabled .el-input__suffix {
  display: none;
}
.oa-select::v-deep .el-input.is-disabled .el-input__inner {
  background-color: transparent;
  border-color: transparent;
  border-bottom-color: rgba(0, 0, 0, 0.06);
  color: #333;
  cursor: default;
  padding-left: 0;
}
.large.formItem {
  min-width: 40%;
}
@media screen and (min-width: 702px) and (max-width: 1260px) {
  .default-theme
    .flow-form.el-form--inline:not(.cell)
    > .large.formItem.formItem {
    min-width: 66%;
  }
}
.divider-title .el-divider__text {
  font-size: 18px;
  font-weight: 600;
}
</style>
