import store from '@/store'
import request from '@/utils/request'
import { Message } from 'element-ui'

// 保存老经销商新增门店
export function saveDraftData(data) {
  return request({
    url: '/dealermanage/shop/saveOrUpdate/draft',
    method: 'post',
    data
  })
}

// 获取老经销商门店新增详情
export function getDraftDetial(params) {
  return request({
    url: '/dealermanage/declarationform/details',
    method: 'get',
    params
  })
}
// 获取老经销商新系列新增
export function saveShopbrand(data) {
  return request({
    url: '/dealermanage/deliveryparty/saveOrUpdate/draft',
    method: 'post',
    data
  })
}

// 获取结构数据列表
export function shopFLowData(params) {
  return request({
    url: '/dealermanage/shop/detailsList',
    method: 'get',
    params
  })
}

// 识别营业执照
export function ocrBizlicense(params) {
  return request({
    url: '/consumer-admin/api/wx/ocr/bizlicense',
    method: 'post',
    params
  }).then(res => {
    const { data = {}} = res
    Message.success('您上传的营业执照识别成功，请仔细检查营业执照图片与填充的内容是否一致。')
    return {
      ...res,
      data: {
        ...data,
        // 英文括号替换成中文括号
        enterprise_name: data?.enterprise_name?.replace(/[()]/g, match =>
          match === '(' ? '（' : '）'
        )
      }
    }
  })
}
// 获取我的申请列表
export function getAppleList(params) {
  return request({
    url: '/dealermanage/declarationform/sonFormModelpage',
    params
  })
}

/**
 * @description: 获取门店列表
 */
export function getFlowShopList(params) {
  return request({
    url: '/dealermanage/shop/list',
    method: 'get',
    params
  })
}

/**
 * @description: 获取财务品牌加盟系数
 * marketLevelCode 必填
 * bigBrandId、brandCode、bigBrandCode三个至少传递其中一个
 * bigBrandId?: string|string[] 选填，传递大品牌id自动找出大品牌code
 * brandCode?: string|string[] 选填，自动找出对应的大品牌code
 * bigBrandCode?: string|string[] 选填，如果为空，则通过bigBrandId、brandCode找出
 */
export async function getBrandLeagueCoefficientInfo({ bigBrandId, ...data }) {
  const brandCode = Array.isArray(data.brandCode) ? data.brandCode : (data.brandCode ? data.brandCode.split(',') : [])
  // 通过brandCode查找bigBrandCode
  if (!data.bigBrandCode && brandCode.length) {
    let seriesList = store.getters['commonConfig/seriesList']
    if (!seriesList.length) {
      await store.dispatch('commonConfig/asyncInitCommonData')
      seriesList = store.getters['commonConfig/seriesList']
    }

    const selectedSeriesList = seriesList.filter(item => {
      return brandCode.includes(item.code)
    })

    data.bigBrandCode = []
    selectedSeriesList.forEach(item => {
      if (!data.bigBrandCode.some(code => code === item.parent.code)) {
        data.bigBrandCode.push(item.parent.code)
      }
    })
  }

  // 通过bigBrandId查找bigBrandCode
  const innerBigBrandId = Array.isArray(bigBrandId) ? bigBrandId : (bigBrandId ? bigBrandId.split(',') : [])
  if (innerBigBrandId.length && !data.bigBrandCode) {
    let bigBrandList = store.getters['commonConfig/bigBrandList']
    if (!bigBrandList.length) {
      await store.dispatch('commonConfig/asyncInitCommonData')
      bigBrandList = store.getters['commonConfig/bigBrandList']
    }
    data.bigBrandCode = bigBrandList.filter(item => innerBigBrandId.includes(item.id)).map(item => item.code)
  }

  data.brandCode = brandCode.join(',')
  data.bigBrandCode = data.bigBrandCode.join(',')

  return request({
    url: '/dealermanage/app/configuration/getBrandLeagueCoefficientInfo',
    method: 'POST',
    data
  })
}
