<template>
  <div>
    <el-form :model="form" ref="form">
      <header-title title="品牌推广费及品牌保证金收取" />
      <el-table :data="form.list" style="width: 100%" border>
        <el-table-column prop="" width="70">
          <template slot="header">
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              size="mini"
              @click="add"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-minus"
              circle
              size="mini"
              @click="del($event, scope.$index)"></el-button>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="type" label="类型">
          <template slot-scope="scope">
            <RequiredModuleFormItemSelect
              :prop="`list.${scope.$index}.type`"
              label="类型"
              :show-label="false"
              v-model="scope.row.type"
              type="payment_type_code"
              @change="onChangePaymentType($event, scope.$index)"
              :disabled="isOA || isPaymentTypeDisabled" />
          </template>
        </el-table-column>
        <el-table-column prop="remittanceAmount" label="金额（万）">
          <template slot-scope="scope">
            <RequiredFormItemInput
              :prop="`list.${scope.$index}.remittanceAmount`"
              :rules="rules.remittanceAmount"
              label="金额（万）"
              :show-label="false"
              v-model="scope.row.remittanceAmount"
              delay-update
              :disabled="isOA || isDisabled" />
          </template>
        </el-table-column>
        <el-table-column prop="remittanceDate" label="汇款日期">
          <template slot-scope="scope">
            <RequiredFormItemDatePicker
              :prop="`list.${scope.$index}.remittanceDate`"
              label="汇款日期"
              :show-label="false"
              :disabled="isOA"
              v-model="scope.row.remittanceDate"
              :datePickerProps="{
                valueFormat: 'yyyy-MM-dd hh:mm:ss',
                type: 'datetime'
              }" />
          </template>
        </el-table-column>
        <el-table-column prop="remittanceUser" label="付款人">
          <template slot-scope="scope">
            <RequiredFormItemInput
              :prop="`list.${scope.$index}.remittanceUser`"
              label="付款人"
              :show-label="false"
              v-model="scope.row.remittanceUser"
              delay-update
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column prop="accountName" label="开户名">
          <template slot-scope="scope">
            <RequiredFormItemInput
              :prop="`list.${scope.$index}.accountName`"
              label="开户名"
              :show-label="false"
              v-model="scope.row.accountName"
              delay-update
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column prop="account" label="开户账号">
          <template slot-scope="scope">
            <RequiredFormItemInput
              :prop="`list.${scope.$index}.account`"
              :rules="rules.account"
              label="开户账号"
              :show-label="false"
              v-model="scope.row.account"
              delay-update
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column prop="bank" label="开户行">
          <template slot-scope="scope">
            <RequiredBankFormItemSelect
              :prop="`list.${scope.$index}.bank`"
              class="formItem"
              label="开户行"
              :show-label="false"
              v-model="scope.row.bank"
              :disabled="isOA" />
          </template>
        </el-table-column>
        <el-table-column prop="fileRecordList" label="款条" width="400">
          <template slot-scope="scope">
            <RequiredFormItemUploadFile
              :prop="`list.${scope.$index}.fileRecordList`"
              class="formItem"
              label="上传附件"
              limit-size="400"
              :on-preview="onPreview"
              v-model="scope.row.fileRecordList"
              @onUploaded="
                () => {
                  $refs['form'].validateField(
                    `list.${scope.$index}.fileRecordList`
                  )
                }
              ">
              <div slot="tip">单个文件不能超过400M</div>
            </RequiredFormItemUploadFile>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
  </div>
</template>
<script>
import {
  RequiredFormItemDatePicker,
  RequiredModuleFormItemSelect,
  RequiredFormItemInput,
  RequiredFormItemUploadFile,
  RequiredBankFormItemSelect
} from '@/components/CustomFormItem'
import moment from 'moment'
import { mapState, mapActions } from 'vuex'
import { checkFloatNumber } from '@/utils/validate'
import { validbankAccount } from '@/utils/useFormValidator'
import HeaderTitle from './headerTitle.vue'
export default {
  props: {
    channel: {
      type: String,
      default: ''
    }
  },
  components: {
    HeaderTitle,
    RequiredFormItemInput,
    RequiredFormItemUploadFile,
    RequiredBankFormItemSelect,
    RequiredModuleFormItemSelect,
    RequiredFormItemDatePicker
  },
  data() {
    return {
      form: {
        list: [
          {
            type: '', //类型
            remittanceAmount: '', //汇款金额（万）
            remittanceDate: moment().format('yyyy-MM-DD hh:mm:ss'), //汇款日期
            remittanceUser: '', //付款人
            accountName: '', //开户名
            account: '', //账号
            bank: '', //开户行
            fileRecordList: [] //附件
          }
        ]
      },
      rules: {
        remittanceAmount: [
          { validator: checkFloatNumber, trigger: ['blur', 'change'] }
        ], //汇款金额（万）
        account: [{ validator: validbankAccount, trigger: 'blur' }] //开户账号
      },
      model: {
        type: '', //类型
        remittanceAmount: '', //汇款金额（万）
        remittanceDate: moment().format('yyyy-MM-DD hh:mm:ss'), //汇款日期
        remittanceUser: '', //付款人
        accountName: '', //开户名
        account: '', //账号
        bank: '', //开户行
        fileRecordList: [] //附件
      }
    }
  },
  computed: {
    ...mapState('dealershipTransfer', [
      'isOA',
      'historicalData',
      'brandIdList',
      'brandLeagueCoefficientInfo'
    ]),
    isDisabled() {
      return this.channel != '10'
    },
    isPaymentTypeDisabled() {
      return this.channel != '10' && this.brandIdList.length === 0
    }
  },
  watch: {
    historicalData: {
      handler(historicalData) {
        const dealerMarginRecordList =
          historicalData.bossJoinModel.dealerMarginRecordList
        this.form.list = dealerMarginRecordList.map(item => {
          return {
            ...item,
            type: item?.type ?? ''
          }
        })
      },
      deep: true
    },
    brandIdList: {
      async handler(brandIdList) {
        if (brandIdList && brandIdList.length > 0) {
          await this.getBrandLeagueCoefficientInfo()
        }
        this.form.list.forEach((item, index) => {
          this.getCoefficientInfo(item.type, index)
        })
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('dealershipTransfer', ['getBrandLeagueCoefficientInfo']),
    onChangePaymentType(val, idx) {
      if (this.channel == '10') return
      this.getCoefficientInfo(val, idx)
    },
    getCoefficientInfo(val, idx) {
      const coefficient = this.brandLeagueCoefficientInfo
      switch (Number(val)) {
        case 1:
          this.form.list[idx].remittanceAmount = coefficient?.promotionExpenses
          break
        case 2:
          this.form.list[idx].remittanceAmount = coefficient?.brandDeposit
          break
        case 3:
          this.form.list[idx].remittanceAmount = coefficient?.earnestDeposit
          break
        case 4:
          this.form.list[idx].remittanceAmount = coefficient?.envyDeposit
          break
        case 5:
          this.form.list[idx].remittanceAmount = coefficient?.decorationDeposit
          break
        default:
          this.form.list[idx].remittanceAmount = ''
      }
    },
    onPreview(file) {
      window.open(file.url)
    },
    validFiles(rule, val, callback) {
      if (val.length > 0) {
        callback()
      } else {
        callback(new Error('必填项'))
      }
    },
    add() {
      if (this.isOA) {
        return
      }
      const params = {
        accountName: '东莞慕思寝具销售有限公司', //开户名
        account: '4405 0177 0050 0000 0180 ', //账号
        bank: '中国建设银行股份有限公司东莞厚街健晖分理处' //开户行
      }
      const obj = {
        type: '', //类型
        remittanceAmount: '', //汇款金额（万）
        remittanceDate: moment().format('yyyy-MM-DD hh:mm:ss'), //汇款日期
        remittanceUser: '', //付款人
        accountName: '', //开户名
        account: '', //账号
        bank: '', //开户行
        fileRecordList: [] //附件
      }
      this.form.list = [...this.form.list, obj]
    },
    del(e, index) {
      if (this.isOA) {
        return
      }
      if (this.form.list.length === 1) return
      this.form.list.splice(index, 1)
    },
    uploaded(fileList, index) {
      this.form.list[index].fileRecordList = fileList
      this.$refs['form'].validateField(`list.${index}.fileRecordList`)
    }
  }
}
</script>
