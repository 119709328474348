<script setup lang="jsx">
import { reactive, computed } from 'vue'
import TablePro, {
  render,
  renderFormItem
} from '@/components/TablePro/index.vue'
import { getAppleList } from '@/api/flow/'
import { getStorage } from '@/utils/storage'
import { useRoute, useRouter } from 'vue-router/composables'
import { getFlowTypeList } from '@/api/commonConfig/dataManage'
import CommonSelect from '@/components/CustomFormItem/Select.vue'
import qs from 'qs'
import flow from '@/router/modules/flow'
import moduleData from '@/components/CustomFormItem/data/module.data'

const router = useRouter()
const routerList = computed(() => flow())

const goDetail = (item, type) => {
  // JX开头标识OA流程跳转oa，否则跳转bpm
  if (/^JX/.test(item.orderNumber)) {
    const oaRequestId = type === 1 ? item.sonOaRequestId : item.oaRequestId
    const orderNumber = type === 1 ? item.sonOrderNumber : item.orderNumber

    const url = `${
      process.env.VUE_APP_OA_URL
    }/mobile/plugin/loginForSession.jsp?loginid=MerchantSysAdmin&requestid=${
      oaRequestId || ''
    }&workflowid=${orderNumber || ''}`
    window.open(url)
  } else {
    const query = {
      fdId: item.id,
      fdFormTemplateCode: 'distributor-' + item.processCode,
      processId: item.orderNumber,
      from: 'bpm'
    }

    if (type === 1) {
      query.fdId = item.sonFormId
      query.fdFormTemplateCode = 'distributor-' + item.sonProcessCode
      query.processId = item.sonOrderNumber
    }
    // router.push({
    //   path: `/oa/flow/entrance`,
    //   query
    // })

    window.open(
      `${location.origin}${location.pathname}#/oa/flow/entrance?${qs.stringify(
        query
      )}`
    )
  }
}

const setAdd = async ({ processCode, declarationType, id, title }) => {
  routerList.value.forEach(item => {
    if (item.meta.code === processCode) {
      if (
        item.meta.declarationType &&
        item.meta.declarationType !== declarationType
      ) {
        return
      }
      if (processCode === 'JXS007' && title.includes('直营')) {
        router.push({
          path: `/flow/${item.path}`,
          query: { id, type: 'direct' }
        })
        return
      }
      router.push({ path: `/flow/${item.path}`, query: { id } })
    }
  })
}

const tableConfig = reactive({
  request: params => {
    const { accountId, id } = getStorage('userInfo')
    return getAppleList({
      ...params,
      optUserId: accountId || id,
      notProcessCode: 'JXS008'
    })
  },
  columns: [
    {
      title: '流程类型',
      name: 'processCode',
      search: true,
      hideInTable: true,
      valueType: 'select',
      renderFormItem: renderFormItem((_, context) => {
        const requiredFlowTypeList = params => {
          return getFlowTypeList({
            module: 'distributor',
            type: 'flow',
            currPage: params.page,
            pageSize: 100
          })
        }
        return (
          <CommonSelect
            attrs={context.$attrs}
            on={context.$listeners}
            label="流程类型"
            getDataList={requiredFlowTypeList}
            valueKey="code"
            labelKey="content"
          />
        )
      })
    },
    { title: '流程ID', name: 'orderNumber', search: true },
    { title: '招商单号', name: 'zsOrderNumber' },
    { title: '流程名称', name: 'title' },
    {
      title: '审批状态',
      name: 'status',
      width: 100,
      search: true,
      valueType: 'select',
      valueEnum: [
        { label: '未知', value: null },
        { label: '草稿', value: 0 },
        { label: '发布中', value: 5 },
        { label: '发布失败/OA失败', value: 9 },
        { label: '已发布', value: 10 },
        { label: '退回/重审', value: 20 },
        { label: '已失效/作废', value: 30 },
        { label: '已归档', value: 40 },
        { label: '强制归档', value: 41 }
      ]
    },
    { title: '申请时间', name: 'optTime', width: 140 },
    {
      title: '操作',
      width: 100,
      render: render((h, { data, index }) => {
        return (
          <div>
            <el-button type="primary" onClick={() => goDetail(data, 0)}>
              查看详情
            </el-button>
            {['20', '9', '0', 20, 9, 0].includes(data.status) &&
              /^JX/.test(data.orderNumber) && (
                <el-button
                  class="btnBlod"
                  type="text"
                  onClick={() => setAdd(data)}>
                  重新发起
                </el-button>
              )}
          </div>
        )
      })
    },
    { title: '关联流程ID', name: 'sonOrderNumber', search: true },

    {
      title: '关联流程名称',
      name: 'sonTitle',
      render: render((h, { data }) => {
        const shopStatuses = moduleData.shop_status.reduce(
          (acc, { content, code }) => {
            // 使用 code 作为键，content 作为值
            acc[code] = content
            return acc
          },
          {}
        )
        return (
          <div>
            {data.sonFormId && (
              <p>
                流程状态：
                <label style="color:red">
                  {shopStatuses[data.nowShopStatusExplain]}
                </label>
              </p>
            )}
            <p>{data.sonTitle}</p>
          </div>
        )
      })
    },
    {
      title: '关联审批状态',
      name: 'sonStatus',
      width: 100,
      render: render((h, { data }) => {
        return (
          <span>
            {
              {
                0: '草稿',
                5: '发布中',
                6: '发布失败/OA失败',
                10: '已发布',
                20: '退回/重审',
                30: '已失效/作废',
                40: '已归档'
              }[data.sonStatus]
            }
          </span>
        )
      })
    },
    // { title: '关联门店状态', name: 'nowShopStatusExplain', width: 100 },
    { title: '申请时间', name: 'sonOptTime', width: 140 },
    {
      title: '关联操作',
      width: 100,
      render: render((h, { data, index }) => {
        return (
          data.sonFormId && (
            <div>
              <el-button type="primary" onClick={() => goDetail(data, 1)}>
                关联详情
              </el-button>
              {['20', '9', '0', 20, 9, 0].includes(data.sonStatus) &&
                /^JX/.test(data.orderNumber) &&
                data.sonProcessCode != 'JXS008' && (
                  <el-button
                    class="btnBlod"
                    type="text"
                    onClick={() =>
                      setAdd({
                        processCode: data.sonProcessCode,
                        id: data.sonFormId
                      })
                    }>
                    {data.sonProcessCode}重新发起
                  </el-button>
                )}
            </div>
          )
        )
      })
    }
  ]
})
</script>

<template>
  <TablePro
    ref="tableProRef"
    autoWidthCell
    :request="tableConfig.request"
    :columns="tableConfig.columns" />
</template>
