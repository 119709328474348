import http, { download } from '@/utils/request'
// import { getStorage } from '@/utils/storage'
import store from '@/store'
// const userInfo = getStorage('userInfo')
// 人工生成电子授权书
export const createBrandAuthorization = data =>
  http({
    url: '/dealermanage/shop/shopAddLoveToken',
    data,
    method: 'post'
  })
// 查询授权品牌
export const getBrandDataList = () =>
  http({
    url: '/dealermanage/brandseries/list',
    params: {
      brandLevel: 1
    }
  })

// 门店列表
export const getShopDataList = params =>
  http({
    url: '/dealermanage/shop/ht/listpage',
    params
  })
// 授权品牌
export const getShopBrandList = params =>
  http({
    url: '/dealermanage/shopbrand/detailsList',
    params
  })
// 送达方
export const getMasterDataList = params =>
  http({
    url: '/dealermanage/authorizedbrand/ht/modelList',
    params
  })
// 售达方
export const getBusinessDataList = params =>
  http({
    url: '/dealermanage/businesslicense/detailsList/dealerBusinessLicenseParent',
    params
  })
export const getEasyBusinessDataList = params =>
  http({
    url: '/dealermanage/businesslicense/list',
    params
  })

// 门店系列
export const getShopBrandDataList = params =>
  http({
    url: '/dealermanage/shopbrand/shopBrandlist',
    params
  })

// 系列列表
export const getShopBrand = params =>
  http({
    url: '/dealermanage/shopbrand/getDealerSeriesByShopId',
    params
  })

// 保存门店系列
export const saveShopBrand = data =>
  http({
    method: 'post',
    url: '/dealermanage/shopbrand/changeShopBrand',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })
// 删除门店系列
export const delShopBrand = data => {
  const formData = new FormData()
  formData.append('ids', data)
  formData.append('updateUserName', store.state.user.userInfo.realName)
  return http({
    method: 'post',
    url: '/dealermanage/shopbrand/removeShopBrand',
    data: formData
  })
}
// 导出门店excel
export const excelShop = params => {
  return http({
    url: '/dealermanage/shop/getDealerShopBrandExport',
    params
  })
}

// 导出门店总系列
export const excelShopAllJson = params => {
  return http({
    url: '/dealermanage/shop/getDealerShopSunList',
    params
  })
}
// 导出门店数据文件
export const excelShopFile = params => {
  // return http({
  //   url: '/dealermanage/shop/getDealerShopSunExport',
  //   params
  // })
  download('dealermanage/shop/getDealerShopSunExport', params)
}
// 门店租金
export const shopRent = params => {
  return http({
    url: '/dealermanage/shoprent/list',
    params
  })
}

// 品牌授权书
export const requestCertificates = id => {
  return http({
    url: '/dealermanage/bestsign/getCertificates/' + id
  })
}

// 修改门店系列
export const requestUpdateBrand = data => {
  return http({
    url: '/dealermanage/shopbrand/update',
    data,
    method: 'post'
  })
}
// oa相关流程列表
export const requestOaGetDataList = params => {
  return http({
    url: '/dealermanage/declarationform/requestOa',
    params
  })
}
// -------------------------------------------------------------------

// 售达方列表
export const getBusinessManageList = params =>
  http({
    url: '/dealermanage/businesslicense/licenselist',
    params
  })
// 售达方-授权品牌
export const getBusinessBrand = params =>
  http({
    url: '/dealermanage/businesslicense/getAuthorizedBrandListBySellerId',
    params
  })
// 售达方-门店
export const getBusinessShop = params =>
  http({
    url: '/dealermanage/businesslicense/getShopListBySelleId',
    params
  })
// 售达方-送达方
export const getBusinessBoss = params =>
  http({
    // url: '/dealermanage/crmdistributor/distributorAuthorizedBrand',
    url: '/dealermanage/businesslicense/getDeliverPlaceListBySellerId',
    params
  })
// 售达方-经销商
export const getBusinessDealer = params =>
  http({
    url: '/dealermanage/businesslicense/getAffiliatedDealersListBySellerId',
    params
  })
// 售达方-授权城市
export const getBusinessCity = params =>
  http({
    url: '/dealermanage/businesslicense/sellerToViewAuthorizedCity',
    params
  })
// 售达方-新渠道送达方
export const getNewBusinessDeliver = params =>
  http({
    url: '/dealermanage/businesslicense/newChannelDeliverPlace',
    params
  })
// 售达方-新渠道授权城市
export const getNewBusinessCity = params =>
  http({
    url: '/dealermanage/businesslicense/newChannelAuthorizedCity',
    params
  })
// 售达方-更新营业执照
export const businesslicenseUpdate = data =>
  http({
    url: '/dealermanage/businesslicense/update',
    method: 'POST',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })
// 售达方-修改授权系列
export const saveBusinessBrandData = data =>
  http({
    url: '/dealermanage/deliveryparty/addOrUpdate',
    method: 'POST',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })
// 售达方-删除授权系列
export const delBusinessBrandData = data => {
  const formData = new FormData()
  formData.append('ids', data)
  formData.append('updateUserName', store.state.user.userInfo.realName)
  return http({
    url: '/dealermanage/deliveryparty/delete',
    method: 'POST',
    data: formData
  })
}

// 售达方-获取授权系列
export const getBusinessBrandList = params =>
  http({
    url: '/dealermanage/deliveryparty/getDealerDeliveryPartyDetails',
    params
  })
// 售达方-获取授权城市
export const getBusinessCityList = params =>
  http({
    url: '/dealermanage/crmdistributor/listpage',
    params
  })

// 售达方 - 导出excel
export const excelBusiness = data =>
  download('dealermanage/businesslicense/export', data)

// 售达方 - 前端导出excel
export const excelToBusiness = params => {
  download('dealermanage/businesslicense/export', params)
  // return http({
  //   url: '/dealermanage/businesslicense/export',
  //   params
  // })
}

// -----------------------------------------------------------------

// 经销商列表
export const getDealerDataList = params =>
  http({
    url: '/dealermanage/crmdistributor/abandoning/listpage',
    params
  })

// 经销商列表-授权品牌
export const getDealerBrand = params =>
  http({
    url: '/dealermanage/deliveryparty/detailsList',
    params
  })
// 经销商列表-门店
export const getDealerShop = params =>
  http({
    url: '/dealermanage/crmdistributor/distributorShopList',
    params
  })
// 经销商列表-送达方
export const getDealerBoss = params =>
  http({
    // url: '/dealermanage/authorizedbrand/ht/modelList',
    url: '/dealermanage/crmdistributor/distributorAuthorizedBrand',
    params
  })
// 经销商列表-售达方
export const getDealerBusiness = params =>
  http({
    url: '/dealermanage/businesslicense/getCrmDealerBusinessLicense',
    params
  })

// 新增经销商
export const addDealer = data =>
  http({
    url: '/dealermanage/crmdistributor/addCrmdistributor',
    data,
    method: 'post'
  })

// 修改经销商
export const editDealer = data =>
  http({
    url: '/dealermanage/crmdistributor/updateCrmdistributor',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    },
    method: 'post'
  })
// 导出经销商列表
export const excelToDealer = params => {
  return http({
    url: '/dealermanage/crmdistributor/exportList',
    params
  })
}

// 导出经销商子系列
export const exportCustomerSeries = params => {
  return http({
    url: '/dealermanage/boss/exportCustomerSeries',
    params
  })
}

// ------------------------------------------------------------------

// 送达方列表
export const getReceiverDataList = params =>
  http({
    url: '/dealermanage/authorizedbrand/receiverList',
    params
  })
// 使用id请求送达方列表
export const getSenderListWithIds = data =>
  http({
    url: '/dealermanage/authorizedbrand/listByIds',
    data,
    method: 'POST'
  })
// 送达方 - 门店
export const getReceiverShop = params =>
  http({
    url: '/dealermanage/authorizedbrand/shopList',
    params
  })
// 送达方 - 授权品牌
export const getReceiverBrand = params =>
  http({
    url: '/dealermanage/authorizedbrand/getBrandListBySenderNumber',
    params
  })
// 送达方 - 订单专员
export const getReceiverOrderSpecialist = params =>
  http({
    url: '/dealermanage/authorizedbrand/getOrderSpecialist',
    params
  })
// 送达方 - 销售组织
export const getTheBrand = params =>
  http({
    url: '/dealermanage/authorizedbrand/getTheBrand',
    params
  })
// 新增/修改订单专员 - 系列
export const getBrandSeries = params =>
  http({
    url: '/dealermanage/brandseries/listpage',
    params
  })

// 大品牌列表
export const getBrandseriesList = params => {
  console.log(params)

  return http({
    url: '/dealermanage/brandseries/list',
    params
  })
}

// 新增/修改订单专员 - 员工
export const getBossList = params =>
  http({
    url: '/dealermanage/boss/listpage',
    params
  })
// 新增订单专员
export const addOrderspecialist = data =>
  http({
    url: '/dealermanage/orderspecialist/add',
    method: 'post',
    data
  })
// 修改订单专员
export const updateOrderspecialist = data =>
  http({
    url: '/dealermanage/orderspecialist/update',
    method: 'post',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })

// 修改订单专员状态值
export const changeOrderspeciaStatus = data =>
  http({
    method: 'post',
    url: '/dealermanage/orderspecialist/updates',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })

// 切换送达方状态
export const changeReceiverStatus = data =>
  http({
    url: '/dealermanage/authorizedbrand/update',
    method: 'post',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })

// 批量修改订单专员
export const resetOrderManager = data =>
  http({
    url: '/dealermanage/orderspecialist/updateBatchOrderSpecialist',
    method: 'post',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })

// 送达方导出
export const excelAuthorzed = data => {
  download('dealermanage/authorizedbrand/export', data)
}

// 送达方列表导出
export const excelAuthorzedJson = data => {
  return http({
    url: '/dealermanage/authorizedbrand/exportList',
    params: data
  })
}
// 是否培训
export const changeTrainStatus = data =>
  http({
    url: '/dealermanage/deliveryparty/update',
    method: 'post',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })

// 变更原因详情
export const requestUpdateDetail = dataId =>
  http({
    url: '/dealermanage/datahistorydetail/modelListpage',
    params: {
      dataId,
      pageSize: 500
    }
  })
// --------------------------------------------------------
// 实控人
export const getBossDataList = params =>
  http({
    url: '/dealermanage/boss/listpage',
    params
  })
// 实控人 - 老板详情
export const getBossDetail = params =>
  http({
    url: '/dealermanage/boss/list',
    params
  })

// 实控人 - 导出excel
export const excelBoss = data => {
  download('dealermanage/crmdistributor/export', data)
}

// --------------------------------------------------------
// 修改门店数据
export const getShopUpdate = data =>
  http({
    url: '/dealermanage/shop/update',
    method: 'post',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })
// 实控人 - 修改实控人
export const updateBossData = data =>
  http({
    url: '/dealermanage/boss/update',
    method: 'post',
    data: {
      ...data,
      updateUserName: store.state.user.userInfo.realName
    }
  })

// 获取授权城市列表
export const crmdistributorListPage = data =>
  http({
    url: 'dealermanage/crmdistributor/listpage',
    method: 'get',
    params: data
  })

// 门店详情
export const shopInfoDetail = data =>
  http({
    url: '/dealermanage/shop/info/' + data.id,
    method: 'get'
  })

// 同步到SAP
export const initializeSapDataByDataId = params =>
  http({
    url: '/dealermanage/operationflow/dealerJoinApi/initializeSapDataByDataId',
    method: 'post',
    params
  })

export const authorizedcityListpage = params =>
  http({
    url: '/dealermanage/authorizedcity/listpage',
    method: 'get',
    params
  })

// 数据流程监控
export const dataRelatedFlow = params => {
  return http({
    url: '/dealermanage/datahistorydetail/declarationFormByDataId',
    method: 'get',
    params
  })
}

// 导出实控人
export const bossExport = data => {
  download('dealermanage/boss/export', data)
}

// 导出实控人列表
export const excelBossData = params => {
  return http({
    url: '/dealermanage/boss/exportList',
    params
  })
}

// 转让门店记录列表
export const franchiseChangeShopList = params => {
  return http({
    url: '/dealermanage/datahistorydetail/franchiseChangeShopList',
    params
  })
}

// 上上签单独失效接口
export const bestsignInfoId = id => {
  return http({
    url: '/dealermanage/bestsign/info/' + id,
    id
  })
}

// 查询实控人关联信息接口
export const getBossCorrelationInfo = params => {
  return http({
    url: '/dealermanage/boss/getBossCorrelationInfo',
    params
  })
}

// 删除门店流程控制
export const deleteByNumber = params => {
  return http({
    url: '/dealermanage/storesunderrenovation/deleteByNumber',
    params
  })
}

// 获取授权品牌
export const requestGetAuthorizedBrand = params => {
  return http({
    url: '/dealermanage/shopbrand/detailsList',
    params
  })
}

// 主数据管理-经销商列表导入模板下载
export const distributorImportDownload = data => {
  download('dealermanage/crmdistributor/distributorImportDownload', data)
}

// 主数据管理-经销商列表导入
export const distributorImport = data =>
  http({
    url: '/dealermanage/crmdistributor/distributorImport',
    method: 'post',
    data
  })

// 主数据管理-送达方导入模板下载
export const authorizedbrandDownload = data => {
  download('dealermanage/authorizedbrand/download', data)
}

// 主数据管理-送达方列表导入
export const importUpdateDealerAuthorizedBrand = data =>
  http({
    url: '/dealermanage/authorizedbrand/importUpdateDealerAuthorizedBrand',
    method: 'post',
    data
  })

// 主数据管理-门店导入模板下载
export const dealermanageShopDownload = data => {
  download('dealermanage/shop/download', data)
}

// 主数据管理-门店列表导入
export const dealermanageShopImport = data =>
  http({
    url: '/dealermanage/shop/import',
    method: 'post',
    data
  })

// 导出操作记录
export const listpageExporttask = data => {
  return http({
    url: '/dealermanage/datahistorydetail/listpage/exporttask',
    method: 'post',
    data: { ...data, optType: 1, optSource: 1 }
  })
}

// 获取新渠道网点列表
export const newchannelnetworkGetNetWorkPage = data => {
  return http({
    url: '/dealermanage/newchannelnetwork/getNetWorkPage',
    method: 'POST',
    data
  })
}
// 门店转让申请记录
export const getShopChangeLogList = params => {
  return http({
    url: '/dealermanage/shopchangelog/listpage',
    params
  })
}
