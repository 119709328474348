const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  token: state => state.user.token,
  userInfo: state => state.user.userInfo,
  menuList: state => state.user.menuList,
  visitedViews: state => state.tagsView.visitedViews,
  routes: state => state.tagsView.routes,
  path: state => state.app.path,
  elementSize: state => state.app.elementSize,
  theme: state => state.settings.theme,
  elementSize: state => state.app.elementSize,
  formTheme: state => state.app.formTheme,
  column: state => state.settings.column,
  ssoToken: state => state.user.ssoToken,
  dictionary: state => state.dataDictionary.dictionary
}
export default getters
