<template>
  <div>
    <TablePro ref="tableProRef" v-bind="tableProConfig"></TablePro>
  </div>
</template>
<script setup>
import TablePro, { render } from '@/components/TablePro/index.vue'
import { ref } from 'vue'
import { getShopChangeLogList } from '@/api/businessOos'
const tableProRef = ref(null)
const tableProConfig = {
  actions: ['export'],
  request: async params => {
    const res = await getShopChangeLogList(params)
    return {
      data: res.data.list,
      totalCount: res.data.totalCount
    }
  },
  columns: [
    { title: '门店编号', name: 'shopNumber', search: true },
    { title: '门店开业时间', name: 'shopOpentime' },
    { title: '转让前经销商编号', name: 'beforeDealerCode', search: true },
    { title: '转让前经销商名称', name: 'beforeDealerName', search: true },
    {
      title: '转让前经销商授权时间',
      name: 'beforeDealerTime'
    },
    { title: '转让后经销商编号', name: 'afterDealerCode', search: true },
    { title: '转让后经销商名称', name: 'afterDealerName', search: true },
    { title: '转让流程单号', name: 'formOrderNumber', search: true },
    {
      title: '转让流程提交时间',
      name: 'formOptTime',
      valueType: 'date',
      search: {
        transform: e =>
          e ? { formOptTimeStart: e[0], formOptTimeEnd: e[1] } : {}
      },
      fieldProps: {
        type: 'daterange'
      }
    },
    {
      title: '转让流程归档时间',
      label: '转让流程审批时间',
      name: 'formOverTime',
      valueType: 'date',
      search: {
        transform: e =>
          e ? { formOverTimeStart: e[0], formOverTimeEnd: e[1] } : {}
      },
      fieldProps: {
        type: 'daterange'
      }
    }
  ]
}
</script>
