import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import app from './modules/app'
import settings from './modules/settings'
import user from './modules/user'
import tagsView from './modules/tags_view'
import data from './modules/data'
import dealershipTransfer from './modules/flow/dealershipTransfer'
import commonConfig from './modules/commonConfig'
import dataDictionary from './modules/dataDictionary'
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    app,
    settings,
    user,
    tagsView,
    data,
    dealershipTransfer,
    commonConfig,
    dataDictionary
  },
  getters
})

export default store
