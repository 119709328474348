const state = {
  dictionary: {}
}

const mutations = {
  ADD_DICTIONARY: (state, data) => {
    state.dictionary[data.key] = data.value
  }
}

const actions = {
  setDataDictionary({ commit }, data) {
    commit('ADD_DICTIONARY', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
