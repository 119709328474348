<template>
  <required-form-item
    ref="formItemRef"
    class="required-form-item-select"
    v-bind="attrs.other"
  >
    <common-select
      ref="selectRef"
      clearable
      filterable
      remote
      :label-key="labelKey"
      :value-key="valueKey"
      :label="$attrs['label']"
      :get-data-list="requestDataList"
      v-bind="attrs.select"
      v-on="listeners"
    />
  </required-form-item>
</template>

<script>
import md5 from 'js-md5'
import requiredFormItem from './requiredFormItem'
import Select from './Select'
import { attrsAssign } from './requiredFormItemSelect.vue'
import { bankCodeQuery } from '@/api/common'
import store from '@/store'

let response = {}
store.subscribeAction(({ type }) => {
  if (type === 'user/logout') {
    response = {}
  }
})
export default {
  components: {
    requiredFormItem,
    [Select.name]: Select
  },
  props: {
    labelKey: {
      type: String,
      default: 'NAME'
    },
    valueKey: {
      type: String,
      default: 'NAME'
    }
  },
  computed: {
    $formItemRef() {
      return this.$refs.formItemRef
    },
    $selectRef() {
      if (this.$refs.selectRef) {
        return this.$refs.selectRef.$selectRef
      }
      return null
    },
    listeners({ $listeners, $attrs: { selectListeners }}) {
      return Object.assign($listeners, selectListeners)
    },
    attrs({ $attrs }) {
      return attrsAssign($attrs)
    }
  },
  methods: {
    async formatData(data) {
      if (Array.isArray(data?.ITMS)) {
        return data.ITMS.map(item => ({
          AREACODE: item.BANKS,
          NAME: `${item.BANKA}（${item.BRNCH}）`,
          CODE: item.BANKL
        })).slice(0, 100)
      }
      return []
    },
    async requestDataList({ searchText, getDataListParams }) {
      if (searchText) {
        this.$emit('input', searchText)
      }
      if (typeof searchText !== 'string' || !searchText.trim()) return []
      const params = {
        BRNCH: searchText,
        ...getDataListParams
      }
      const paramsStr = JSON.stringify(params)
      const paramsKey = md5(paramsStr)
      if (response[paramsKey]) {
        const responseData = await response[paramsKey]
        return this.formatData(responseData)
      }

      response[paramsKey] = bankCodeQuery(params).then(res => {
        response[paramsKey] = res
        return res
      })
      return response[paramsKey].then(res => {
        return this.formatData(res)
      })
    }
  }
}
</script>
