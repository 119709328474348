<template>
  <div>
    <el-form
      :model="form"
      class="flow-form"
      :inline="true"
      ref="form"
      label-position="top">
      <header-title title="原经销商信息" />
      <RequiredFormItemSelect
        ref="businessRef"
        class="formItem"
        prop="oldBusinessLicenseId"
        v-model="form.oldBusinessLicenseId"
        label="售达方"
        labelKey="licenseNumber"
        valueKey="id"
        :selectProps="{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        }"
        :selectListeners="{
          change: id => {
            form.authorizedCityCode = ''
            form.brandIdList = []
            form.marketCenterName = ''
            form.marketCenterCode = ''
            form.brandRegionCode = ''
            senderIdList = []
            agencyBrandIdList = []
            setAuthorizedCityParams({})
            setBrandIdList([])
            setBrandList([])
            setBrandLeagueCoefficientInfo(null)
          }
        }"
        :total-count="businessCountTotal"
        :getDataList="getBusinessLicense"
        :getNextList="getBusinessLicense"
        @onOptionsListNotFound="onNotFoundBusinessLicense"
        @changeSelectedItem="
          e => {
            businessSelectedItem = e
            setDealerBusinessParams(e)
          }
        ">
        <template v-slot="{ scope }">
          <span>{{ scope.row.licenseNumber }}</span>
          <small>{{ scope.row.businessLicenseName }}</small>
        </template>
      </RequiredFormItemSelect>
      <RequiredFormItemInput
        class="formItem"
        label="原售达方名称"
        placeholder="自动生成"
        disabled
        v-model="businessSelectedItem.businessLicenseName" />
      <RequiredFormItemSelect
        class="formItem"
        prop="authorizedCityCode"
        label="转让城市"
        v-model="form.authorizedCityCode"
        :getDataList="getCityList"
        :getDataListParams="getCityDataListParams"
        filterable
        :disabled="isOA || !form.oldBusinessLicenseId"
        labelKey="authorizedCityName"
        valueKey="authorizedCityCode"
        @change="changeCity"
        @changeSelectedItem="onChangeCitySelectedItem" />
      <RequiredFormItemSelect
        class="formItem"
        label="送达方"
        placeholder="自动生成"
        v-model="senderIdList"
        multiple
        disabled
        labelKey="authorizedName"
        valueKey="id"
        :dataList="senderList" />
      <RequiredFormItemInput
        class="formItem"
        label="省份"
        placeholder="自动生成"
        disabled
        v-model="authorizedCityParams.province" />
      <RequiredFormItemInput
        class="formItem"
        label="地级市"
        placeholder="自动生成"
        disabled
        v-model="authorizedCityParams.city" />
      <RequiredFormItemInput
        class="formItem"
        label="区/县"
        placeholder="自动生成"
        disabled
        v-model="authorizedCityParams.area" />
      <RequiredFormItemSelect
        class="formItem"
        label="代理品牌"
        placeholder="自动生成"
        v-model="agencyBrandIdList"
        multiple
        disabled
        labelKey="name"
        valueKey="brandId"
        :dataList="brandList" />
      <RequiredFormItemSelect
        class="formItem"
        prop="brandIdList"
        label="转让品牌"
        placeholder="自动生成"
        v-model="form.brandIdList"
        :disabled="isOA"
        filterable
        multiple
        :dataList="customBrandList"
        disabledKey="disabled"
        labelKey="name"
        valueKey="brandId"
        @changeSelectedItem="onChangeBrandSelectedItem"
        @change="changeBrand" />
      <RequiredFormItemInput
        class="formItem"
        label="营销中心"
        placeholder="自动生成"
        disabled
        v-model="form.marketCenterName" />
      <RequiredFormItemInput
        class="formItem"
        label="品牌区域编码"
        placeholder="自动生成"
        disabled
        v-model="form.brandRegionCode" />
      <RequiredFormItemInput
        class="formItem"
        label="实控人"
        placeholder="自动生成"
        disabled
        v-model="businessSelectedItem.realName" />
      <RequiredFormItemInput
        class="formItem"
        label="实控人编号"
        placeholder="自动生成"
        disabled
        v-model="businessSelectedItem.bossNumber" />
    </el-form>
  </div>
</template>
<script>
import {
  RequiredFormItemInput,
  RequiredFormItemSelect
} from '@/components/CustomFormItem'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import * as BusinessApi from '@/api/attractPlatform/business'
import * as CommonApi from '@/api/common'
import { getMasterDataList } from '@/api/businessOos'
import HeaderTitle from './headerTitle.vue'
export default {
  props: {
    formStatus: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    HeaderTitle,
    RequiredFormItemInput,
    RequiredFormItemSelect
  },
  data() {
    return {
      form: {
        oldBusinessLicenseId: '', //售达方Id
        authorizedCityCode: '', //授权城市编号
        brandIdList: [], //转让品牌
        marketCenterName: '',
        marketCenterCode: '',
        brandRegionCode: '', //品牌区域编码
        marketLevelCode: '',
        marketLevelName: '',
        marketLevelTypeCode: '',
        marketLevelTypeName: ''
      },
      rules: [
        { required: true, message: '必填项', trigger: ['blur', 'change'] }
      ],
      businessCountTotal: null,
      businessSelectedItem: {
        businessLicenseName: '',
        bossNumber: '',
        realName: ''
      },
      senderIdList: [],
      senderList: [],
      agencyBrandIdList: []
    }
  },
  watch: {
    historicalData: {
      handler(historicalData) {
        const brandIdList = historicalData.brandIdList
        this.setBrandIdList(brandIdList)
        if (historicalData.oldBusinessLicenseId) {
          this.form = {
            oldBusinessLicenseId: historicalData.oldBusinessLicenseId, //售达方Id
            authorizedCityCode: historicalData.authorizedCityCode, //授权城市编号
            brandIdList, //转让品牌
            marketCenterName: historicalData.marketCenterName,
            marketCenterCode: historicalData.marketCenterCode,
            brandRegionCode: historicalData.brandRegionCode
          }
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('dealershipTransfer', [
      'isOA',
      'brandList',
      'dealerBusinessParams',
      'authorizedCityParams',
      'historicalData'
    ]),
    ...mapGetters('commonConfig', ['bigBrandMap']),
    customBrandList() {
      return this.brandList.map(v => {
        const disabled =
          this.form.brandIdList.length > 0
            ? this.brandList.find(v => v.brandId === this.form.brandIdList[0])
                ?.bigBrandId !== v.bigBrandId
            : false
        return {
          ...v,
          disabled
        }
      })
    },
    getCityDataListParams() {
      return {
        businessLicenseId: this.form.oldBusinessLicenseId
      }
    },
    getBrandOrSenderDataListParams() {
      return {
        businessLicenseId: this.form.oldBusinessLicenseId,
        authorizedCityCode: this.form.authorizedCityCode
      }
    }
  },
  methods: {
    ...mapMutations('dealershipTransfer', [
      'setAuthorizedCityParams',
      'setBrandList',
      'setDealerBusinessParams',
      'setBrandIdList',
      'setBrandLeagueCoefficientInfo'
    ]),
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    //获取授权城市列表
    async getCityList({ page, searchText, getDataListParams }) {
      const res = await CommonApi.getAuthorizedCityData({
        ...getDataListParams
      })
      return {
        ...res,
        totalCount: res.data.length
      }
    },
    // 获取授权城市选中项
    onChangeCitySelectedItem(e) {
      if (e) {
        this.getSenderList()
        this.getBrandList()
      }
      this.setAuthorizedCityParams(
        e
          ? {
              ...e,
              area: e?.area || ''
            }
          : {}
      )
      this.$emit('changeCity', e?.authorizedCityName || '')
    },
    // 获取送达方
    async getSenderList() {
      const res = await getMasterDataList({
        businessLicenseId: this.form.oldBusinessLicenseId,
        authorizedCityCode: this.form.authorizedCityCode
      })
      this.senderList = res.data
      this.senderIdList = res.data.map(item => item.id)
    },
    // 获取品牌
    async getBrandList() {
      const res = await CommonApi.getShopBrandData({
        businessLicenseId: this.form.oldBusinessLicenseId,
        authorizedCityCode: this.form.authorizedCityCode,
        ifEnabled:
          this.formStatus === '' || [0, 20].includes(this.formStatus) ? 0 : -1
      })
      this.setBrandList(res.data)
      if (this.formStatus === 40) {
        const { brandIdList } = historicalData
        this.agencyBrandIdList = res.data
          .filter(item => brandIdList.includes(item.brandId))
          .map(item => item.brandId)
      } else {
        this.agencyBrandIdList = res.data.map(item => item.brandId)
      }
    },
    //根据授权城市选择带出省市区并获取品牌
    async changeCity(val) {
      this.$emit('changeAuthorizedCityCode', val)
    },
    onChangeBrandSelectedItem(e) {
      if (e) {
        const brandIds = e.map(item => item.brandId)
        this.setBrandIdList(brandIds)
        this.$emit('changeBrand', brandIds)
      } else {
        this.setBrandIdList([])
        this.$emit('changeBrand', [])
      }
    },
    //授权城市下选中的品牌
    async changeBrand(arr) {
      if (!arr.length) {
        this.setBrandLeagueCoefficientInfo(null)
      }
      if (arr.length) {
        let brandItems = this.brandList.filter(item =>
          arr.includes(item.brandId)
        )
        let bigbrandIds = brandItems.reduce(
          (prev, curr) =>
            prev.includes(curr.bigBrandId) ? prev : [...prev, curr.bigBrandId],
          []
        )
        const {
          provinceCode = '',
          cityCode = '',
          areaCode = '',
          townCode = ''
        } = this.authorizedCityParams
        const {
          data: { smallCode = '', smallName = '', brandRegionCode = '' }
        } = await CommonApi.requestGetSizeBarListByDeptMerge({
          provinceCode,
          cityCode,
          areaCode,
          townCode,
          deptId: '',
          brandIds: bigbrandIds.join(',')
        })
        this.form.marketCenterName = smallName
        this.form.marketCenterCode = smallCode
        this.form.brandRegionCode = brandRegionCode
      } else {
        this.form.marketCenterName = ''
        this.form.marketCenterCode = ''
        this.form.brandRegionCode = ''
        this.form.marketLevelCode = ''
        this.form.marketLevelName = ''
        this.form.marketLevelTypeCode = ''
        this.form.marketLevelTypeName = ''
      }
      if (arr.length === 1 && !this.form.marketLevelCode) {
        const { bigBrandId } = this.brandList.find(
          ({ brandId }) => brandId === arr[0]
        )
        const { data: cityLevels } = await CommonApi.requestGetCityLevel({
          authorizedCityCode: this.form.authorizedCityCode,
          brandId: bigBrandId
        })
        const [cityLevel] = cityLevels
        if (cityLevel) {
          this.form.marketLevelCode = cityLevel.marketLevelCode
          this.form.marketLevelName = cityLevel.marketLevelName
          this.form.marketLevelTypeCode = cityLevel.marketLevelType
          this.form.marketLevelTypeName = cityLevel.marketLevelTypeName
        }
      }
    }
  }
}
</script>
