<template>
  <div>
    <!-- <div
      v-if="isMobile && isApproval"
      id="baseInfoWrapper"
      v-loading="loadingTitle">
      <iframe
        frameBorder="0"
        width="100%"
        height="100%"
        @load="loadingTitle = false"
        @error="loadingTitle = false"
        :src="`${bpmIframeUrl}/mobile/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/baseInfo?fdProcessId=${$route.query.processId}&maxHeight=65vh`" />
    </div> -->
    <div class="flow-title" v-if="isMobile && isApproval">
      <h2>{{ title }}</h2>
      <div
        class="flow-title__icon"
        :style="{
          background: `url(${processStatus.icon}) center center / contain no-repeat`
        }">
        <span>{{ processStatus.name }}</span>
      </div>
    </div>

    <el-form
      v-if="bigBrandMap"
      ref="ruleForm"
      v-loading="submitLoading"
      v-bind="$attrs"
      status-icon
      class="flow-form"
      label-position="top"
      :inline="true"
      :hide-required-asterisk="disabled"
      :style="{
        padding: isOA ? '20px' : 0,
        paddingTop: fixedHeader && !isMobile ? '62px' : 0
      }"
      v-on="$listeners"
      @validate="onValidate">
      <div
        v-if="!isMobile"
        style="
          display: flex;
          z-index: 10;
          right: 0;
          justify-content: space-between;
          align-items: center;
          background-color: #fff;
          padding: 15px 20px;
        "
        :style="{
          width: `${contentBoxSize.inlineSize + 40}px`,
          marginLeft: !fixedHeader ? '-20px' : 0,
          position: fixedHeader ? 'fixed' : 'static',
          boxShadow: fixedHeader ? '0 0 4px 0 rgba(0,0,0,0.08)' : 'inherit',
          top: fixedHeader ? 0 : 'inherit'
        }">
        <div style="font-weight: bold; min-width: calc(100vw - 114px)">
          {{ title }}
        </div>
        <el-popover
          v-if="isApproval"
          v-model="approvalPopover"
          width="500"
          trigger="click">
          <div style="height: 80vh; overflow-y: auto">
            <iframe
              id="flow-chart"
              style="border: none"
              width="100%"
              height="750"
              :src="iframeUrls.pcAuditForm" />
          </div>
          <el-button
            slot="reference"
            type="primary"
            class="approval-btn"
            @click="openApprovalPopover">
            审批
          </el-button>
        </el-popover>
      </div>

      <div class="tips">
        <ol>
          <li v-for="(content, index) of tips" :key="index">{{ content }}</li>
        </ol>
      </div>

      <slot v-if="!loading" />
      <slot name="approval-form">
        <approvalForm
          ref="approvalFormRef"
          :fd-form-template-code="fdFormTemplateCode"
          :approval-data="approvalData"
          :approval-flow-params="approvalFlowParams"
          :approval-data-rules="approvalDataRules"
          v-on="$listeners">
          <template #approval-divider="scoped">
            <slot name="approval-divider" v-bind="scoped" />
          </template>
          <template #custom-item="scoped">
            <slot name="custom" v-bind="scoped" />
          </template>
        </approvalForm>
      </slot>
    </el-form>

    <!-- <el-button @click="$refs.approvalFormRef.onSubmit()">click</el-button> -->

    <!-- 底部 -->
    <div v-if="isApproval" style="margin-top: 20px">
      <el-card v-if="!isMobile" type="card">
        <el-tabs>
          <el-tab-pane label="审批记录">
            <iframe
              id="audit-notes"
              style="border: none"
              width="100%"
              height="1000"
              :src="`${bpmIframeUrl}/desktop/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/auditNotes?fdProcessId=${$route.query.processId}`" />
          </el-tab-pane>
          <el-tab-pane :lazy="true" label="流程状态">
            <iframe
              id="audit-notes"
              style="border: none"
              width="100%"
              height="1000"
              :src="`${bpmIframeUrl}/desktop/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/processStatus?fdProcessId=${$route.query.processId}`" />
          </el-tab-pane>
          <el-tab-pane :lazy="true" label="流程图">
            <iframe
              id="flow-chart"
              style="border: none"
              width="100%"
              height="1000"
              :src="`${bpmIframeUrl}/desktop/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/auditChart?fdProcessId=${$route.query.processId}`" />
          </el-tab-pane>
          <el-tab-pane :lazy="true" label="操作日志">
            <iframe
              id="audit-logs"
              style="border: none"
              width="100%"
              height="1000"
              :src="`${bpmIframeUrl}/desktop/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/auditLogs?fdProcessId=${$route.query.processId}`" />
          </el-tab-pane>
          <el-tab-pane :lazy="true" label="基本信息">
            <iframe
              id="audit-basicInfo"
              style="border: none"
              width="100%"
              height="1000"
              :src="`${bpmIframeUrl}/desktop/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/basicInfo?fdProcessId=${$route.query.processId}`" />
          </el-tab-pane>
          <el-tab-pane :lazy="true" label="传阅记录">
            <iframe
              id="audit-circulated"
              style="border: none"
              width="100%"
              height="1000"
              :src="`${bpmIframeUrl}/desktop/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/auditcirculated?fdProcessId=${$route.query.processId}`" />
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <template v-else>
        <!-- 审批纪录时间轴 -->
        <div id="auditNotesWrapper" style="overflow: hidden">
          <iframe
            id="auditNotes"
            frameBorder="0"
            scrolling="no"
            width="100%"
            height="100%"
            :src="iframeUrls.modileAuditNotes" />
        </div>
        <div
          id="auditFormWrapper"
          style="
            position: fixed;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff;
            height: 0;
            z-index: 99;
          "
          :style="{ height: `${floatBarHeight}` }">
          <iframe
            style="display: block"
            frameBorder="0"
            width="100%"
            height="100%"
            :src="`${bpmIframeUrl}/mobile/?___mk_sso_appName=tic-auth&code=BPM${ssoToken}#/lbpmIntegrate/integrate/auditForm?fdProcessId=${$route.query.processId}`" />
        </div>
      </template>
    </div>

    <div :style="{ height: `${floatBarHeight || '80px'}` }" />
    <footer v-if="!isOA && !isApproval" class="footer">
      <el-button type="primary" @click="onCommit('submit')">
        {{ submitButtonText }}
      </el-button>
      <el-button v-if="showSave" type="info" @click="onCommit('save')">
        保存
      </el-button>
      <el-button style="margin-left: 50px" @click="onCancel">取消</el-button>
    </footer>
  </div>
</template>

<script>
import { positionToError } from '@/utils/index'
import { setSession, getSession, removeSession } from '@/utils/storage'
import { mapState, mapGetters } from 'vuex'
import { mergeStep, postMessageIframe } from '@/utils/index'
import { Message } from 'element-ui'
import approvalForm from './approvalForm.vue'
import { bpmCallbackApi } from '../api'
export default {
  components: { approvalForm },
  provide() {
    return {
      bpmFlow: this
    }
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    getDetail: {
      type: Function,
      default: null
    },
    onSubmit: {
      type: Function,
      default: null
    },
    onApproval: {
      type: Function,
      default: null
    },
    tips: {
      type: Array,
      default: () => []
    },
    showSave: {
      type: Boolean,
      default: true
    },
    approvalData: {
      type: Object,
      default: () => ({})
    },
    approvalDataRules: {
      type: Object,
      default: () => ({})
    },
    fdFormTemplateCode: {
      type: String,
      default: ''
    },
    approvalFlowParams: {
      type: Object,
      default: () => ({})
    },
    submitButtonText: {
      type: String,
      default: '发起流程'
    }
  },
  data() {
    const withCache = !this.formId
    return {
      withCache,
      loading: true,
      pageInfo: {
        type: 'create' // or 'detail' 默认新建表单
      },
      submitLoading: false,
      contentBoxSize: {
        inlineSize: 0,
        blockSize: 0
      },
      contentBoxTop: undefined,
      approvalPopover: false,
      bpmIframeUrl: process.env.VUE_APP_BPM_IFRAME_URL + '/sys-lbpm',
      floatBarHeight: '0px',
      loadingTitle: true,
      iframeUrls: {
        modileAuditNotes: '',
        pcAuditForm: ''
      },
      processStatus: {}
    }
  },

  computed: {
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA,
      isMobile: state => {
        return state.app.device === 'mobile' || state.app.isWxworkMoblie
      },
      userInfo: state => state.user.userInfo,
      ssoToken: state => state.user.ssoToken
    }),
    ...mapGetters('commonConfig', ['bigBrandMap']),
    formId({ $route: { query } }) {
      return query.id || query.businessId
    },
    fixedHeader({ contentBoxTop }) {
      if (contentBoxTop === undefined) {
        return false
      }
      return contentBoxTop < 20
    },
    isApproval({ $route: { query } }) {
      return !!query.processId
    },
    disabled({ isEdit, isOA }) {
      return !isEdit && isOA
    }
  },
  created() {
    const that = this
    this.initData()
    window.addEventListener('beforeunload', this.$destroy.bind(this, undefined))

    // 获取内容区域宽高
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        if (entry.contentBoxSize) {
          this.contentBoxSize = entry.contentBoxSize[0]
        }
      }
    })
    // 获取滚动相对距离
    window.addEventListener('scroll', () => {
      this.contentBoxTop = document
        .querySelector('.flow-form')
        .getBoundingClientRect().top
    })
    setTimeout(() => {
      observer.observe(document.querySelector('.flow-form'))
    }, 1000)

    window.addEventListener('message', function (event) {
      // console.log('message', event)
      if (event && event.data && event.data.type === 'submit') {
        // 点击提交事件，可先校验业务表单的内容，然后再处理流程的数据
        const formValues = event.data.formValues
        console.log('evev', event.data)
        if ('errorFields' in formValues) {
          Message('请填写所有必填表单项')
        } else {
          // 提交数据逻辑可参考场景1
          // Message('提交的表单数据为：' + JSON.stringify(event.data))
          that.approval(event.data.formValues)
        }
      }
    })
    this.mobileDomAction()
  },
  destroyed() {
    window.removeEventListener(
      'beforeunload',
      this.$destroy.bind(this, undefined)
    )
    if (this.withCache) {
      setSession(this.title, this.$attrs.model)
    } else {
      removeSession(this.title)
    }
  },
  methods: {
    async initData() {
      if (!this.getDetail) return
      const loadingClient = this.$loading({
        lock: true,
        text: '加载中,请稍等',
        background: 'rgba(255, 255, 255, 0.7)'
      })
      try {
        const cachedFormData = getSession(this.title)
        removeSession(this.title)
        if (this.formId && this.getDetail) {
          this.pageInfo.type = 'detail'
        }

        await this.getDetail(
          this.pageInfo.type,
          this.withCache ? cachedFormData : undefined
        )
        this.errorCount = 0
        // 传送父级iframe高度
        postMessageIframe()
      } catch (error) {
        console.error('初始化数据失败', error)
        // if (!this.errorCount) {
        //   this.errorCount = 1
        // } else {
        //   this.errorCount++
        // }
        // if (this.errorCount > 2) return
        // this.$alert('刷新重试', '初始化数据失败', {
        //   confirmButtonText: '确定',
        //   callback: action => {
        //     if (action === 'confirm') {
        //       this.initData()
        //     }
        //   }
        // })
      } finally {
        loadingClient.close()
        this.loading = false
      }
    },
    setLoading(bool) {
      this.submitLoading = bool
    },
    async validateForm() {
      try {
        await this.$refs.ruleForm.validate()
        return false
      } catch (error) {
        this.$nextTick(positionToError)
        return true
      }
    },
    onCancel() {
      this.withCache = false
      this.$router.go(-1)
    },
    onValidate(_, flag, message) {
      if (!flag && message && this.validateFlag) {
        this.$message.warning(message)
        this.validateFlag = false
      }
    },
    // 打开审核弹窗，刷新一次审核表单权限
    openApprovalPopover() {
      if (this.approvalPopover) return
      this.$refs.approvalFormRef?.getQueryColumnAuthority()
    },
    async approval(data = {}) {
      const operationType = data.operationType?.split('_')[1]
      const approvalFormRef = this.$refs.approvalFormRef
      const form = approvalFormRef
        ? await approvalFormRef.onSubmit(operationType === 'pass')
        : undefined

      await this.onApproval(data, form)
      if (data.operationType === 'drafter_retract') {
        await bpmCallbackApi({
          fdFormInstanceId: this.formId,
          fdFormTemplateCode: this.fdFormTemplateCode,
          status: 0
        })
      }
      this.approvalPopover = false
      this.$message.success('审批成功')
      setTimeout(() => {
        // 如果是移动端企业微信环境，则跳转到OA待办页面
        if (
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          ) &&
          navigator.userAgent.match(/wxwork/i) == 'wxwork'
        ) {
          location.href = process.env.VUE_APP_OA_TODO_URL
          return
        }
        location.reload()
      }, 300)
    },
    onCommit: mergeStep(async function (type) {
      this.withCache = false
      this.submitLoading = true
      if (type === 'save') {
        if (this.onSubmit) {
          try {
            await this.onSubmit('save')

            this.$message.success('保存成功')
            this.$router.replace({
              name: 'draft'
            })
          } catch (error) {
            console.log(error)
          }
        } else {
          this.$emit('onSave')
        }
        // 草稿
        this.submitLoading = false
        return
      }
      this.validateFlag = true
      if (type === 'submit') {
        return new Promise((resolve, reject) => {
          this.$refs.ruleForm.validate(async valid => {
            if (!valid) {
              reject('请检查表单项')
              return this.$nextTick(positionToError)
            }
            if (this.onSubmit) {
              try {
                await this.onSubmit('submit')
                await this.$alert('操作成功', '提示', {
                  confirmButtonText: '确定',
                  type: 'success'
                })
                // this.$message.success('流程发布中')
                this.$router.replace({
                  name: 'myApply'
                })
                resolve()
              } catch (error) {
                reject(error)
              }
            } else {
              // 提交
              this.$emit('onSubmit')
              resolve()
            }
          })
        }).finally(() => {
          this.submitLoading = false
        })
      }
    }),
    clearValidate(prop) {
      this.$nextTick(() => {
        this.$refs['ruleForm'].clearValidate(prop)
      })
    },
    // 移动端
    mobileDomAction() {
      const that = this
      const { processId = '' } = that.$route.query
      window.addEventListener('message', function (event) {
        const eventData = event && event.data
        if (!eventData) {
          return
        }

        if (eventData.type) {
          console.log(eventData.type, eventData.value)
        }

        if (eventData.type === 'getFormValues') {
          const formValues = eventData.formValues
          if ('errorFields' in formValues) {
            console.log('请填写所有必填表单项')
          } else {
            // 参照方案一的提交逻辑
            console.log('通过必填校验，提交审批')
            that.approval(formValues)
          }
        }
        if (eventData.type === 'auditNotesHeight') {
          const height = eventData.value
          document.querySelector('#auditNotesWrapper').style.height = height
        }
        if (eventData.type === 'auditFormHeight') {
          const height = eventData.value
          that.floatBarHeight = height
        }
        if (eventData.type === 'baseInfoHeight') {
          // 基础信息卡片
          const height = eventData.value
          document.querySelector('#baseInfoWrapper').style.height = height
        }

        //iframe 加载完成 逐个加载url
        if (eventData.type === 'onLbpmCpmtInit') {
          //pc端逐个iframe加载
          if (
            !that.isMobile &&
            eventData.value &&
            eventData.value.cmptName == 'auditNotes'
          ) {
            that.iframeUrls.pcAuditForm = `${that.bpmIframeUrl}/desktop/?___mk_sso_appName=tic-auth&code=BPM${that.ssoToken}#/lbpmIntegrate/integrate/auditForm?fdProcessId=${processId}&fdLoginName=${that.userInfo.code}&submitBtnPosition=card`
          }

          //移动端逐个iframe加载
          if (
            that.isMobile &&
            eventData.value &&
            eventData.value.cmptName == 'auditForm'
          ) {
            that.iframeUrls.modileAuditNotes = `${that.bpmIframeUrl}/mobile/?___mk_sso_appName=tic-auth&code=BPM${that.ssoToken}#/lbpmIntegrate/integrate/auditNotes?fdProcessId=${processId}`
          }
        }
        //获取iframe审批状态
        if (eventData.type === 'processInfo') {
          const statusEnum = {
            '00': {
              icon: require('@/assets/img/icon/pause.png'),
              name: '废弃'
            },
            '01': { icon: require('@/assets/img/icon/info.png'), name: '新建' },
            10: { icon: require('@/assets/img/icon/info.png'), name: '草稿' },
            20: { icon: require('@/assets/img/icon/info.png'), name: '待审' },
            21: { icon: require('@/assets/img/icon/error.png'), name: '异常' },
            30: {
              icon: require('@/assets/img/icon/success.png'),
              name: '结束'
            },
            40: { icon: require('@/assets/img/icon/warning.png'), name: '挂起' }
          }
          that.processStatus = statusEnum[eventData.value.processStatus]
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/colors.scss';
.flow-title {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  & h2 {
    font-size: 16px;
    flex: 1;
    padding-top: 10px;
    line-height: 1.3;
  }
  &__icon {
    height: 60px;
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      color: #409eff;
      font-size: 10px;
      transform: rotate(30deg);
    }
  }
}
.flow-form {
  ol {
    list-style: none;
    padding: 0;
    line-height: 1.5;
    color: $danger-dark-color;
  }
  .main-title {
    ::v-deep {
      .title-cell {
        &::before {
          background-color: $title-dark-color;
        }
      }
    }
  }
  ::v-deep {
    .title-cell {
      margin-top: 30px;
      padding-left: 10px;
      margin-bottom: 10px;
      position: relative;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        width: 4px;
        height: 10px;
        background-color: $primary-color;
        transform: translateY(-50%);
      }
    }
    .el-divider.el-divider--horizontal {
      display: none;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .el-table__row {
      .el-form-item {
        width: 100%;
        margin: 0;
        &.is-error {
          margin-bottom: 22px;
        }
      }
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    padding: 16px;
    background-color: #fff;
    z-index: 99;
  }
}
.approval-btn {
  position: fixed;
  right: 20px;
  top: 10px;
  z-index: 3000;
}
</style>
