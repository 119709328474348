import request from '@/utils/request'
import { mergeStep } from '@/utils/index'
// 授权城市编码获取省市区
export function getByAuthorizedCityCode(params) {
  return request({
    url: '/dealermanage/authorizedcity/getByAuthorizedCityCode',
    method: 'get',
    params
  })
}

/**
 * 分页授权城市列表
 * @see http://10.12.0.62:8182/docs/operate/operate-1elfr1q3n4kt6
 * @param {object} query
 * @param {number} query.currPage
 * @param {number} query.pageSize
 * @param {string} [query.authorizedCityCode]
 * @param {string} [query.brandId]
 */
export async function getAuthorizedCities(query) {
  const res = await request({
    url: '/dealermanage/authorizedcity/authorizedCityAndLevel',
    method: 'get',
    params: query
  })
  return res
}

export function getProvinceOrCityOrAreaList(params) {
  return request({
    url: '/dealermanage/authorizedcity/getProvinceOrCityOrAreaList',
    method: 'get',
    params
  })
}
// 大品牌列表,分页
export function brandseriesListpage(params) {
  return request({
    url: '/dealermanage/brandseries/listpage',
    params: {
      ...params,
      pageSize: 100
    }
  })
}
// 获取售达方对应的授权城市
export function getAuthorizedCityData(params) {
  return request({
    url: '/dealermanage/deliveryparty/getAuthorizedCity',
    params
  })
}
// 获取售达方分页数据
export function getBusinessPageData(params) {
  return request({
    url: '/dealermanage/businesslicense/listpage',
    params
  })
}
// 获取售达方/授权城市获取对应的品牌信息
export function getShopBrandData(params) {
  return request({
    // url: "/dealermanage/shopbrand/detailsList",
    url: '/dealermanage/deliveryparty/detailsList',
    params
  })
}

// 阿里oss配置
export function getOssInfo() {
  return request({
    url: '/consumermanage/api/getOssInfo'
  })
}
// 华为obs配置
export function getObsInfo(data) {
  return request({
    url: '/consumermanage/api/createTemporaryAccess',
    data
  })
}

// 售达方列表
export const getBusinesslicenseList = params =>
  request({ url: '/dealermanage/businesslicense/list', params })

// 根据营业执照（售达方）查询送达方
export const getSenderList = params =>
  request({
    url: '/dealermanage/deliveryparty/getSenderListByBusinessLicenseId',
    params
  })

// 根据营业执照（售达方）查询送达方
export const getAuthorizedCity = params =>
  request({
    url: '/dealermanage/shopbrand/getAuthorizedCityPageByBusinessLicenseId',
    params
  })

// 根据营业执照（售达方）查询送达方
export const getBossByBusinessLicenseId = params =>
  request({ url: '/dealermanage/boss/getBossByBusinessLicenseId', params })

// 根据营业执照（售达方）查询门店
export const getShopListByBusinessLicenseId = params =>
  request({ url: '/dealermanage/shop/getShopListByBusinessLicenseId', params })

// 根据身份证查经销商基础信息
export const getDealerBossInfo = params =>
  request({ url: '/dealermanage/boss/list', params })

// 根据售达方-品牌-查询门店
export const getShopList = params =>
  request({ url: '/dealermanage/shop/detailsList', params })

// 获取城市详情
export const getJoinCityDetail = params =>
  request({
    url: '/dealermanage/joincity/listpage',
    params
  })

// 根据授权城市查门店
export const getCityShopList = params =>
  request({
    url: '/dealermanage/shopRevoke/getShopList',
    params
  })

// 用户数据字典列表
export const datadictionaryList = params =>
  request({
    url: '/dealermanage/datadictionary/listpage',
    params
  })
// 大品牌列表
export const brandseriesList = params =>
  request({
    url: '/dealermanage/brandseries/list',
    params
  })
// 所有品牌列表(包含大小品牌)
export const brandParentList = params =>
  request({
    url: '/dealermanage/brandseries/parentList',
    params
  })
// 获取门店/商场类型文本
export const getAnyTypeList = params =>
  request({
    url: '/dealermanage/datadictionary/list',
    params
  })
// 获取经销商老板信息(登录专用)
export const getbossforlogin = params =>
  request({
    url: '/dealermanage/boss/getbossforlogin',
    params
  })
// 流程重新提交
export const onResubmit = params =>
  request({
    url: '/dealermanage/declarationform/andReload',
    params
  })
// 流程重新提交
export const getArea = params =>
  request({
    url: '/dealermanage/region/address',
    params
  })
// 门店全系列
export const getBrandAllData = () =>
  request({
    url: '/dealermanage/brandseries/list',
    params: {
      brandLevel: 1
    }
  })

/**
 * 财务开户视图
 * 该接口只部署到测试域名下,不需要切换域名
 */
export const bankList = params =>
  request({
    baseURL: 'https://chandao.derucci.com:6443/consumer-admin',
    url: '/sys/user/viewList',
    params
  })

/**
 * 银行代码查询
 */
export const bankCodeQuery = async params => {
  return request({
    url: '/commonservice/sap/post',
    method: 'POST',
    data: {
      jsonData: params,
      requestUrl: '/RESTAdapter/oos/querybackcode'
    }
  })
}

/**
 * 校验
 */
export const checkBossPhone = params => {
  return request({
    url: '/dealermanage/operationflow/flowCommonApi/checkBossPhone',
    method: 'POST',
    params
  })
}

// 请求省、市
export const getProvinceWidthCity = params => {
  return request({
    url: '/dealermanage/region/provinceAndCity',
    params
  })
}

// 获取历史版本通知
export const getHistoryNotify = params => {
  return request({
    url: '/consumermanage/essay/listpage',
    params: {
      ...params,
      ascriptionModular: 40,
      classificationId: 30
    }
  })
}

/**
 * @description: 根据部门授权城市查大区
 * @param {import('vue').PropType<{provinceCode?:string,deptId?:string,cityCode?:string,areaCode:string}>} params
 */
export const requestGetSizeBarListByDept = mergeStep(params => {
  console.log(params)
  return request({
    url: '/dealermanage/areadept/sizeBarList',
    params: {
      deptId: '1390103620495798273',
      ...params
    }
  })
})

/**
 * @description: 根据部门授权城市查大区，返回列表，内部合并为一个对象
 * @param {import('vue').PropType<{provinceCode?:string,deptId?:string,cityCode?:string,areaCode:string}>} params
 */
export const requestGetSizeBarListByDeptMerge = mergeStep(async params => {
  const res = await request({
    url: '/dealermanage/areadept/modelList',
    params: {
      deptId: '1390103620495798273',
      ...params
    }
  })
  if (Array.isArray(res.data)) {
    // 合并列表，将值全部合并为一个对象
    const mergeData = res.data.reduce((prev, next) => {
      for (const key in next) {
        if (!prev[key]) {
          prev[key] = []
        }
        // 去重复
        if (prev[key].includes(next[key])) {
          continue
        }
        prev[key].push(next[key])
      }
      return prev
    }, {})

    // 将value的数组转换为字符串英文,分割
    for (const key in mergeData) {
      mergeData[key] = mergeData[key].join(',')
    }
    return { data: mergeData }
  } else {
    return res
  }
})

/**
 * @description: 获取历史表单详情
 * @param {*}
 */
export const requestGetHistoryFlow = params => {
  return request({
    url: '/dealermanage/datahistory/list',
    params
  })
}

/**
 * @description: 获取城市级别
 * @param {*}
 */
export const requestGetCityLevel = params => {
  return request({
    url: '/dealermanage/authorizedcitylevel/list',
    params: {
      brandId: '1390119720428154881',
      ...params
    }
  })
}
/**
 * @description: 字典添加
 * @param {*} data
 */
export const requestAddDatadictionary = data => {
  return request({
    url: '/dealermanage/datadictionary/updateList',
    data,
    method: 'post'
  })
}

/**
 * @description: 根据要求获取门店信息
 * @param {object} params
 * @param {string} params.bossId 实控人id
 * @param {string} params.marketName 商城名称
 * @param {string} params.authorizedCityCode 授权城市编号
 */
export const requestGetStoreData = params => {
  return request({
    url: '/dealermanage/shop/listpage',
    params
  })
}

/**
 * @description: 根据手机号码获取实控人
 * @param {object} params
 * @param {string} params.contactInfo 手机号码
 * @param {string} params.ifEnabled 状态 0生效 1失效
 */
export const requestBossListByContactInfo = params => {
  console.log(params)
  return request({
    url: '/dealermanage/boss/getBossListByContactInfo',
    params
  })
}

/**
 * @description: 根据营业执照名字获取售达方列表
 * @param {object} params
 * @param {string} params.businessLicenseName 营业执照名字
 * @param {string} params.ifEnabled 状态 0生效 1失效
 */
export const requestBossListByBusinessLicenseName = params => {
  return request({
    url: '/dealermanage/businesslicense/getBusinessLicenseListByName',
    params
  })
}

/**
 * @description: 运营平台账号列表
 * @param {object} params
 * @param {string} params.searchVal 可以填工号，也可以填姓名，进行模糊查询，如果不传，则返回所有的用户信息
 * @param {string} params.page 当前页码
 * @param {string} params.limit 每页显示条数
 */
export const requestGetUserList = params => {
  return request({
    url: '/consumer-admin/sys/user/listTotal',
    params
  })
}
export const requestGetDesignerList = params => {
  return request({
    url: '/consumermanage/stylist/listpage',
    params
  })
}

/**
 * @description: 根据经销商统计门店数量
 * @param {*}
 * @return {*}
 */
export const requestGetShopCountByDealer = mergeStep(params => {
  return request({
    url: '/dealermanage/shopbrand/getShopIdCount',
    params
  })
})
/**
 * @description: 根据经销商是否有到期未重装门店
 * @param {*}
 * @return {*}
 */
export const requestDealerIfReShop = params => {
  return request({
    url: '/dealermanage/shop/getIfReassembly',
    params
  })
}
/**
 * @description: 获取授权城市等级,authorizedCityCode+brandId/seriesCodeList
 */
export const requestGetCityLevel2 = data => {
  return request({
    url: '/dealermanage/authorizedcitylevel/getlevel',
    method: 'POST',
    data
  })
}

/**
 * 报表管理-报表任务列表
 */
export const reportTaskList = async(params, showMessage) => {
  return request({
    showMessage,
    url: '/report/reportinfo/listpage',
    method: 'GET',
    params,
    baseURL: process.env.VUE_APP_REPORT_URL
  })
}

/**
 * 报表管理-报表下载次数
 */
export const reportTaskDownloadNumber = async params => {
  return request({
    url: '/report/reportinfo/downloadNumber',
    method: 'GET',
    params,
    baseURL: process.env.VUE_APP_REPORT_URL
  })
}
/**
 * 报表管理-报表任务取消
 */
export const reportTaskCancel = async params => {
  return request({
    url: '/report/reportinfo/taskCancel',
    method: 'GET',
    params,
    baseURL: process.env.VUE_APP_REPORT_URL
  })
}

/**
 * 报表管理-报表任务取消
 */
export const reportTaskRead = async params => {
  return request({
    url: '/report/reportinfo/read',
    method: 'GET',
    params,
    baseURL: process.env.VUE_APP_REPORT_URL
  })
}
// 根据经销商编码获取售达方
export const requestGetBusinesslicense = params => {
  return request({
    url: '/dealermanage/businesslicense/crmDistributor/listpage',
    params
  })
}

// 从金蝶获取相关人员
export const requestJinDieHr = params => {
  return request({
    url: '/commonservice/jindie/hr/get',
    params: {
      requestUrl: '/my_app/syncData/person/getAllData',
      isLeft: 0,
      count: 200,
      ...params
    }
  })
}

/**
 * @description: 授权城市、品牌查大区
 * @param {import('vue').PropType<{provinceCode?:string,deptId?:string,cityCode?:string,areaCode:string}>} params
 */
export const requestCityAndBrandFindMarket = mergeStep(params => {
  console.log(params)
  return request({
    url: '/dealermanage/areadept/modelList',
    params
  })
})

// 获取部门树
export const getTree = params => {
  return request({
    method: 'get',
    url: '/consumer-admin/sys/user/userTree',
    params
  })
}

// 工作人员
export const getInput = params => {
  return request({
    method: 'get',
    url: '/consumer-admin/sys/user/listTotal',
    params
  })
}

// 获取运营平台参数管理列表
export const getSysConfigList = params =>
  request({
    url: '/consumer-admin/sys/config/list',
    params: {
      page: 1,
      limit: 1,
      sidx: 'paramKey',
      ...params
    }
  })
// 获取财务品牌加盟系数
export const getBrandLeagueCoefficientInfo = data => {
  return request({
    method: 'POST',
    url: '/dealermanage/app/configuration/getBrandLeagueCoefficientInfo',
    data
  })
}
