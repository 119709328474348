<template>
  <flow-form
    ref="flowFormRef"
    :model="form"
    :on-submit="onSubmit"
    :get-detail="getDetail"
    title="渠道合作申请表"
    :tips="[
      '1、渠道合作申请表：非经销渠道的合作申请',
      '2、此流程仅针对授权管理，不涉及门店信息，不生成门店账号。后续需要开店请走新店流程'
    ]"
  >
    <div>
      <required-form-item-input
        v-for="(value, key) in formLayout.title"
        :key="key"
        delay-update
        class="formItem"
        :prop="`dealerDeclarationForm[${key}]`"
        :value="
          key === 'title'
            ? (form.dealerDeclarationForm[key] = title)
            : form.dealerDeclarationForm[key]
        "
        :label="value"
        placeholder="自动生成"
        disabled
        :required="false"
      />
      <!-- 招商单号 -->
      <RequiredFormItemInput
        v-model="form.zsOrderNumber"
        label="招商单号"
        class="formItem"
        prop="zsOrderNumber"
        placeholder="归档后生成"
        disabled
      />
      <required-form-item-input
        v-for="(value, key) in formLayout.optUser"
        :key="key"
        v-model="form.dealerDeclarationForm[key]"
        delay-update
        class="formItem"
        :prop="`dealerDeclarationForm[${key}]`"
        :label="value"
        disabled
      />

      <required-form-item-input
        v-for="(value, key) in formLayout.finalList"
        :key="key"
        delay-update
        class="formItem"
        :value="form[key]"
        :label="value"
        placeholder="归档后生成"
        disabled
      />
      <required-form-item-input
        delay-update
        class="formItem"
        label="经销商平台账号"
        :value="form.dealerBoss.bossNumber"
        placeholder="归档后生成"
        disabled
      />
    </div>

    <HeaderTitle style="margin-top: 20px" title="营业执照信息" />

    <required-form-item-input
      delay-update
      :placeholder="placeholder['creditCode'] || null"
      class="formItem"
      prop="dealerBusinessLicense.creditCode"
      :value="form.dealerBusinessLicense.creditCode"
      label="社会统一信用代码"
      strong
      :validations="validations.creditCode"
      @blur="
        value => (form.dealerBusinessLicense.creditCode = value.target.value)
      "
    />

    <required-form-item-input
      v-model="form.dealerBusinessLicense.businessLicenseName"
      delay-update
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseName"
      label="营业执照名称"
      strong
      :disabled="isLicenseDisabled"
    />
    <RequiredFormItemInput
      v-model="form.dealerBusinessLicense.businessLicenseShortName"
      delay-update
      :placeholder="placeholder['businessLicenseShortName'] || null"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseShortName"
      label="简称"
      :disabled="isLicenseDisabled"
    />
    <required-module-form-item-select
      v-model="form.dealerBusinessLicense.businessLicenseType"
      class="formItem"
      prop="dealerBusinessLicense.businessLicenseType"
      label="营业执照类型"
      type="business_license_type"
      :disabled="isLicenseDisabled"
    />
    <required-form-item-input
      v-model="form.dealerBusinessLicense.registeredCapital"
      delay-update
      :placeholder="placeholder['registeredCapital'] || null"
      class="formItem"
      prop="dealerBusinessLicense.registeredCapital"
      label="注册资本（万元)"
      :input-props="{
        type: 'number'
      }"
      :disabled="isLicenseDisabled"
    />
    <component
      :is="
        key === 'registerDate'
          ? 'required-form-item-date-picker'
          : 'required-form-item-input'
      "
      v-for="(value, key) in formLayout.dealerBusinessLicense1"
      :key="key"
      v-model="form.dealerBusinessLicense[key]"
      class="formItem"
      :prop="`dealerBusinessLicense[${key}]`"
      delay-update
      :label="value"
      :validations="validations[key] || []"
      :placeholder="placeholder[key] || null"
      :disabled="isLicenseDisabled"
    />
    <div>
      <required-form-item-upload-file
        v-model="form.businessLicenseFileList"
        prop="businessLicenseFileList"
        class="formItem"
        label="上传营业执照"
        limit-size="400"
        :on-preview="onPreview"
      >
        <div slot="describe">（营业执照照片/电子档）<p style="color:red">先上传营业执照可智能识别出营业执照信息。</p></div>
        <div slot="tip">单个文件不能超过400M</div>
      </required-form-item-upload-file>
    </div>

    <HeaderTitle style="margin-top: 20px" title="渠道商基础信息" />

    <!-- :data-list="[
        { value: '23', label: '慕思美居' },
        { value: '47', label: '慕思经典-新渠道' },
        { value: '46', label: '团购配套' },
        { value: '48', label: 'V6电商' },
        { value: '46', label: '慕思工程' },
        { value: '45', label: '智慧睡眠' },
        { value: '35', label: '慕思沙发-新渠道' },
        { value: '24', label: '慕思海外' },
        { value: '59', label: '嘉兴OEM' },
        { value: '15', label: 'Derucci HOME' },
      ]"
       -->
    <required-module-form-item-select
      ref="channelTypeRef"
      v-model="form.channelCooperationName"
      prop="channelCooperationName"
      class="formItem"
      label="渠道合作类型"
      label-key="content"
      value-key="content"
      type="channelJoin"
      @changeSelectedItem="onChangeChannelTypeSelectedItem"
    />

    <required-form-item-select
      v-model="form.dealerChannelCooperation.cooperationCityCode"
      prop="dealerChannelCooperation.cooperationCityCode"
      class="formItem"
      label="合作城市"
      :get-data-list="getAuthList"
      remote
      filterable
      label-key="authorizedCityName"
      value-key="authorizedCityCode"
      @changeSelectedItem="onChangeSelectedItem"
      @onOptionsListNotFound="onOptionsListNotFound"
    />

    <required-module-form-item-select
      v-if="selectedItemAuthCity && selectedItemAuthCity.province === '全国'"
      v-model="form.countryChainFlag"
      class="formItem"
      label="是否全国连锁"
      prop="countryChainFlag"
      type="flow_common_select_ifEnable"
    />
    <required-form-item-input
      :value="form.authorizedCityMarketLevelName"
      label="城市等级"
      class="formItem"
      placeholder="请选择合作城市"
      disabled
    />
    <template v-if="form.channelSeriesCode !== ''">
      <required-form-item-input
        v-for="(value, key) in formLayout.dealerBoss1"
        :key="key"
        v-model="form.dealerBoss[key]"
        delay-update
        class="formItem"
        :prop="`dealerBoss[${key}]`"
        :label="value"
        :validations="validations[key] || []"
        :disabled="isLicenseDisabled"
      />
      <component
        :is="
          typeof value === 'object' && value.moduleSelectType
            ? 'required-module-form-item-select'
            : 'required-form-item-input'
        "
        v-for="(value, key) in formLayout.dealerBoss2"
        :key="key"
        v-model="form.dealerBoss[key]"
        delay-update
        class="formItem"
        :prop="`dealerBoss[${key}]`"
        :label="typeof value === 'object' ? value.label : value"
        :validations="
          (typeof value === 'object' &&
            value.validate !== false &&
            validations[key]) ||
            []
        "
        :placeholder="placeholder[key] || null"
        :type="typeof value === 'object' ? value.moduleSelectType : ''"
        :disabled="isLicenseDisabled"
        :required="!(typeof value === 'object' && value.required === false)"
      />

      <required-module-form-item-select
        v-model="form.dealerCrmDistributor.channelType"
        class="formItem"
        prop="dealerCrmDistributor.channelType"
        label="经销商类型"
        type="dealer_type"
        network
        @changeSelectedItem="
          item => (form.dealerCrmDistributor.channelTypeS = item.content)
        "
      />

      <required-module-form-item-select
        ref="secondaryChannelTypeRef"
        v-model="form.dealerCrmDistributor.secondaryChannelType"
        class="formItem"
        type="dealer_sub_type"
        label="经销商类型二级分类"
        @change="
          () => {
            form.dealerCrmDistributor.thirdChannelType = ''
          }
        "
        @changeSelectedItem="
          item =>
            (form.dealerCrmDistributor.secondaryChannelTypeS = item.content)
        "
      />

      <required-module-form-item-select
        ref="thirdChannelTypeRef"
        v-model="form.dealerCrmDistributor.thirdChannelType"
        class="formItem"
        type="dealer_thirdary_type"
        label="经销商类型三级分类"
        :parent-code="form.dealerCrmDistributor.secondaryChannelType"
        empty-disabled
        dependent-parent-code
        @changeSelectedItem="
          item => (form.dealerCrmDistributor.thirdChannelTypeS = item.content)
        "
      />
      <required-module-form-item-select
        v-model="form.dealerCrmDistributor.shopCreateType"
        class="formItem"
        prop="dealerCrmDistributor.shopCreateType"
        label="开店类型"
        type="open_type"
      />

      <HeaderTitle style="margin-top: 20px" title="开票信息" />

      <required-form-item-input
        v-for="(value, key) in formLayout.dealerBusinessLicense2"
        :key="value"
        class="formItem"
        disabled
        placeholder="自动带出"
        :prop="`dealerBusinessLicense[${key}]`"
        :value="form.dealerBusinessLicense[key]"
        :label="value"
      />
      <required-form-item-input
        v-model="form.dealerBusinessLicense.taxpayerPhone"
        prop="dealerBusinessLicense.taxpayerPhone"
        delay-update
        class="formItem"
        label="纳税人电话"
        :validations="validations['taxpayerPhone'] || []"
        :disabled="isLicenseDisabled"
      />

      <required-bank-form-item-select
        v-model="form.dealerBusinessLicense.bank"
        :prop="`dealerBusinessLicense.bank`"
        class="formItem"
        label="开户行"
        :validations="validations['bank'] || []"
        :disabled="isLicenseDisabled"
        @changeSelectedItem="
          bankItem => {
            form.dealerBusinessLicense.bankCode = bankItem ? bankItem.CODE:''
            form.dealerBusinessLicense.bankCountryCode = bankItem ? bankItem.AREACODE : ''
          }
        "
      />
      <RequiredFormItemInput
        v-model="form.dealerBusinessLicense.bankCode"
        :prop="`dealerBusinessLicense.bankCode`"
        class="formItem"
        label="开户行编码"
        :validations="validations['bankCode'] || []"
        disabled
        delay-update
      />
      <required-form-item-input
        v-for="(value, key) in formLayout.dealerBusinessLicense3"
        :key="value"
        v-model="form.dealerBusinessLicense[key]"
        delay-update
        class="formItem"
        :prop="`dealerBusinessLicense[${key}]`"
        :label="value"
        :validations="validations[key] || []"
        :disabled="isLicenseDisabled"
      />

      <required-module-form-item-select
        v-model="form.dealerBusinessLicense.payTaxesType"
        class="formItem"
        prop="dealerBusinessLicense.payTaxesType"
        label="纳税类型"
        type="pay_taxes_type"
        :disabled="isLicenseDisabled"
      />
      <required-module-form-item-select
        v-model="form.dealerBusinessLicense.billType"
        class="formItem"
        prop="dealerBusinessLicense.billType"
        label="专票/普票"
        module="public"
        type="invoice_type"
        :disabled="isLicenseDisabled"
      />

      <required-form-item-input
        v-for="(value, key) in formLayout.dealerBusinessLicense4"
        :key="key"
        v-model="form.dealerBusinessLicense[key]"
        delay-update
        class="formItem"
        :prop="`dealerBusinessLicense[${key}]`"
        :label="value"
        :validations="validations[key] || []"
        :placeholder="placeholder[key] || null"
        :disabled="isLicenseDisabled"
      />
      <div>
        <required-form-item-upload-file
          v-model="form.taxpayerProveFileList"
          prop="taxpayerProveFileList"
          class="formItem"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview"
          style="width: inherit"
        >
          <div slot="describe">
            （1：一般纳税人登记证 或2：税务登记事项通知书或
            3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </required-form-item-upload-file>

        <required-form-item-upload-file
          v-model="form.bankFileList"
          prop="bankFileList"
          class="formItem"
          label="上传附件"
          limit-size="400"
          :on-preview="onPreview"
          style="width: inherit"
        >
          <div
            slot="describe"
          >（1、一般纳税人登记证/税务登记事项通知书/网站“全国企业一般纳税人资格查询截图/其他途径截图或照片，2、开户证明或网银截图能显示开户银行全称）</div>
          <div slot="tip">单个文件不能超过400M</div>
        </required-form-item-upload-file>
      </div>
      <header-title style="margin-top: 20px" title="货物及快递收货信息" />
      <div style="margin-bottom: 10px">
        <component
          :is="
            value.moduleSelectType
              ? 'required-module-form-item-select'
              : 'required-form-item-input'
          "
          v-for="(value, key) in formLayout.warehouseAndDelivery"
          :key="key"
          v-model="form.warehouseAndDelivery[key]"
          delay-update
          class="formItem"
          :label="value.label"
          :prop="`warehouseAndDelivery[${key}]`"
          :type="value.moduleSelectType"
          :validations="validations[key] || []"
          :placeholder="placeholder[key] || null"
        />

        <template v-if="isOverseas">
          <RequiredModuleFormItemSelect
            v-model="form.dealerBusinessLicense.reconciliationAccount"
            prop="dealerBusinessLicense.reconciliationAccount"
            class="formItem"
            label="统驭科目"
            type="reconciliation_account"
            @changeSelectedItem="(e)=>{
              form.dealerBusinessLicense.reconciliationAccountS =e?e.content:''
            }"
          />
          <!-- <RequiredModuleFormItemSelect
            v-model="form.dealerBusinessLicense.salesDepartmentCode"
            prop="dealerBusinessLicense.salesDepartmentCode"
            class="formItem"
            label="销售部门"
            type="overseas_department"
            @changeSelectedItem="(e)=>{
              form.dealerBusinessLicense.salesDepartmentS =e?e.content:''
            }"
          />
          <RequiredModuleFormItemSelect
            v-model="form.dealerBusinessLicense.tradingItem"
            prop="dealerBusinessLicense.tradingItem"
            class="formItem"
            label="交易条款"
            type="exchange_provision"
            @changeSelectedItem="(e)=>{
              form.dealerBusinessLicense.tradingItemS =e?e.content:''
            }"
          />
          <RequiredModuleFormItemSelect
            v-model="form.dealerBusinessLicense.paymentCondition"
            prop="dealerBusinessLicense.paymentCondition"
            class="formItem"
            label="结算方式"
            type="settlement_type"
            @changeSelectedItem="(e)=>{
              form.dealerBusinessLicense.paymentConditionS =e?e.content:''
            }"
          /> -->
        </template>
      </div>

      <template v-if="['23', '47', '08', '24', '35'].includes(String(form.channelSeriesCode))">
        <HeaderTitle title="品牌推广费及品牌保证金收取" />
        <div style="margin-bottom: 10px">
          <el-table :data="form.dealerMarginRecordList" border>
            <el-table-column align="center" width="60">
              <template slot="header" slot-scope="{}">
                <span
                  class="el-icon-circle-plus iconfont"
                  @click="addDealerMarginRecordList"
                />
              </template>
              <template v-if="scope.$index" slot-scope="scope">
                <div>
                  <span
                    class="el-icon-remove iconfont"
                    @click="
                      removeTabelItem(form.dealerMarginRecordList, scope.$index)
                    "
                  />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" width="200" align="center">
              <template slot-scope="scope">
                <required-module-form-item-select
                  v-model="scope.row.type"
                  class="formItem"
                  :required="false"
                  :prop="`dealerMarginRecordList.${scope.$index}.type`"
                  :show-label="false"
                  label="类型"
                  type="payment_type_code"
                  @change="(value)=>onChangeDealerMarginRecordType(value, scope.$index)"
                />
              </template>
            </el-table-column>
            <el-table-column prop="remittanceAmount" label="金额（万）" width="180" />
            <el-table-column prop="remittanceDate" label="汇款日期" width="240">
              <template slot-scope="scope">
                <required-form-item-date-picker
                  v-model="scope.row.remittanceDate"
                  :required="false"
                  :prop="
                    `dealerMarginRecordList.${scope.$index}.remittanceDate`
                  "
                  label="汇款日期"
                  :show-label="false"
                />
              </template>
            </el-table-column>
            <el-table-column prop="account" label="汇款账户" min-width="200">
              <template slot-scope="scope">
                <required-form-item-input
                  v-model="scope.row.accountName"
                  :required="false"
                  :prop="`dealerMarginRecordList.${scope.$index}.accountName`"
                  :show-label="false"
                  label="开户名"
                />
                <el-divider />
                <required-form-item-input
                  v-model="scope.row.account"
                  :required="false"
                  :prop="`dealerMarginRecordList.${scope.$index}.account`"
                  :show-label="false"
                  label="账号"
                />
                <el-divider />
                <required-bank-form-item-select
                  v-model="scope.row.bank"
                  :required="false"
                  :prop="`dealerMarginRecordList.${scope.$index}.bank`"
                  :show-label="false"
                  label="开户行"
                  :validations="validations['bank'] || []"
                />
              </template>
            </el-table-column>
            <el-table-column prop="address" label="款条" min-width="200">
              <template slot-scope="scope">
                <required-form-item-upload-file
                  v-model="scope.row.fileList"
                  :required="false"
                  class="formItem"
                  limit-size="400"
                  :on-preview="onPreview"
                  :prop="`dealerMarginRecordList.${scope.$index}.fileList`"
                  :auto-upload="true"
                >
                  <div slot="tip">单个文件不能超过400M</div>
                </required-form-item-upload-file>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </template>
    </template>
    <remark-item
      v-model="form.dealerDeclarationForm.remark"
      style="margin-top: 40px;"
      :file-list="form.fileRecordList"
      @onUpload="
        file => {
          form.fileRecordList = file.map(item => item.response)
        }
      "
    />
  </flow-form>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import 'element-ui/lib/theme-chalk/display.css'

import dateFormat from 'dateformat'
import * as channelJoinApi from '@/api/attractPlatform/channelJoinApi'
import { getBusinessDataList } from '@/api/businessOos/index'
import { getCityInfoData } from '@/api/flowSetting/cityInfoManage'

import HeaderTitle from './components/headerTitle'
import GoBack from '@/components/goBack'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import RequiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker'
import RequiredFormItemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import RequiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import RequiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect'
import FlowForm from './components/flowForm'
import RemarkItem from './components/remarkItem'

import moment from 'moment'
import { getBrandLeagueCoefficientInfo, ocrBizlicense } from '@/api/flow/index'
import { requestBossListByContactInfo, requestGetCityLevel2 } from '@/api/common'

const validations = {
  realName: ['name'],
  contactInfo: ['phone'],
  tencentQq: ['qq_email'],
  idCard: ['user_id'],
  legalPersonName: ['name'],
  legalPersonContact: ['phone'],
  // taxpayerPhone: ['phone'],
  bankAccount: ['bank_account'],
  personLiableName: ['name'],
  personLiablePhone: ['phone'],
  eMail: ['email'],
  creditCode: ['credit_code'],
  warehouseUser: ['name'],
  warehousePhone: ['phone'],
  age: ['age']
}

const originalForm = {
  channelCooperationName: '',
  channelSeriesCode: '', // 渠道编码(对应小品牌编码)
  dealerChannelCooperation: {
    businessDept: '', // 业务部门
    cooperationCityCode: '', // 城市编码
    cooperationCityName: '', // 城市名称
    orderSpecialist: '', // 订单专员名称
    orderSpecialistNumber: '' // 订单专员编号
  },
  // 经销商数据
  dealerCrmDistributor: {
    channelType: '', // 经销商分类
    channelTypeS: '', // 经销商分类名称
    secondaryChannelType: '', // 经销商二级分类
    secondaryChannelTypeS: '', // 经销商二级分类名称
    thirdChannelType: '', // 经销商三级分类
    thirdChannelTypeS: '', // 经销商三级分类名称
    shopCreateType: '' // 开店类型
  },
  // 经销商个人基础信息
  dealerDeclarationForm: {
    id: '', // 表单id，如果有则返回，如果没有则代表新建
    title: '', // 标题
    orderNumber: '', // 单号
    optJobNumber: '', // 提单人工号
    optUserId: '', // 提单人id
    optUserName: '', // 提单人工号
    optDeptId: '', // 提单部门id
    optDeptName: '', // 提单部门
    optTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'), // 提单日期
    urgentLevel: '', // 0正常1紧急
    urgentExplain: '',
    statusExplain: '',
    remark: '' // 备注文本
  },
  // 经销商个人基础信息
  dealerBoss: {
    bossNumber: '', // 实控人账号
    realName: '', // 经销商（实际控股人）
    contactInfo: '', // 联系方式
    idCard: '', // 身份证
    tencentQq: '', // QQ电邮
    education: '', // 学历
    age: '', // 年龄
    maritalStatus: '', // 0未婚1已婚2离异
    nativePlace: '',
    sex: '', // 性别'0保密1男2女
    createTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss')
  },
  // 营业执照信息
  dealerBusinessLicense: {
    businessLicenseName: '', // 营业执照名称
    businessLicenseType: '', // 营业执照类型
    creditCode: '', // 社会统一信用代码
    registeredCapital: '', // 注册资本
    legalPersonName: '', // 法人姓名
    legalPersonContact: '', // 法人联系方式
    registerDate: '', // 注册日期
    businessTerm: '', // 营业期限
    businessLicenseAddress: '', // 营业执照地址
    businessScope: '', // 经营范围
    registrationAuthority: '', // 登记机关
    // 开票信息
    taxpayerPhone: '', // 纳税人电话
    bank: '', // 开户行
    bankCode: '', // 开户行编号
    bankAccount: '', // 开户行账号
    payTaxesType: '', // 纳税类型
    billType: '', // 专票/普票
    personLiableName: '', // 税务负责人姓名
    personLiablePhone: '', // 税务负责人电话
    oaAccount: '', // OA账号
    createTime: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'),
    eMail: '', // 接收电子发票邮箱
    licenseNumber: '', // 售达方
    certificatesRecAddress: '', // 函证接收地址

    reconciliationAccountS: '', // 统驭科目 名称
    reconciliationAccount: '', // 统驭科目
    tradingItemS: '', // 交易条款 名称
    tradingItem: '', // 交易条款
    paymentConditionS: '', // 付款条件 名称
    paymentCondition: '', // 付款条件
    businessLicenseShortName: '', //	营业执照简称
    salesDepartmentCode: '', // 销售部门编码
    salesDepartmentS: ''// 销售部门名称
  },
  // 与慕思的合作情况
  dealerCooperationRecordList: [],
  // 品牌推广费及品牌保证金收取
  dealerMarginRecordList: [
    {
      type: '', // 保证金类型
      remittanceAmount: '', // 汇款金额（万）
      remittanceDate: '', // 汇款日期
      // remittanceDate: moment().format("YYYY-MM-DD hh:mm:ss"), //汇款日期
      remittanceUser: '', // 付款人
      accountName: '', // 开户名
      account: '', // 账号
      bank: '', // 开户行
      financeConfirmStatus: '', // 0未到账1已到账
      paymentTime: '', // 入账时间
      fileList: [] // 文件列表
    }
  ],
  // 营业执照照片/电子档
  businessLicenseFileList: [],
  // （1：一般纳税人登记证 或2：税务登记事项通知书或 3：网站“全国企业一般纳税人资格查询截图或4：其他途径截图/照片）
  taxpayerProveFileList: [],
  // 开户证明或者网银截图(能显示开户银行全称)
  bankFileList: [],
  // 货物及快递收货信息
  warehouseAndDelivery: {
    warehouseUser: '', // 仓库收货人
    warehousePhone: '', // 联系方式
    warehouseAddress: '', // 仓库收货地址
    deliveryAddress: '', // 运输目的地
    deliveryType: '', // 运输方式
    transportType: '' // 运输条件
  },
  finalBusinessLicenseName: '', // 最终售达方名称
  finalLicenseNumber: '', // 最终售达方编号 OA账号
  finalBossAccount: '', // 实控人账号
  finalSenderNumber: '', // 最终送达方编号，多个送达方采用 "-" 进行分割

  fileRecordList: [], // 备注文件
  authorizedCityMarketLevelName: '',
  /* 授权城市 城市级别编码 */
  authorizedCityMarketLevelCode: '',
  /* 全国连锁标记，1是全国连锁，0不是全国连锁 */
  countryChainFlag: ''
}

export default {
  name: 'ChannelFlow',
  components: {
    HeaderTitle,
    GoBack,
    RequiredFormItemInput,
    RequiredFormItemSelect,
    RequiredFormItemDatePicker,
    RequiredFormItemUploadFile,
    RequiredModuleFormItemSelect,
    RequiredBankFormItemSelect,
    FlowForm,
    RemarkItem
  },
  props: {},
  data() {
    return {
      pageInfo: {
        type: 'create' // or 'edit' 默认新建表单
      },
      selectedChannelTypeItem: null,
      selectedItemAuthCity: null,
      form: JSON.parse(JSON.stringify(originalForm)),
      formLayout: {
        title: { title: '标题', orderNumber: '单号' },
        optUser: {
          optJobNumber: '提单人工号',
          optUserName: '提单人',
          optDeptName: '提单部门',
          optTime: '提单日期'
        },

        finalList: {
          finalBusinessLicenseName: '最终售达方名称',
          finalLicenseNumber: '最终售达方编号',
          finalSenderNumber: '最终送达方编号'
        },

        dealerBoss1: {
          realName: '渠道商名字',
          contactInfo: '渠道商联系方式'
        },
        dealerBoss2: {
          idCard: '身份证',
          age: '年龄',
          maritalStatus: {
            label: '婚姻状况',
            moduleSelectType: 'marital_status',
            required: false
          },
          nativePlace: '籍贯',
          sex: {
            label: '性别',
            moduleSelectType: 'sex'
          }
        },
        dealerBusinessLicense1: {
          legalPersonName: '法人',
          legalPersonContact: '法人联系方式',
          registerDate: '注册日期',
          businessTerm: '营业期限',
          businessLicenseAddress: '营业执照地址（住所）',
          businessScope: '经营范围',
          registrationAuthority: '登记机关',
          certificatesRecAddress: '函证接收地址'
        },
        dealerBusinessLicense2: {
          businessLicenseName: '开票名称',
          creditCode: '纳税人识别号',
          businessLicenseAddress: '纳税人地址'
          // taxpayerPhone: '纳税人电话'
        },
        dealerBusinessLicense3: {
          // bank: '开户行',
          bankAccount: '银行账户号'
        },
        dealerBusinessLicense4: {
          personLiableName: '税务负责人',
          personLiablePhone: '税务负责人电话',
          eMail: '接受电子发票邮箱'
        },
        warehouseAndDelivery: {
          warehouseUser: { label: '仓库收货人' },
          warehousePhone: { label: '联系方式' },
          warehouseAddress: { label: '仓库收货地址' },
          deliveryAddress: { label: '运输目的地' },
          deliveryType: {
            label: '运输方式',
            moduleSelectType: 'delivery_type'
          },
          transportType: {
            label: '运输条件',
            moduleSelectType: 'transport_type'
          }
        }
      },
      validations,
      placeholder: {
        registrationAuthority: '请填写营业执照右下方登记机关红章名称',
        certificatesRecAddress: '实控人接收慕思财务下发函证资料地址',
        bank: '详细至营业点，**分行/分理处',
        nativePlace: '广东东莞'
      },
      licenseDetail: null,
      brandLeagueCoefficientInfo: null
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA
    }),
    title({ form, selectedChannelTypeItem, selectedItemAuthCity }) {
      const list = []
      if (selectedChannelTypeItem) {
        list.push(`渠道类型：${selectedChannelTypeItem.content}`)
      }

      let str = '渠道合作申请表'
      if (list.length) {
        str += `（${list.join('，')}）`
      }
      form.dealerDeclarationForm.title = str
      return str
    },
    cooperationCityCode_channelSeriesCode({ form }) {
      if (!form.dealerChannelCooperation.cooperationCityCode || !form.channelSeriesCode) {
        return null
      }
      return {
        authorizedCityCode: form.dealerChannelCooperation.cooperationCityCode,
        seriesCodeList: [form.channelSeriesCode]
      }
    },
    // 包含海外
    isOverseas({ form: { channelSeriesCode }}) {
      return Number(channelSeriesCode) === 24
    },
    isLicenseDisabled({
      licenseDetail,
      form: {
        dealerBusinessLicense: { creditCode }
      }
    }) {
      const creditCodeReg = /^(([0-9A-Za-z]{15})|([0-9A-Za-z]{18})|([0-9A-Za-z]{20}))$/
      return !!licenseDetail || !creditCodeReg.test(creditCode)
    },
    marketLevelCode_brandCode({
      form: {
        authorizedCityMarketLevelCode: marketLevelCode
      },
      selectedChannelTypeItem
    }) {
      return {
        marketLevelCode,
        bigBrandId: selectedChannelTypeItem?.explainJson
      }
    }
  },
  watch: {
    marketLevelCode_brandCode: {
      immediate: true,
      async handler(value) {
        console.log(value)
        if (!value.marketLevelCode || !value.bigBrandId) {
          this.brandLeagueCoefficientInfo = null
          return
        }
        const res = await getBrandLeagueCoefficientInfo(value)
        this.brandLeagueCoefficientInfo = res.data
      }
    },
    brandLeagueCoefficientInfo(value) {
      this.form.dealerMarginRecordList.forEach(item => {
        if (!value) {
          item.remittanceAmount = ''
          return
        }
        switch (Number(item.type)) {
          case 1:
            item.remittanceAmount = value.promotionExpenses
            break
          case 2:
            item.remittanceAmount = value.brandDeposit
            break
          case 3:
            item.remittanceAmount = value.earnestDeposit
            break
          case 4:
            item.remittanceAmount = value.envyDeposit
            break
          case 5:
            item.remittanceAmount = value.decorationDeposit
            break
          default:
            item.remittanceAmount = ''
        }
      })
    },
    'form.channelSeriesCode': {
      immediate: true,
      handler(code) {
        this.$nextTick(() => {
          this.$refs.flowFormRef.clearValidate()
        })

        if (code === '46') {
          this.$set(this.formLayout.dealerBoss2, 'idCard', {
            label: '身份证',
            required: false,
            validate: false
          })
        } else {
          this.$set(this.formLayout.dealerBoss2, 'idCard', '身份证')
        }
      }
    },
    // 'form.dealerBusinessLicense.legalPersonContact': {
    //   immediate: true,
    //   handler(value) {
    //     this.form.dealerBusinessLicense.taxpayerPhone = value
    //   }
    // },
    'form.dealerBusinessLicense.creditCode': {
      immediate: true,
      handler(creditCode) {
        if (this.isOA) return

        if (!creditCode) return (this.licenseDetail = null)
        getBusinessDataList({
          creditCode
        })
          .then(res => {
            if (res.data.length) {
              const licenseDetail = res.data[0]
              this.licenseDetail = licenseDetail
            } else {
              this.licenseDetail = null
            }
          })
          .catch(() => {
            this.licenseDetail = null
          })
      }
    },
    'form.dealerBusinessLicense.businessLicenseName': {
      immediate: true,
      handler(value) {
        this.form.dealerBusinessLicense.bankAccountName = value
      }
    },
    licenseDetail(licenseDetail) {
      if (licenseDetail) {
        for (const key in this.form.dealerBusinessLicense) {
          this.form.dealerBusinessLicense[key] = licenseDetail[key]
        }
        for (const key in this.form.dealerBoss) {
          if (licenseDetail[key] !== undefined) {
            this.form.dealerBoss[key] = licenseDetail[key]
          }
        }
      } else {
        this.form.dealerBusinessLicense = {
          ...originalForm.dealerBusinessLicense,
          creditCode: this.form.dealerBusinessLicense.creditCode
        }
        this.form.dealerBoss = {
          ...originalForm.dealerBoss
        }
      }
    },
    'form.businessLicenseFileList'(arr) {
      // OCR识别营业执照
      if (arr.length && arr[0]['url']) {
        const params = { imgUrl: arr[0]['url'], appId: 'wx27748066d2817941' }
        ocrBizlicense(params).then((res = {}) => {
          if (Number(res.data.errcode) === 0) {
            const data = res.data
            this.form.dealerBusinessLicense.creditCode = data.reg_num // 统一社会信用代码
            this.form.dealerBusinessLicense.businessLicenseName =
              data.enterprise_name // 营业执照名称
            this.form.dealerBusinessLicense.businessLicenseType = data.type_of_enterprise.includes(
              '个体工商户'
            )
              ? '0'
              : '1' // 营业执类型
            // this.form.dealerBusinessLicense.registeredCapital =data.registered_capital//注册资本
            this.form.dealerBusinessLicense.legalPersonName =
              data.legal_representative // 法人姓名

            const registerDate = moment(
              data.registered_date
                .replace(/日|\s/g, '')
                .replace(/年|月/g, '/')
            ).format('YYYY-MM-DD HH:mm:ss')
            this.form.dealerBusinessLicense.registerDate =
              registerDate.toLocaleLowerCase() === 'invalid date'
                ? ''
                : registerDate // 注册日期

            this.form.dealerBusinessLicense.businessTerm = data.valid_period // 营业期限
            this.form.dealerBusinessLicense.businessLicenseAddress =
              data.address // 营业执照地址
            this.form.dealerBusinessLicense.businessScope = data.business_scope // 经营范围
          } else {
            if (Number(res.data.errcode) === 101003) return
            this.$message.warning(
              '您上传的营业执照未能识别成功，请检查营业执照图片是否清晰或手工填写信息。'
            )
          }
        })
      }
    },
    cooperationCityCode_channelSeriesCode(params) {
      if (params) {
        requestGetCityLevel2(params).then(res => {
          this.form.authorizedCityMarketLevelCode = res.data?.[0]?.marketLevelCode ?? ''
          this.form.authorizedCityMarketLevelName = res.data?.[0]?.marketLevelName ?? ''
        })
      } else {
        this.form.authorizedCityMarketLevelCode = ''
        this.form.authorizedCityMarketLevelName = ''
      }
    },
    'form.dealerBoss.contactInfo': function(contactInfo) {
      if (contactInfo) {
        requestBossListByContactInfo({
          contactInfo: contactInfo,
          ifEnabled: 0
        }).then(({ data }) => {
          const dealerBoss = data[0]
          if (dealerBoss?.realName) {
            this.form.dealerBoss.realName = dealerBoss.realName
            this.form.dealerBoss.idCard = dealerBoss.idCard
          }
        })
      }
    }
  },
  methods: {
    // 添加品牌推广费及品牌保证金收取
    addDealerMarginRecordList() {
      this.form.dealerMarginRecordList.push({
        type: '', // 保证金类型
        remittanceAmount: '', // 汇款金额（万）
        remittanceDate: dateFormat(Date.now(), 'yyyy-mm-dd HH:MM:ss'), // 汇款日期
        remittanceUser: '', // 付款人
        accountName: '', // 开户名
        account: '', // 账号
        bank: '', // 开户行
        financeConfirmStatus: '', // 0未到账1已到账
        paymentTime: '', // 入账时间
        fileList: [] // 文件列表
      })
    },
    // 删除慕思品牌
    removeTabelItem(data, index) {
      data.splice(index, 1)
    },
    async onSubmit(type) {
      this.form.dealerBoss.createTime = dateFormat(
        Date.now(),
        'yyyy-mm-dd HH:MM:ss'
      )
      this.form.dealerBusinessLicense.createTime = dateFormat(
        Date.now(),
        'yyyy-mm-dd HH:MM:ss'
      )
      this.form.dealerDeclarationForm.optTime = dateFormat(
        Date.now(),
        'yyyy-mm-dd HH:MM:ss'
      )
      if (!this.form.dealerBoss.idCard) {
        this.form.dealerBoss.idCard = `XX${Date.now()}`
      }

      const { data } = await channelJoinApi.saveDraft(this.form)
      if (type !== 'submit') {
        return formId
      }
      const formId = data.id
      const formData = new FormData()
      formData.append('formId', formId)
      return channelJoinApi.submit(formData)
    },
    onPreview(file) {
      window.open(file.url)
    },

    async getDetail(type, cachedFormData) {
      let initFormData = cachedFormData
      // 编辑状态
      if (type === 'detail') {
        const { data } = await channelJoinApi.detail({
          formId: this.$route.query.id
        })
        initFormData = data
      }
      if (initFormData) {
        if (/XX\d{13}/.test(initFormData.dealerBoss.idCard)) {
          initFormData.dealerBoss.idCard = ''
        }

        for (const key in this.form) {
          if (initFormData[key] !== undefined) {
            this.form[key] = initFormData[key]
          }
        }
      }
      if (this.userInfo && !this.isOA) {
        const {
          optUserId,
          optJobNumber,
          optUserName,
          optDeptId,
          optDeptName,
          ...rest
        } = this.form.dealerDeclarationForm || {}
        this.form.dealerDeclarationForm = {
          ...rest,
          optUserId: this.userInfo.accountId,
          optJobNumber: this.userInfo.code,
          optUserName: this.userInfo.realName,
          optDeptId: this.userInfo.deptId,
          optDeptName: this.userInfo.deptName
        }
      }
    },
    getAuthList({ page, searchText, authorizedCityCode }) {
      return getCityInfoData({
        currPage: page || 1,
        authorizedCityCode,
        addr: searchText,
        overseasFlag: 'all',
        ifEnabled: 0
      })
    },
    onChangeChannelTypeSelectedItem(item) {
      this.selectedChannelTypeItem = item
      this.form.channelSeriesCode = item.code
    },
    onChangeSelectedItem(item) {
      this.selectedItemAuthCity = item
      if (item) {
        this.form.dealerChannelCooperation.cooperationCityName = `${item.city ||
          ''}${item.area || ''}`
      } else {
        this.form.dealerChannelCooperation.cooperationCityName = ''
      }
    },
    // 当前列表没有默认选项,触发
    async onOptionsListNotFound({ value: authorizedCityCode }, callback) {
      callback(await this.getAuthList({ authorizedCityCode }))
    },
    getBusinessdivisionList() {
      return channelJoinApi.businessdivisionList().then(res => res.data)
    },
    onChangeDealerMarginRecordType(type, index) {
      if (!this.brandLeagueCoefficientInfo) {
        return this.$message.warning(
          '请选择授权城市确认、授权品牌确认后选择此类型带出金额'
        )
      }
      switch (Number(type)) {
        case 1:
          this.form.dealerMarginRecordList[index].remittanceAmount = this.brandLeagueCoefficientInfo.promotionExpenses
          break
        case 2:
          this.form.dealerMarginRecordList[index].remittanceAmount = this.brandLeagueCoefficientInfo.brandDeposit
          break
        case 3:
          this.form.dealerMarginRecordList[index].remittanceAmount = this.brandLeagueCoefficientInfo.earnestDeposit
          break
        case 4:
          this.form.dealerMarginRecordList[index].remittanceAmount = this.brandLeagueCoefficientInfo.envyDeposit
          break
        case 5:
          this.form.dealerMarginRecordList[index].remittanceAmount = this.brandLeagueCoefficientInfo.decorationDeposit
          break
        default:
          this.form.dealerMarginRecordList[index].remittanceAmount = ''
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
