<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      class="flow-form"
      :inline="true"
      label-position="top">
      <header-title title="现经销商营业执照信息" />
      <RequiredFormItemInput
        class="formItem"
        prop="creditCode"
        label="统一社会信用代码"
        v-model="form.creditCode"
        :rules="rules.creditCode"
        :disabled="isOA"
        @change="changeCreditCode"
        maxlength="100" />
      <RequiredFormItemInput
        class="formItem"
        prop="businessLicenseName"
        label="营业执照名称"
        v-model="form.businessLicenseName"
        :disabled="isOA || disabled"
        maxlength="100"
        @change="
          val => {
            $emit('changeBusinessLicense', { businessLicenseName: val })
          }
        " />
      <RequiredModuleFormItemSelect
        class="formItem"
        prop="businessLicenseType"
        type="business_license_type"
        label="营业执照类型"
        v-model="form.businessLicenseType"
        :disabled="isOA || disabled"
        @change="onChangeBusinessLicenseType" />
      <RequiredFormItemInput
        class="formItem"
        prop="registeredCapital"
        label="注册资本（万元）"
        v-model="form.registeredCapital"
        :rules="rules.registeredCapital"
        :disabled="isOA || disabled" />
      <RequiredFormItemInput
        class="formItem"
        prop="legalPersonName"
        label="法人"
        v-model="form.legalPersonName"
        :disabled="isOA || disabled"
        maxlength="20" />
      <RequiredFormItemInput
        class="formItem"
        prop="legalPersonContact"
        label="法人联系方式"
        v-model="form.legalPersonContact"
        :rules="rules.legalPersonContact"
        :disabled="isOA || disabled"
        maxlength="20" />
      <RequiredFormItemDatePicker
        class="formItem"
        prop="registerDate"
        label="注册日期"
        v-model="form.registerDate"
        :datePickerProps="{ type: 'datetime' }"
        :disabled="isOA || disabled" />
      <RequiredFormItemInput
        class="formItem"
        prop="businessTerm"
        label="营业期限"
        v-model="form.businessTerm"
        :disabled="isOA || disabled"
        maxlength="10" />
      <RequiredFormItemInput
        class="formItem"
        prop="businessLicenseAddress"
        label="营业执照地址（住所）"
        v-model="form.businessLicenseAddress"
        :disabled="isOA || disabled"
        maxlength="100"
        @change="
          val => {
            $emit('changeBusinessLicense', { businessLicenseAddress: val })
          }
        " />
      <RequiredFormItemInput
        class="formItem"
        prop="businessScope"
        label="经营范围"
        v-model="form.businessScope"
        :disabled="isOA || disabled"
        maxlength="100" />
      <RequiredFormItemInput
        class="formItem"
        prop="registrationAuthority"
        label="登记机关"
        v-model="form.registrationAuthority"
        :disabled="isOA || disabled"
        maxlength="100" />
      <RequiredFormItemInput
        class="formItem"
        prop="certificatesRecAddress"
        label="函证接收地址"
        :placeholder="isOA ? '' : '实控人接收慕思财务下发函证资料地址'"
        v-model="form.certificatesRecAddress"
        :disabled="isOA || disabled"
        maxlength="500" />
      <div>
        <RequiredFormItemUploadFile
          prop="businessLicenseFile"
          class="formItem"
          label="上传附件"
          :limit="1"
          limit-size="400"
          v-model="form.businessLicenseFile"
          @onUploaded="
            () => {
              $refs['form'].validateField('businessLicenseFile')
            }
          ">
          <div slot="describe">
            <p style="color: red">先上传营业执照可智能识别出营业执照信息。</p>
            （营业执照照片/电子档）
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
        <RequiredFormItemUploadFile
          :required="false"
          class="formItem"
          label="上传附件"
          :limit="1"
          limit-size="400"
          v-model="commitmentFile">
          <div slot="describe">
            （实控人承诺函）
            <p style="margin-bottom: 10px" v-if="!isOA">
              <el-button @click="download">下载模板</el-button>
            </p>
          </div>
          <div slot="tip">单个文件不能超过400M</div>
        </RequiredFormItemUploadFile>
      </div>
    </el-form>
  </div>
</template>
<script>
import {
  RequiredFormItemDatePicker,
  RequiredFormItemInput,
  RequiredModuleFormItemSelect,
  RequiredFormItemUploadFile,
  RequestModuleSelect
} from '@/components/CustomFormItem'
import { mapActions, mapMutations, mapState } from 'vuex'
import {
  validCreditCode,
  validPhone,
  validMoney,
  filesRules
} from '@/utils/useFormValidator'
import HeaderTitle from './headerTitle.vue'
import { ocrBizlicense } from '@/api/flow/index'
import moment from 'moment'
import { getBusiness } from '@/api/user'

export default {
  props: {
    formStatus: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    RequiredFormItemDatePicker,
    RequiredModuleFormItemSelect,
    RequiredFormItemUploadFile,
    RequestModuleSelect,
    RequiredFormItemInput,
    HeaderTitle
  },
  data() {
    return {
      form: {
        businessLicenseName: '', //营业执照名称
        businessLicenseType: '', //营业执照类型
        creditCode: '', //社会统一信用代码
        registeredCapital: '', //注册资本（万元）
        legalPersonName: '', //法人
        legalPersonContact: '', //法人联系方式
        registerDate: '', //注册日期
        businessTerm: '', //营业期限
        businessLicenseAddress: '', //营业执照地址（住所）
        businessScope: '', //经营范围
        registrationAuthority: '', //登记机关
        certificatesRecAddress: '', // 函证接收地址
        businessLicenseFile: [] //营业执照附件
      },
      commitmentFile: [],
      disabled: true,
      rules: {
        creditCode: [
          { validator: validCreditCode, trigger: ['blur', 'change'] }
        ], //社会统一信用代码
        registeredCapital: [
          { validator: validMoney, trigger: ['blur', 'change'] }
        ], //注册资本（万元）
        legalPersonContact: [
          { validator: validPhone, trigger: ['blur', 'change'] }
        ] //法人联系方式
      }
    }
  },
  watch: {
    historicalData: {
      handler(historicalData) {
        const dealerBusinessLicense =
          historicalData.bossJoinModel.dealerBusinessLicense
        for (const key in this.form) {
          if (key !== 'businessLicenseFile') {
            if (dealerBusinessLicense[key]) {
              this.form[key] = dealerBusinessLicense[key]
            }
          }
          if (historicalData.bossJoinModel.businessLicenseFile) {
            this.form['businessLicenseFile'] = [
              historicalData.bossJoinModel.businessLicenseFile
            ]
          }
        }
        if (historicalData.commitmentFile) {
          this.commitmentFile = [historicalData.commitmentFile]
        }
        this.$emit('getBossInfo')
        if (this.formStatus !== '' || ![0, 20].includes(this.formStatus)) {
          return
        }
        if (this.form.creditCode) {
          this.getBusinessLicense({ creditCode: this.form.creditCode }).then(
            () => {
              this.disabled = !!this.businessLicense
              this.$emit('changeDisabled', this.disabled)
            }
          )
        }
        this.$emit('changeBusinessLicense', {
          businessLicenseName: this.form['businessLicenseName'],
          creditCode: this.form['creditCode'],
          businessLicenseAddress: this.form['businessLicenseAddress']
        })
      },
      deep: true
    },
    businessLicense: {
      handler(businessLicense) {
        console.log('businessLicense', businessLicense)
        if (!businessLicense) {
          this.disabled = false
          for (const key in this.form) {
            // console.log('执行',key)
            if (key !== 'creditCode' && key !== 'businessLicenseFile') {
              this.form[key] = ''
            }
          }
        } else {
          this.disabled = true
          for (const key in this.form) {
            // console.log('执行2',key,businessLicense[key])
            if (key !== 'businessLicenseFile') {
              this.form[key] = businessLicense[key]
            }
          }
        }
        this.$emit('changeBusinessLicense', {
          businessLicenseName: this.form['businessLicenseName'],
          creditCode: this.form['creditCode'],
          businessLicenseAddress: this.form['businessLicenseAddress']
        })
      },
      deep: true
    },
    'form.businessLicenseFile'(arr) {
      //OCR识别营业执照
      if (arr.length && arr[0]['url']) {
        const params = { imgUrl: arr[0]['url'], appId: 'wx27748066d2817941' }
        const loading = this.$loading({ background: 'rgba(0, 0, 0, 0.3)' })
        ocrBizlicense(params).then((res = {}) => {
          loading.close()
          if (res.data.errcode == 0) {
            const data = res.data
            getBusiness({ creditCode: data.reg_num }).then(res => {
              if (res.data.length) {
                this.setBusinessLicense(res.data[0])
                this.disabled = true
              } else {
                this.setBusinessLicense(null)
                this.disabled = false
              }
              this.$emit('changeDisabled', this.disabled)
              this.$emit('changeBusinessLicense', {
                businessLicenseAddress: data.address,
                businessLicenseName: data.address,
                creditCode: data.reg_num
              })
              this.$nextTick(() => {
                this.form.creditCode = data.reg_num //统一社会信用代码
                this.form.businessLicenseName = data.enterprise_name //营业执照名称
                this.form.businessLicenseType =
                  data.type_of_enterprise.includes('个体工商户') ? '0' : '1' //营业执类型
                // this.form.registeredCapital =data.registered_capital//注册资本
                this.form.legalPersonName = data.legal_representative //法人姓名

                const registerDate = moment(
                  data.registered_date
                    .replace(/日|\s/g, '')
                    .replace(/年|月/g, '-')
                ).format('YYYY-MM-DD HH:mm:ss')
                this.form.registerDate =
                  registerDate.toLocaleLowerCase() == 'invalid date'
                    ? ''
                    : registerDate // 注册日期

                this.form.businessTerm = data.valid_period //营业期限
                this.form.businessLicenseAddress = data.address //营业执照地址
                this.form.businessScope = data.business_scope //经营范围
              })
            })
          } else {
            if (res.data.errcode == 101003) return
            this.$message.warning(
              '您上传的营业执照未能识别成功，请检查营业执照图片是否清晰或手工填写信息。'
            )
          }
        })
      }
    }
  },
  computed: {
    ...mapState('dealershipTransfer', [
      'isOA',
      'businessLicense',
      'historicalData'
    ])
  },
  methods: {
    ...mapMutations('dealershipTransfer', ['setBusinessLicense']),
    ...mapActions('dealershipTransfer', ['getBusinessLicense']),
    onChangeBusinessLicenseType(e) {
      this.$emit('onChangeBusinessLicenseType', e)
    },
    changeCreditCode(val) {
      if (val) {
        this.getBusinessLicense({ creditCode: val }).then(() => {
          if (!this.businessLicense) {
            this.disabled = false
          } else {
            this.disabled = true
          }
          this.$emit('changeDisabled', this.disabled)
          this.$emit('changeBusinessLicense', { creditCode: val })
        })
      }
    },
    onPreview(file) {
      window.open(file.url)
    },
    download() {
      let a = document.createElement('a')
      a.href =
        'https://derucci-app-obs.iderucci.com/upload/20230316/9383d56cc758ef48b96c72b7ab1603df.docx'
      a.download = '9383d56cc758ef48b96c72b7ab1603df.docx'
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      a.remove()
    }
  }
}
</script>
