<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      class="flow-form"
      label-position="top"
      :inline="true">
      <div v-if="isMobile">
        <el-form-item
          class="formItem"
          v-if="form.list.length === 0"
          style="margin-top: 22px">
          <template slot="label">
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              @click="add"></el-button>
          </template>
        </el-form-item>
        <div
          v-for="(item, index) in form.list"
          :key="index"
          style="margin-top: 22px">
          <el-form-item class="formItem" v-if="form.list.length !== 0">
            <template slot="label">
              <el-button
                type="primary"
                icon="el-icon-plus"
                circle
                @click="add"></el-button>
            </template>
            <template slot-scope="scope">
              <div class="formItem_content">
                <el-button
                  type="primary"
                  icon="el-icon-minus"
                  circle
                  @click="del($event, index, isOA)"></el-button>
              </div>
            </template>
          </el-form-item>
          <el-form-item class="formItem" label="序号">
            <el-input :value="index + 1"></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="门店编号"
            :prop="`list.${index}.id`"
            :rules="[
              {
                required: !item.id,
                message: '必填项不能为空',
                trigger: ['blur', 'change']
              }
            ]">
            <el-input v-if="isOA" :disabled="true" v-model="item.shopNumber" />
            <el-select
              v-else
              placeholder="请选择门店编码"
              v-model="item.id"
              @change="changeShop($event, index)"
              :disabled="isOA || !!item.id">
              <el-option
                v-for="sitem in shopList"
                :key="sitem.id"
                :label="sitem.shopNumber"
                :value="sitem.id" />
            </el-select>
          </el-form-item>
          <el-form-item class="formItem" label="门店名称">
            <el-input :value="item.shopName"></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="门店类型">
            <el-input :value="item.shopTypeContent"></el-input>
          </el-form-item>
          <el-form-item
            v-if="isBigBrandWithClassic"
            class="formItem"
            label="卖场名称">
            <el-input :value="item.marketName"></el-input>
          </el-form-item>
          <el-form-item
            v-if="isBigBrandWithClassic"
            class="formItem"
            label="卖场类型">
            <el-input :value="item.marketType"></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="门店位置等级">
            <el-input :value="item.shopPositionLevel"></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="门店授权品牌">
            <el-input :value="item.brandName"></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="门店地址">
            <el-input :value="shopAddress"></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="开业日期">
            <el-input :value="item.openingTime"></el-input>
          </el-form-item>
          <el-form-item class="formItem" label="门店面积">
            <el-input :value="item.shopArea"></el-input>
          </el-form-item>
          <!-- <el-form-item
            v-if="isBigBrandWithClassic"
            class="formItem"
            label="近三年提货业绩（元）">
            <div v-for="(item, index) in remarkMap" :key="index">
              <div>{{ item.year_desc }}</div>
              <el-input
                :value="item.ship_inv_rb_add_amt_cur"
                readonly></el-input>
            </div>
          </el-form-item> -->
          <!-- <el-form-item
            v-if="isBigBrandWithClassic"
            class="formItem"
            label="同级别市场排名">
            <div>
              <div v-for="(item, index) in remarkMap" :key="index">
                <div>{{ item.year_desc }}年</div>
                <el-input :value="item.row_index" readonly></el-input>
              </div>
            </div>
          </el-form-item> -->
          <el-form-item
            v-if="isBigBrandWithClassic"
            class="formItem"
            label="当前物理店数">
            <el-input v-model="classicShopCount" readonly></el-input>
          </el-form-item>
          <el-form-item
            v-if="isBigBrandWithClassic"
            class="formItem"
            label="当前物理店面积">
            <el-input v-model="classicShopArea" readonly></el-input>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="是否私自撤店"
            :prop="`list.${index}.revokeShopsType`"
            :rules="requiredRules"
            v-if="item.id">
            <el-select
              placeholder="请选择"
              v-model="item.revokeShopsType"
              :disabled="isOA">
              <el-option
                v-for="(sitem, sindex) in type"
                :key="sindex"
                :label="sitem.label"
                :value="sitem.value" />
            </el-select>
          </el-form-item>
          <el-form-item
            class="formItem"
            label="预计撤店日期"
            :prop="`list.${index}.actualRevokeTime`"
            :rules="
              isBigBrandWithClassic
                ? [
                    ...requiredRules,
                    {
                      validator: validActualRevokeTime,
                      trigger: ['blur', 'change']
                    }
                  ]
                : requiredRules
            "
            v-if="item.id">
            <el-date-picker
              :disabled="isOA"
              v-model="item.actualRevokeTime"
              value-format="yyyy-MM-dd hh:mm:ss"
              type="datetime"
              placeholder="请选择">
            </el-date-picker>
          </el-form-item>
          <!-- <el-form-item class="formItem" label="撤店原因" :prop="`list.${index}.revokeReason`" :rules="requiredRules" v-if="item.id">
            <el-select placeholder="请选择" v-model="item.revokeReason" :disabled="isOA" @change="changeRevokeReason($event,index)">
              <el-option v-for="(sitem,sindex) in removeShopReason" :key="sindex" :label="sitem" :value="sitem"/>
            </el-select>
          </el-form-item> -->
          <el-form-item class="formItem" label="备注">
            <div>
              <div>
                若涉及门店在同一商场内的位置调整/换位重装，请在备注栏及审批意见中说明以下信息：
              </div>
              <div>1、卖场描述：包含卖场运营、客流等情况；</div>
              <div>
                2、经销商开店计划描述：如调整到几楼的什么位置，多少平，若有照片可提供新门店位置照片。
              </div>
              <el-input
                v-if="item.id"
                style="width: 100%"
                type="textarea"
                placeholder="请填写"
                :maxlength="500"
                :showWordLimit="true"
                v-model="item.remark">
              </el-input>
            </div>
          </el-form-item>
          <el-form-item class="formItem" label="附件">
            <div>
              <required-formitem-upload-file
                :prop="`list.${index}.fileList`"
                :required="isBigBrandWithClassic"
                class="formItem"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="item.fileList"
                v-if="item.id">
                <div slot="describe">（1、门店照片）</div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
              <required-formitem-upload-file
                :prop="`list.${index}.locationFileList`"
                :required="isBigBrandWithClassic"
                class="formItem"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="item.locationFileList"
                v-if="item.id">
                <div slot="describe">（2、门店地址定位截图）</div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
              <required-formitem-upload-file
                :prop="`list.${index}.proveFileList`"
                :required="isBigBrandWithClassic"
                class="formItem"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="item.proveFileList"
                v-if="item.id">
                <div slot="describe">
                  （3、手持“当天日期报纸”或“现在北京时间”在该店面拍照）
                </div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
              <required-formitem-upload-file
                :prop="`list.${index}.newShopLocationFileList`"
                :required="revokeReason === '换位重装'"
                class="formItem"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="item.newShopLocationFileList"
                v-if="item.id">
                <div slot="describe">（4、新门店位置照片）</div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
            </div>
          </el-form-item>
        </div>
      </div>
      <el-table
        v-else
        :data="form.list"
        style="width: 100%; margin-bottom: 22px"
        border>
        <el-table-column width="200" align="center">
          <template slot="header">
            <el-button
              type="primary"
              icon="el-icon-plus"
              circle
              @click="add"></el-button>
          </template>
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-minus"
              circle
              @click="del($event, scope.$index, isOA)"></el-button>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="50"></el-table-column>
        <el-table-column prop="shopNumber" label="门店编号" min-width="150">
          <template slot-scope="scope">
            <el-form-item
              :prop="`list.${scope.$index}.id`"
              :rules="[
                {
                  required: !scope.row.id,
                  message: '必填项不能为空',
                  trigger: ['blur', 'change']
                }
              ]">
              <el-input
                v-if="isOA"
                :disabled="true"
                v-model="scope.row.shopNumber" />
              <el-select
                v-else
                placeholder="请选择门店编码"
                v-model="scope.row.id"
                @change="changeShop($event, scope.$index)"
                :disabled="isOA || !!scope.row.id">
                <el-option
                  v-for="item in shopList"
                  :key="item.id"
                  :label="item.shopNumber"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column prop="shopName" label="门店名称" min-width="200">
        </el-table-column>
        <el-table-column
          prop="shopTypeContent"
          label="门店类型"
          min-width="200">
        </el-table-column>
        <el-table-column
          v-if="isBigBrandWithClassic"
          prop="marketName"
          label="卖场名称"
          min-width="200">
          <template #default="{ row }">
            <div>{{ row.marketName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isBigBrandWithClassic"
          prop="marketType"
          label="卖场类型"
          min-width="200">
          <template #default="{ row }">
            <div>{{ row.marketType }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="shopPositionLevel"
          label="门店位置等级"
          min-width="200">
          <template #default="{ row }">
            <div>{{ row.shopPositionLevel }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="brandName" label="门店授权品牌" min-width="200">
        </el-table-column>
        <el-table-column
          v-if="shopAddress"
          prop="province"
          label="门店地址"
          min-width="200">
          <template slot-scope="scope">
            <div>
              <div>{{ shopAddress }}</div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="openingTime" label="开业日期" min-width="200">
        </el-table-column>
        <el-table-column prop="shopArea" label="门店面积" min-width="200">
        </el-table-column>
        <!-- <el-table-column
          v-if="isBigBrandWithClassic"
          label="近三年提货业绩（元)"
          min-width="200">
          <template slot-scope="scope">
            <div>
              <div v-for="(item, index) in remarkMap" :key="index">
                <div>{{ item.year_desc }}年</div>
                <el-input
                  :value="item.ship_inv_rb_add_amt_cur"
                  readonly></el-input>
              </div>
            </div>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          v-if="isBigBrandWithClassic"
          label="同级别市场排名"
          min-width="200">
          <template slot-scope="scope">
            <div>
              <div v-for="(item, index) in remarkMap" :key="index">
                <div>{{ item.year_desc }}年</div>
                <el-input :value="item.row_index" readonly></el-input>
              </div>
            </div>
          </template>
        </el-table-column> -->
        <el-table-column
          v-if="isBigBrandWithClassic"
          label="当前物理店数"
          min-width="200">
          <template slot-scope="scope">
            <div>
              {{ classicShopCount }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          v-if="isBigBrandWithClassic"
          label="当前物理店面积"
          min-width="200">
          {{ classicShopArea }}
        </el-table-column>
        <el-table-column
          prop="revokeShopsType"
          label="是否私自撤店"
          min-width="100">
          <template slot-scope="scope">
            <el-form-item
              :prop="`list.${scope.$index}.revokeShopsType`"
              :rules="requiredRules"
              v-if="scope.row.id">
              <el-select
                placeholder="请选择"
                v-model="scope.row.revokeShopsType"
                :disabled="isOA">
                <el-option
                  v-for="(item, index) in type"
                  :key="index"
                  :label="item.label"
                  :value="item.value" />
              </el-select>
            </el-form-item>
          </template>
        </el-table-column>
        <el-table-column
          prop="actualRevokeTime"
          label="预计撤店日期"
          min-width="240">
          <template slot-scope="scope">
            <el-form-item
              :prop="`list.${scope.$index}.actualRevokeTime`"
              :rules="
                isBigBrandWithClassic
                  ? [
                      ...requiredRules,
                      {
                        validator: validActualRevokeTime,
                        trigger: ['blur', 'change']
                      }
                    ]
                  : requiredRules
              "
              v-if="scope.row.id">
              <el-date-picker
                :disabled="isOA"
                v-model="scope.row.actualRevokeTime"
                value-format="yyyy-MM-dd hh:mm:ss"
                type="datetime"
                placeholder="请选择">
              </el-date-picker>
            </el-form-item>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="revokeReason" label="撤店原因" min-width="200">
          <template slot-scope="scope">
            <el-form-item  :prop="`list.${scope.$index}.revokeReason`" :rules="requiredRules" v-if="scope.row.id">
              <el-select placeholder="请选择" v-model="scope.row.revokeReason" :disabled="isOA" @change="changeRevokeReason($event,scope.$index)">
                <el-option v-for="(item,index) in removeShopReason" :key="index" :label="item" :value="item"/>
              </el-select>
            </el-form-item>
          </template>
        </el-table-column> -->
        <el-table-column prop="remark" label="备注" min-width="200">
          <template slot-scope="scope">
            <div>
              <div>
                若涉及门店在同一商场内的位置调整/换位重装，请在备注栏及审批意见中说明以下信息：
              </div>
              <div>1、卖场描述：包含卖场运营、客流等情况；</div>
              <div>
                2、经销商开店计划描述：如调整到几楼的什么位置，多少平，若有照片可提供新门店位置照片。
              </div>
              <el-form-item
                :prop="`list.${scope.$index}.remark`"
                :rules="isBigBrandWithClassic ? requiredRules : null"
                v-if="scope.row.id">
                <el-input
                  style="width: 100%"
                  type="textarea"
                  placeholder="请填写"
                  maxlength="500"
                  v-model="scope.row.remark">
                </el-input>
              </el-form-item>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="附件" min-width="400">
          <template slot-scope="scope">
            <div>
              <required-formitem-upload-file
                :prop="`list.${scope.$index}.fileList`"
                :required="isBigBrandWithClassic"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="scope.row.fileList"
                v-if="scope.row.id">
                <div slot="describe">（1、门店照片）</div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
              <required-formitem-upload-file
                :prop="`list.${scope.$index}.locationFileList`"
                :required="isBigBrandWithClassic"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="scope.row.locationFileList"
                v-if="scope.row.id">
                <div slot="describe">（2、门店地址定位截图）</div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
              <required-formitem-upload-file
                :prop="`list.${scope.$index}.proveFileList`"
                :required="isBigBrandWithClassic"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="scope.row.proveFileList"
                v-if="scope.row.id">
                <div slot="describe">
                  （3、手持“当天日期报纸”或“现在北京时间”在该店面拍照）
                </div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
              <required-formitem-upload-file
                :prop="`list.${scope.$index}.newShopLocationFileList`"
                :required="revokeReason === '换位重装'"
                label="上传附件"
                :show-label="false"
                limit-size="400"
                :on-preview="onPreview"
                v-model="scope.row.newShopLocationFileList"
                v-if="scope.row.id">
                <div slot="describe">（4、新门店位置照片）</div>
                <div slot="tip">单个文件不能超过400M</div>
              </required-formitem-upload-file>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <HeaderTitle title="门店撤店系列明细" />
    <el-form
      :model="seriesForm"
      class="flow-form"
      label-position="top"
      :inline="true"
      v-if="isMobile">
      <div
        v-for="(item, index) in seriesForm.seriesList"
        :key="index"
        style="margin-top: 22px">
        <el-form-item class="formItem" label="序号">
          <el-input :value="index + 1"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="系列名称">
          <el-input :value="item.name"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="营销中心">
          <el-input :value="item.marketCenter"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="系列开业时间">
          <el-input :value="item.authorizedTime"></el-input>
        </el-form-item>
        <el-form-item class="formItem" label="系列取消原因">
          <el-input :value="item.revokeReason"></el-input>
        </el-form-item>
      </div>
    </el-form>
    <el-table v-else :data="seriesForm.seriesList" border>
      <el-table-column width="50" align="center" label="序号" type="index" />
      <el-table-column prop="name" label="系列名称" min-width="200" />
      <el-table-column prop="marketCenter" label="营销中心" min-width="200" />
      <el-table-column
        prop="authorizedTime"
        label="系列开业时间"
        min-width="240" />
      <el-table-column
        prop="revokeReason"
        label="系列取消原因"
        min-width="200" />
    </el-table>
  </div>
</template>
<script>
import HeaderTitle from './headerTitle.vue'
import RequiredFormitemUploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import { mapState, mapGetters } from 'vuex'
import { getShopBrandDataList } from '@/api/businessOos'

const validActualRevokeTime = (rule, value, callback) => {
  const currYear = new Date().getFullYear()
  const currMonth = new Date().getMonth() + 1
  const currDay = new Date().getDate()
  const startDate = new Date(
    `${currYear}/${currMonth}/${currDay} 00:00:00`
  ).getTime()
  const endDate =
    currMonth + 2 > 12
      ? new Date(
          `${currYear + 1}/${currMonth + 2 - 12}/${currDay} 23:59:59`
        ).getTime()
      : new Date(`${currYear}/${currMonth + 2}/${currDay} 23:59:59`).getTime()
  if (value) {
    const dateValue = value.replace('-', '/')
    const date = new Date(dateValue).getTime()
    if (date < startDate || date > endDate) {
      return callback(new Error('日期只能选择当天以后两个月以内'))
    }
    return callback()
  }
  return callback()
}
export default {
  props: {
    shopList: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    shopData: {
      type: Array,
      default: () => {
        ;[]
      }
    },
    marketCenter: {
      type: String,
      default: ''
    },
    revokeReason: {
      type: String,
      default: ''
    },
    isBigBrandWithClassic: {
      type: Boolean,
      default: false
    },
    classicShopCount: {
      type: Number,
      default: 0
    },
    classicShopArea: {
      type: Number,
      default: 0
    },
    formStatus: {
      type: [String, Number],
      default: ''
    }
  },
  components: {
    HeaderTitle,
    RequiredFormitemUploadFile
  },
  data() {
    return {
      seriesForm: {
        seriesList: []
      },
      form: {
        list: [
          // {
          //   id:'',//门店ID
          //   shopNumber:'',//门店编码
          //   shopName:'',//门店名称
          //   marketName:'' ,//商场类型
          //   shopType:'',//门店类型
          //   brandName:'',//授权品牌
          //   openingTime:'',//开业日期
          //   shopArea:'',//门店面积
          //   achievement:'',//上一年提货业绩（万）
          //   revokeShopsType:'',//是否私自撤店
          //   actualRevokeTime:'',//预计撤店日期
          //   revokeReason:'',//撤店原因
          //   remark:'',//备注
          //   fileList:[],//附件
          //   province:'',
          //   city:'',
          //   area:'',
          //   street:''
          // }
        ]
      },
      requiredRules: [
        {
          required: true,
          message: '必填项不能为空',
          trigger: ['blur', 'change']
        }
      ],
      validActualRevokeTime,
      model: {
        id: '', //门店ID
        shopNumber: '', //门店编码
        shopName: '', //门店名称
        marketName: '', //商场名称
        marketType: '', //商场类型
        shopType: '', //门店类型
        shopTypeContent: '',
        brandName: '', //授权品牌
        openingTime: '', //开业日期
        shopArea: '', //门店面积
        // achievement: '', //上一年提货业绩（万）
        revokeShopsType: '', //是否私自撤店
        actualRevokeTime: '', //预计撤店日期
        // revokeReason:'',//撤店原因
        remark: '', //备注
        fileList: [], //附件
        locationFileList: [],
        proveFileList: [],
        newShopLocationFileList: [],
        province: '',
        city: '',
        area: '',
        street: '',
        shopPositionLevel: ''
      }
    }
  },
  filters: {
    addressFilter(obj) {
      let {
        province = '',
        city = '',
        area = '',
        street = '',
        shopTypeValue = '',
        shopFloor = '',
        shopFloorNumber = ''
      } = obj
      return (
        province +
        city +
        area +
        street +
        shopTypeValue +
        shopFloor +
        shopFloorNumber
      )
    }
  },
  watch: {
    shopData: {
      handler(shopData) {
        if (shopData.length) {
          this.form.list = [...shopData]
          let shopIds = shopData.map(item => item.id)
          Promise.all(
            shopIds.map(async shopId => {
              // let shopItem = shopData.filter(item => item.id === shopId)
              let revokeReason = this.revokeReason
              let data = await this.getShopBrandDataList(shopId)
              return data.map(v => {
                return {
                  ...v,
                  revokeReason: revokeReason || ''
                }
              })
            })
          ).then(res => {
            // console.log(res)
            let arr = []
            res.map(seriesList => {
              arr = [...arr, ...seriesList]
            })
            this.seriesForm.seriesList = arr
          })
        }
      },
      immediate: true
    },
    marketCenter: {
      handler(val) {
        this.seriesForm.seriesList = this.seriesForm.seriesList.map(item => {
          return {
            ...item,
            marketCenter: val
          }
        })
      },
      immediate: true
    },
    watchShopDataAndShopList: {
      handler({ shopData, shopList }) {
        if (
          (this.isOA || [20, 9, 0].includes(this.formStatus)) &&
          shopData.length > 0 &&
          shopList.length > 0
        ) {
          shopData = shopData.map(item => {
            const defaultList = shopList.find(list => list.id === item.id)
            if (!defaultList) return item
            return Object.assign(this.model, defaultList, item)
          })
          this.form.list = [...shopData]
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState('data', ['type', 'removeShopReason']),
    ...mapState({
      isOA: state => state.dealershipTransfer.isOA,
      isMobile: state =>
        state.app.device === 'mobile' || state.app.isWxworkMoblie
    }),
    ...mapGetters('commonConfig', ['bigBrandMap']),
    watchShopDataAndShopList() {
      return {
        shopData: this.shopData,
        shopList: this.shopList
      }
    },
    shopAddress({ form }) {
      const list = form.list[0]
      if (!list) return ''
      return (
        (list?.province || '') +
        (list?.city || '') +
        (list?.area || '') +
        (list?.street || '') +
        (list?.shopTypeValue || '') +
        (list?.shopFloor || '') +
        (list?.shopFloorNumber || '')
      )
    }
  },
  methods: {
    add() {
      if (this.isOA) {
        return
      }
      if (!this.shopList.length) {
        this.$message.error('没有门店可添加')
        return
      }
      if (this.form.list.length === 1) {
        this.$message.error('一个流程只能撤一家门店')
        return
      }
      const obj = Object.assign({}, this.model)

      this.form.list = [...this.form.list, obj]
    },
    init() {
      this.form = {
        list: [
          // {
          //   id:'',//门店ID
          //   shopNumber:'',//门店编码
          //   shopName:'',//门店名称
          //   marketName:'' ,//商场类型
          //   shopType:'',//门店类型
          //   brandName:'',//授权品牌
          //   openingTime:'',//开业日期
          //   shopArea:'',//门店面积
          //   achievement:'',//上一年提货业绩（万）
          //   revokeShopsType:'',//是否私自撤店
          //   actualRevokeTime:'',//预计撤店日期
          //   revokeReason:'',//撤店原因
          //   remark:'',//备注
          //   fileList:[],//附件
          //   province:'',
          //   city:'',
          //   area:'',
          //   street:''
          // }
        ]
      }
      this.seriesForm.seriesList = []
    },
    onPreview(file) {
      window.open(file.url)
    },
    del(e, index, isOA) {
      if (isOA) {
        return
      } else {
        let shopId = this.form.list[index].id
        this.seriesForm.seriesList = this.seriesForm.seriesList.filter(
          item => item.shopId !== shopId
        )
        this.form.list.splice(index, 1)
      }
    },
    changeRevokeReason(val, index) {
      let shopId = this.form.list[index].id
      this.seriesForm.seriesList = this.seriesForm.seriesList.map(item => {
        if (item.shopId === shopId) {
          return {
            ...item,
            revokeReason: val
          }
        } else {
          return item
        }
      })
    },
    async changeShop(val, index) {
      console.log('ID', val)
      const ids = this.form.list.map(item => {
        return item.id
      })
      let isAdd = []
      ids.map(item => {
        if (item === val) {
          isAdd.push(item)
        }
      })
      if (isAdd.length > 1) {
        this.form.list[index] = Object.assign({}, this.model)
        this.$message.error('不能重复添加该门店')
        return
      }
      let obj = this.shopList.filter(item => {
        if (item.id === val) {
          return item
        }
      })[0]
      obj.brandName = obj?.shopBrandDetailsList
        .map(item => {
          return item.brandName
        })
        .join(',')
      const arr = [
        'shopNumber',
        'shopName',
        'marketName',
        'marketType',
        'shopType',
        'shopTypeContent',
        'brandName',
        'openingTime',
        'shopArea',
        'province',
        'city',
        'area',
        'street',
        'shopPositionLevel'
      ]
      for (let i = 0; i <= arr.length - 1; i++) {
        this.form.list[index][arr[i]] = obj[arr[i]]
      }
      this.$set(this.form.list, index, this.form.list[index])
      let seriesList = await this.getShopBrandDataList(this.form.list[index].id)
      const list = this.seriesForm.seriesList.filter(
        item => item.shopId != this.form.list[index].id
      )
      this.seriesForm.seriesList = [...list, ...seriesList]
      this.seriesForm.seriesList = this.seriesForm.seriesList.map(item => {
        return {
          ...item,
          marketCenter: this.marketCenter,
          revokeReason: this.revokeReason
        }
      })
    },
    uploaded(fileList, index) {
      this.$refs['form'].validateField(`list.${index}.fileList`)
    },
    getShopBrandDataList(id) {
      return new Promise(async (resolve, reject) => {
        let { code, data } = await getShopBrandDataList({
          shopId: id,
          queryAll: 1
        })
        if (code === 0 && data.length) {
          data = data.map(item => {
            return {
              ...item,
              shopId: id,
              revokeReason: '',
              brandId: item.id
            }
          })
          resolve(data)
        } else {
          resolve([])
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/flowForm.scss';
.formItem_content {
  height: 100%;
  padding: 0 15px;
  display: flex;
  flex-direction: columns;
  justify-content: flex-start;
  align-items: center;
}
</style>
