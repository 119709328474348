<template>
  <div style="margin-bottom: 60px">
    <el-form
      :model="form"
      ref="form"
      class="flow-form"
      label-position="top"
      :inline="true">
      <header-title :title="`经销商撤店流程`" />
      <div class="tips">
        <ol>
          <li v-for="(content, index) of tips" :key="index">{{ content }}</li>
        </ol>
      </div>
      <el-form-item class="formItem" label="标题" prop="title"
        ><el-input
          v-model="form.title"
          :title="form.title"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <el-form-item class="formItem" label="单号" prop="orderNumber"
        ><el-input
          v-model="form.orderNumber"
          :title="form.orderNumber"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <el-form-item class="formItem" label="招商单号" prop="zsOrderNumber"
        ><el-input
          v-model="form.zsOrderNumber"
          :title="form.zsOrderNumber"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <el-form-item class="formItem" label="流程类型" v-if="type === 'direct'"
        ><el-input
          :value="type === 'direct' ? '直营撤店' : ''"
          placeholder="流程类型"
          disabled
      /></el-form-item>
      <el-form-item class="formItem" label="提单人工号" prop="optJobNumber"
        ><el-input v-model="form.optJobNumber" placeholder="自动生成" disabled
      /></el-form-item>
      <el-form-item class="formItem" label="提单人" prop="optUserName"
        ><el-input v-model="form.optUserName" placeholder="自动生成" disabled
      /></el-form-item>
      <el-form-item class="formItem" label="提单部门" prop="optDeptName"
        ><el-input v-model="form.optDeptName" placeholder="自动生成" disabled
      /></el-form-item>
      <el-form-item class="formItem" label="提单日期" prop="optTime"
        ><el-input v-model="form.optTime" placeholder="自动生成" disabled
      /></el-form-item>
      <required-form-item-select
        class="formItem"
        ref="businessRef"
        prop="businessLicenseId"
        v-model="form.businessLicenseId"
        label="售达方"
        labelKey="licenseNumber"
        valueKey="id"
        :selectProps="{
          filterable: true,
          remote: true,
          'popper-class': 'custom-business-select'
        }"
        :selectListeners="{
          change: changeData
        }"
        :total-count="businessCountTotal"
        :getDataList="getBusinessLicense"
        :getNextList="getBusinessLicense"
        @onOptionsListNotFound="onNotFoundBusinessLicense"
        @changeSelectedItem="handleChangeBusinessLicense">
        <template v-slot="{ scope }">
          <span>{{ scope.row.licenseNumber }}</span>
          <small>{{ scope.row.businessLicenseName }}</small>
        </template>
      </required-form-item-select>
      <el-form-item class="formItem" label="售达方名称" prop=""
        ><el-input
          v-model="businessSelectedItem.businessLicenseName"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <el-form-item class="formItem" label="经销商（实际控股人）" prop=""
        ><el-input
          v-model="businessSelectedItem.realName"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <el-form-item
        class="formItem"
        label="经销商（实际控股人）联系方式"
        prop=""
        ><el-input
          v-model="businessSelectedItem.contactInfo"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <RequiredFormItemSelect
        class="formItem"
        label="授权城市"
        prop="authorizedCityCode"
        v-model="form.authorizedCityCode"
        :disabled="isOA"
        labelKey="authorizedCityName"
        valueKey="authorizedCityCode"
        :getDataListParams="getAuthorizedCityListParams"
        :getDataList="getAuthorizedCityList"
        @changeSelectedItem="handleChangeCity" />
      <el-form-item class="formItem" label="城市级别" prop=""
        ><el-input v-model="marketLevelName" placeholder="自动生成" disabled
      /></el-form-item>
      <el-form-item class="formItem" label="省份" prop=""
        ><el-input
          v-model="authorizedCityParams.province"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <el-form-item class="formItem" label="城市" prop=""
        ><el-input
          v-model="authorizedCityParams.city"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <el-form-item class="formItem" label="区/县" prop=""
        ><el-input
          v-model="authorizedCityParams.area"
          placeholder="自动生成"
          disabled
      /></el-form-item>
      <RequiredFormItemSelect
        class="formItem"
        label="门店品牌"
        prop="bigBrandCode"
        v-model="form.bigBrandCode"
        :getDataList="getBigBrandData"
        labelKey="name"
        valueKey="code"
        @changeSelectedItem="handleChangeBigBrand" />
      <CommonSelect
        v-if="isBigBrandWithClassic"
        :filterable="false"
        prop="marketBrandCodeList"
        multiple
        class="formItem"
        label="商场品牌"
        type="marketBrand"
        v-model="form.marketBrandCodeList"
        labelKey="content"
        valueKey="code"
        @onChangeItem="handleChangeMarketBrand" />
      <RequiredFormItemSelect
        class="formItem"
        label="撤店原因"
        prop="revokeReason"
        v-model="form.revokeReason"
        :dataList="[
          {
            label: '商场原因',
            value: '商场原因'
          },
          {
            label: '经销商经营原因',
            value: '经销商经营原因'
          },
          {
            label: '换位重装',
            value: '换位重装'
          },
          {
            label: '系统数据修正',
            value: '系统数据修正'
          },
          {
            label: '换商换位/换系列',
            value: '换商换位/换系列'
          },
          {
            label: '店面换系列',
            value: '店面换系列'
          }
        ]"
        labelKey="label"
        valueKey="value" />
      <RequiredFormItemInput
        class="formItem"
        label="品牌区域编码"
        placeholder="自动生成"
        disabled
        v-model="form.brandRegionCode" />
      <el-form-item
        v-if="isBigBrandWithClassic"
        class="formItem"
        label="近三年提货业绩（元）">
        <div
          v-for="(item, index) in isOA ? form.remarkMap : remarkMap"
          :key="index">
          <div>{{ item.year_desc }}年</div>
          <el-input :value="item.ship_inv_rb_add_amt_cur" readonly></el-input>
        </div>
      </el-form-item>
      <el-form-item
        v-if="isBigBrandWithClassic"
        class="formItem"
        label="近三年提货业绩增长率">
        <div
          v-for="(item, index) in isOA ? form.remarkMap : remarkMap"
          :key="index">
          <div>{{ item.year_desc }}年</div>
          <el-input
            :value="`${(item.growth_rate * 100).toFixed(2)}%`"
            readonly></el-input>
        </div>
      </el-form-item>
      <el-form-item
        v-if="isBigBrandWithClassic"
        class="formItem"
        label="同级别市场排名">
        <div>
          <div
            v-for="(item, index) in isOA ? form.remarkMap : remarkMap"
            :key="index">
            <div>{{ item.year_desc }}年</div>
            <el-input :value="item.row_index" readonly></el-input>
          </div>
        </div>
      </el-form-item>
      <HeaderTitle title="直营门店评估备案" v-if="type" />
      <DirectFlow v-if="type" v-model="form.assessmentfFiling" />
    </el-form>
    <dealer-remove-store-flow
      ref="dealerRemoveStoreFlow"
      :formStatus="form.status"
      :shopList="shopList"
      :shopData="shopData"
      :marketCenter="form.marketCenter"
      :revokeReason="form.revokeReason"
      :isBigBrandWithClassic="isBigBrandWithClassic"
      :classicShopCount="isOA ? form.shopCon : classicShopCount"
      :classicShopArea="
        isOA && shopData.length > 0
          ? shopData[0]?.dealerShopArea
          : classicShopArea
      "
      @getShopType="getShopType" />

    <!-- <el-form class="flow-form" :inline="true" label-position="top">
      <remark-item class="remark" v-model="form.remark" :fileList="form.remarkfileList" @onUpload="file=>{
        form.remarkfileList = file.map(item => { return {...item.response,functionAttribution:'备注'}})
      }"/>
    </el-form> -->
    <footer v-if="!isOA" class="footer">
      <el-button
        type="primary"
        @click.stop="submit(10)"
        v-loading.fullscreen.lock="loading"
        >提交</el-button
      >
      <el-button
        type="info"
        @click.stop="submit(0)"
        v-loading.fullscreen.lock="loading"
        >保存</el-button
      >
      <el-button style="margin-left: 50px" @click="$router.go(-1)"
        >取消</el-button
      >
    </footer>
  </div>
</template>
<script>
import DealerRemoveStoreFlow from './components/dealerRemoveStoreFlow'
import { mapGetters, mapState } from 'vuex'
import {
  getAuthorizedCityData,
  brandseriesListpage,
  requestGetShopCountByDealer,
  getShopBrandData,
  getShopList
} from '@/api/common'
import {
  submitShopRevoke,
  shopRevokeDetail,
  bossAuthShopList
} from '@/api/flow/dealerRemoveStore'
import RequiredFormItemSelect from '@/components/CustomFormItem/requiredFormItemSelect'
import * as BusinessApi from '@/api/attractPlatform/business'
import * as CommonApi from '@/api/common'
import {
  getBusinessDataList,
  shopInfoDetail,
  authorizedcityListpage
} from '@/api/businessOos'
import HeaderTitle from './components/headerTitle.vue'
import RemarkItem from './components/remarkItem.vue'
import CommonSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import DateSelect from '@/components/CustomFormItem/requiredFormItemDatePicker.vue'
import RequiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import UploadFile from '@/components/CustomFormItem/requiredFormItemUploadFile'
import DirectFlow from './components/directFlow.vue'
import { setSession, getSession, removeSession } from '@/utils/storage'
import { requestGetPickUp } from './api/common'
import { authorizedCityAndLevel } from '@/api/flowSetting/cityInfoManage'
import { brandseriesList } from '@/api/common'
export default {
  components: {
    DealerRemoveStoreFlow,
    RequiredFormItemSelect,
    HeaderTitle,
    RemarkItem,
    CommonSelect,
    DateSelect,
    RequiredFormItemInput,
    UploadFile,
    DirectFlow
  },
  data() {
    return {
      tips: [
        '1、经销商撤店申请流程：经销商因故需要把旗下门店关闭',
        '2、撤店流程只正针对整店撤店，如果是门店内撤销某一系列，请提交“门店系列管理表单”',
        '3、若符合以下条件的不允许撤店：① 商场内含3个及以上的品牌（顾家、舒达、丝涟、席梦思、喜临门、金可儿）；② 门店处于A类位置；③ 授权城市经销商整体业绩负增长；④ 撤店后达不到该市场面积要求'
      ],
      businessCountTotal: null,
      loading: false,
      businessSelectedItem: {},
      authorizedCityParams: {
        marketLevelName: '',
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        area: '',
        areaCode: '',
        town: '',
        townCode: ''
      },
      cityList: [],
      shopList: [],
      form: {
        title: '经销商撤店申请', //标题
        orderNumber: '', //单号
        zsOrderNumber: '', //招商单号
        optJobNumber: '', //提单人工号
        optUserId: '', //提单人ID
        optUserName: '', //提单人姓名
        optDeptId: '', //提单部门ID
        optDeptName: '', //提单部门名称
        optTime: '', //提单日期
        businessLicenseId: '', //售达方
        authorizedCityCode: '', //授权城市编码
        remark: '', //备注
        remarkfileList: [], //备注附件
        bossId: '',
        bossName: '',
        contactInfo: '',
        legalPersonName: '',
        assessmentfFiling: {},
        channel: '',
        marketCenter: '',
        marketCenterCode: '',
        brandRegionCode: '', //品牌区域编码
        bigBrandCode: '', //门店品牌代号
        bigBrandName: '', //门店品牌
        revokeReason: '', //撤店原因
        remarkMap: [],
        reserved: '',
        shopCon: '',
        marketBrandCodeList: [],
        marketBrandTextList: [],
        status: ''
      },
      shopData: [],
      isCache: true,
      type: '',
      remarkMap: [],
      classicShopCount: 0,
      classicShopArea: 0,
      marketLevelName: '',
      brandItem: {}
    }
  },
  watch: {
    userInfo: {
      handler(userInfo) {
        if (userInfo && !this.$route.query.id) {
          this.form = Object.assign({}, this.form, {
            optJobNumber: userInfo.code,
            optUserId: userInfo.accountId,
            optUserName: userInfo.realName,
            optDeptId: userInfo.deptId,
            optDeptName: userInfo.deptName
          })
        }
      },
      immediate: true
    },
    authorizedCityParams: {
      async handler(authorizedCityParams) {
        if (!authorizedCityParams.authorizedCityCode) return
        const boss = await getShopBrandData({
          authorizedCityCode: authorizedCityParams.authorizedCityCode,
          businessLicenseId: authorizedCityParams.businessLicenseId
        })
        const dealerData = await requestGetPickUp({
          dealer_code: boss.data[0].crmDistributorCode,
          year_desc: new Date().getFullYear()
        })
        this.remarkMap =
          dealerData.data?.sort((a, b) => {
            return Number(b.year_desc) - Number(a.year_desc)
          }) || []
        if (this.form.bigBrandCode) {
          const res = await brandseriesList({ code: this.form.bigBrandCode })
          this.getMarketLevelName({
            brandId: res.data[0].id,
            authorizedCityCode: authorizedCityParams.authorizedCityCode
          })
          this.getMarketCenterOrBrandRegionCode()
        }
      },
      deep: true,
      immediate: true
    },
    watchClassicShopCount: {
      handler({ isBigBrandWithClassic, form, businessSelectedItem }) {
        if (
          form.authorizedCityCode &&
          isBigBrandWithClassic &&
          businessSelectedItem.bossId &&
          !this.isOA
        ) {
          this.getClassicShopCount(form, businessSelectedItem)
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapGetters('commonConfig', ['bigBrandMap']),
    ...mapState('dealershipTransfer', ['isOA']),
    isBigBrandWithClassic({ form }) {
      if (this.isOA && this.form?.reserved) {
        const reserved = JSON.parse(this.form.reserved)
        return reserved?.isBigBrandWithClassic || false
      }
      if (!this.bigBrandMap.get('慕思经典')) return false
      const { code } = this.bigBrandMap.get('慕思经典')
      return form.bigBrandCode === code
    },
    watchClassicShopCount({
      isBigBrandWithClassic,
      form,
      businessSelectedItem
    }) {
      return { isBigBrandWithClassic, form, businessSelectedItem }
    },
    getAuthorizedCityListParams() {
      return {
        businessLicenseId: this.form.businessLicenseId
      }
    }
  },
  async created() {
    if (this.$route.query.id) {
      const revokeId = this.$route.query.id
      const loading = this.$loading({
        lock: true,
        text: '加载中，请稍等',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      await shopRevokeDetail({ revokeId })
        .then(async res => {
          console.log(res)
          let status = res.data.status
          // 近三年提货量
          if (res.data?.remarkMap) {
            res.data.remarkMap = JSON.parse(res.data.remarkMap)
          }
          // if (res.data?.reserved) {
          //   const reserved = JSON.parse(res.data.reserved)
          //   res.data.revokeReason = reserved.revokeReason
          // }
          for (const key in this.form) {
            this.form[key] = res.data[key]
          }
          if (res.data.shopList.length) {
            Promise.all(
              res.data.shopList.map(async item => {
                let shopType = await this.getShopType(item.shopType)
                return {
                  ...item,
                  shopTypeContent: shopType
                }
              })
            ).then(async shopData => {
              this.form.revokeReason = shopData[0].revokeReason
              this.shopData = shopData
            })
          }
          if (this.form.businessLicenseId && this.form.authorizedCityCode) {
            let params = {
              businessLicenseId: this.form.businessLicenseId,
              pageSize: 1000,
              currPage: 1,
              shopStatus: this.isOA ? -1 : 0
            }
            const res = await getShopList(params)
            if (res.code === 0) {
              Promise.all(
                res.data.map(async item => {
                  let shopType = await this.getShopType(item.shopType)
                  return {
                    ...item,
                    shopTypeContent: shopType
                  }
                })
              ).then(async res => {
                this.shopList = res
                if (
                  this.isOA &&
                  this.shopList.findIndex(
                    item => item.id === this.shopData[0].id
                  ) === -1
                ) {
                  // 通过门店 id 查询门店详情
                  const { data: shopDetail } = await shopInfoDetail({
                    id: this.shopData[0].id
                  })
                  this.shopList.push(shopDetail)
                }
              })
            }
          }
        })
        .finally(() => {
          loading.close()
        })
    }
  },
  mounted() {
    const { type } = this.$route.query
    this.type = type
    this.form.channel = type ? '10' : '20'
    this.form.title = this.form.title.includes('直营')
      ? this.form.title
      : this.form.title + (this.form.channel === '10' ? '(直营)' : '')
    this.isCache = !this.$route.query.id
    if (this.isCache) {
      if (this.form.channel === '20') {
        const json = getSession('dealerRemoveStore')
        console.log(json)
        if (!json) {
          return
        }
        Object.assign(this.$data, json.data)
        Object.assign(
          this.$refs['dealerRemoveStoreFlow'].$data,
          json.dealerRemoveStoreFlow
        )
      } else {
        const json = getSession('dealerRemoveStoreDirect')
        console.log(json)
        if (!json) {
          return
        }
        Object.assign(this.$data, json.data)
        Object.assign(
          this.$refs['dealerRemoveStoreFlow'].$data,
          json.dealerRemoveStoreFlow
        )
      }
    }
  },
  beforeDestroy() {
    if (this.isCache) {
      const dealerRemoveStoreFlow = this.$refs['dealerRemoveStoreFlow']
      if (this.form.channel === '20') {
        setSession(
          'dealerRemoveStore',
          JSON.stringify({
            data: { ...this.$data },
            dealerRemoveStoreFlow: dealerRemoveStoreFlow.$data
          })
        )
      } else {
        setSession(
          'dealerRemoveStoreDirect',
          JSON.stringify({
            data: { ...this.$data },
            dealerRemoveStoreFlow: dealerRemoveStoreFlow.$data
          })
        )
      }
    } else {
      this.form.channel === '20'
        ? removeSession('dealerRemoveStore')
        : removeSession('dealerRemoveStoreDirect')
    }
  },
  methods: {
    // 获取商场类型
    async handleChangeMarketBrand(data) {
      this.form.marketBrandTextList = data.map(item => item.content)
    },
    // 获取售达方
    async handleChangeBusinessLicense(data) {
      this.shopList = []
      this.businessSelectedItem = data
      this.form.bossId = data.bossId
      this.form.bossName = data.realName
      this.form.contactInfo = data.contactInfo
      this.form.legalPersonName = data.legalPersonName
      const res = await getShopList({
        businessLicenseId: data.id,
        pageSize: 1000,
        currPage: 1,
        shopStatus: this.isOA ? -1 : 0
      })
      if (res.code === 0) {
        Promise.all(
          res.data.map(async item => {
            let shopType = await this.getShopType(item.shopType)
            return {
              ...item,
              shopTypeContent: shopType
            }
          })
        ).then(res => {
          // console.log(res)
          this.shopList = res
        })
      }
    },
    async getClassicShopCount(form, businessSelectedItem) {
      const res = await requestGetShopCountByDealer({
        bigBrandId: '1390119720428154881',
        bossId: businessSelectedItem.bossId,
        authorizedCityCode: form.authorizedCityCode,
        ifEnabled: 0
      })
      this.classicShopCount = res.data.shopCount
      this.classicShopArea = res.data.shopArea
      return res.data
    },
    async getMarketLevelName(params) {
      const res = await authorizedCityAndLevel(params)
      if (res.data && res.data.length) {
        this.marketLevelName = res.data[0].marketLevelName
      } else {
        this.marketLevelName = ''
      }
    },
    async handleChangeBigBrand(val) {
      this.form.bigBrandName = val?.name || ''
      this.brandItem = val
      if (val?.id && this.form.authorizedCityCode) {
        this.getMarketCenterOrBrandRegionCode()
        this.getMarketLevelName({
          brandId: val?.id,
          authorizedCityCode: this.form.authorizedCityCode
        })
      }
    },
    async getBigBrandData(params) {
      const res = await brandseriesListpage({
        brandLevel: 0,
        ...params
      })
      return res
    },
    getShopType(shopType) {
      return new Promise(async (resolve, reject) => {
        let { code, data } = await CommonApi.getAnyTypeList({
          module: 'distributor',
          type: 'store_type',
          code: shopType
        })
        if (code === 0 && data.length) {
          resolve(data[0].content)
        } else {
          resolve('')
        }
      })
    },
    getBusinessLicense({ page, searchText, businessLicenseId }) {
      return BusinessApi.license({
        currPage: page,
        key: searchText,
        businessLicenseId
      }).then(res => {
        this.businessCountTotal = res.totalCount
        return res.data
      })
    },
    // 当前列表没有默认选项,触发
    async onNotFoundBusinessLicense({ value: businessLicenseId }, callback) {
      console.log('onNotFoundBusinessLicense', businessLicenseId)
      callback(
        await BusinessApi.license({
          businessLicenseId
        }).then(res => res.data)
      )
    },
    init() {
      this.authorizedCityParams = {
        marketLevelName: '',
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        area: '',
        areaCode: '',
        town: '',
        townCode: ''
      }
      this.shopList = []
      this.form = {
        title: '经销商撤店申请', //标题
        orderNumber: '', //单号
        zsOrderNumber: '', //招商单号
        optJobNumber: '', //提单人工号
        optUserId: '', //提单人ID
        optUserName: '', //提单人姓名
        optDeptId: '', //提单部门ID
        optDeptName: '', //提单部门名称
        optTime: '', //提单日期
        businessLicenseId: '', //售达方
        authorizedCityCode: '', //授权城市编码
        assessmentfFiling: {}
      }
      this.$refs['form'].resetFields()
      this.$refs['dealerRemoveStoreFlow'].init()
    },
    submit(status) {
      let form = {
        shopList: []
      }
      // console.log('id',this.$route.query.id)
      if (!!this.$route.query.id) {
        form.id = this.$route.query.id
      }
      // console.log('id22',form)
      const p1 = new Promise((resolve, reject) => {
        const callback = () => {
          form = Object.assign({}, form, this.form, {
            status
          })
          resolve()
        }
        if (status) {
          this.$refs['form'].validate(valid => {
            if (valid) {
              this.form = {
                ...this.form,
                shopCon: this.isBigBrandWithClassic
                  ? this.classicShopCount
                  : '',
                remarkMap: this.isBigBrandWithClassic ? this.remarkMap : [],
                reserved: {
                  isBigBrandWithClassic: this.isBigBrandWithClassic
                }
              }
              callback()
            } else {
              reject('必填项不能为空或格式有误，请检查填写内容')
            }
          })
        } else {
          callback()
        }
      })
      const p2 = new Promise((resolve, reject) => {
        const dealerRemoveStoreFlowForm =
          this.$refs['dealerRemoveStoreFlow'].$refs['form']
        const callback = () => {
          let list = this.$refs['dealerRemoveStoreFlow'].form.list
          let arr = []
          list.forEach(item => {
            if (!!item.id) {
              arr.push({
                ...item,
                revokeReason: this.form.revokeReason,
                dealerShopArea: this.isBigBrandWithClassic
                  ? this.classicShopArea
                  : ''
              })
            }
          })
          form.shopList = arr
          resolve()
        }
        if (status) {
          dealerRemoveStoreFlowForm.validate(valid => {
            if (valid) {
              callback()
            } else {
              reject('必填项不能为空或格式有误，请检查填写内容')
            }
          })
        } else {
          callback()
        }
      })
      Promise.all([p1, p2])
        .then(() => {
          console.log('验证通过', form)
          this.loading = true
          const { brandRegionCode } = form
          const formData = {
            ...form,
            reserved: JSON.stringify(form.reserved),
            remarkMap: JSON.stringify(form.remarkMap),
            brandRegionCode: brandRegionCode || null
          }
          submitShopRevoke(formData)
            .then(res => {
              console.log(res)
              this.loading = false
              if (res.code === 0) {
                this.isCache = false
                if (status) {
                  this.$alert('流程发布中', '提示', {
                    confirmButtonText: '确定',
                    type: 'success',
                    callback: action => {
                      this.$router.replace({
                        name: 'myApply'
                      })
                    }
                  })
                } else {
                  this.$message.success('保存成功')
                  this.$router.replace({
                    name: 'draft'
                  })
                }
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch(e => {
              this.loading = false
            })
        })
        .catch(error => {
          this.$message.error(error)
        })
    },
    async changeData(businessLicenseId) {
      console.log('id', businessLicenseId)
      this.form.marketCenter = ''
      this.form.marketCenterCode = ''
      this.form.brandRegionCode = ''
      this.form.bossId = ''
      this.form.bossName = ''
      this.form.contactInfo = ''
      this.form.legalPersonName = ''
      this.authorizedCityParams = {
        marketLevelName: '',
        province: '',
        provinceCode: '',
        city: '',
        cityCode: '',
        area: '',
        areaCode: '',
        town: '',
        townCode: ''
      }
      this.form.authorizedCityCode = ''
      this.shopList = []
      this.$refs['dealerRemoveStoreFlow'].init()
    },
    async getAuthorizedCityList({ page, searchText, getDataListParams }) {
      const res = await getAuthorizedCityData(getDataListParams)
      return res.data
    },
    handleChangeCity(authorizedcityItem) {
      this.authorizedCityParams = {
        ...authorizedcityItem,
        area: authorizedcityItem?.area || ''
      }
      console.log('授权城市', this.authorizedCityParams, this.brandItem)
      this.$refs['dealerRemoveStoreFlow'].init()
    },
    async getMarketCenterOrBrandRegionCode() {
      const {
        provinceCode = '',
        cityCode = '',
        areaCode = '',
        townCode = ''
      } = this.authorizedCityParams
      const {
        data: { smallCode = '', smallName = '', brandRegionCode = '' }
      } = await CommonApi.requestGetSizeBarListByDeptMerge({
        provinceCode,
        cityCode,
        areaCode,
        townCode,
        deptId: '',
        brandIds: this.brandItem?.id
      })
      this.form.marketCenter = smallName
      this.form.marketCenterCode = smallCode
      this.form.brandRegionCode = brandRegionCode
    },
    // 上传附件
    handleUpload(file) {}
  }
}
</script>
<style lang="scss" scoped>
@import '@/styles/flowForm.scss';
</style>
