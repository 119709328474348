<template>
  <div class="container">
    <el-form
      ref="ruleForm"
      status-icon
      class="flow-form"
      :inline="true"
      :model="formData"
      label-position="top"
    >
      <HeaderTitle style="margin-top: 20px" title="营业执照信息" />

      <component
        :is="isElement(key)"
        v-for="(value, key) in formLayout.dealerBusinessLicense"
        :key="key"
        v-model="formData[key]"
        delay-update
        :placeholder="placeholder[key] || null"
        class="formItem"
        :prop="key"
        :label="value"
        :type="key === 'businessLicenseType' ? 'business_license_type' : ''"
        :validations="validations[key] || []"
      />
      <required-module-form-item-select
        v-model="formData.ifEnabled"
        class="formItem"
        prop="ifEnabled"
        label="是否生效"
        type="ifEnabled"
      />

      <HeaderTitle style="margin-top: 20px" title="开票信息" />

      <requiredBankFormItemSelect
        v-model="formData.bank"
        prop="bank"
        class="formItem"
        label="开户行"
        :validations="validations['bank'] || []"
        @changeSelectedItem="
          bankItem => {
            formData.bankCode = bankItem ? bankItem.CODE : formData.bankCode
            formData.bankCountryCode = bankItem ? bankItem.AREACODE : formData.bankCountryCode
          }
        "
      />

      <required-form-item-input
        v-for="(value, key) in formLayout.dealerBusinessLicense2"
        :key="value"
        v-model="formData[key]"
        delay-update
        class="formItem"
        :prop="key"
        :label="value"
      />
      <HeaderTitle style="margin-top: 20px" title="纳税信息" />

      <required-module-form-item-select
        v-model="formData.payTaxesType"
        class="formItem"
        prop="payTaxesType"
        label="纳税类型"
        type="pay_taxes_type"
      />
      <required-module-form-item-select
        v-model="formData.billType"
        class="formItem"
        prop="billType"
        label="专票/普票"
        module="public"
        type="invoice_type"
      />
      <required-form-item-input
        v-for="(value, key) in formLayout.dealerBusinessLicense3"
        :key="key"
        v-model="formData[key]"
        delay-update
        :placeholder="placeholder[key] || null"
        class="formItem"
        :prop="key"
        :label="value"
        :validations="validations[key] || []"
      />
      <footer class="footer">
        <el-button
          v-permission="['edit_business_info']"
          type="primary"
          @click="onCommit"
        >
          保存
        </el-button>
        <el-button @click="cancel"> 取消 </el-button>
      </footer>
    </el-form>
  </div>
</template>

<script>
import { businesslicenseUpdate } from '@/api/businessOos'
import RequestModuleSelect from '@/components/CustomFormItem/requestModuleSelect'
import requiredFormItemInput from '@/components/CustomFormItem/requiredFormItemInput'
import requiredFormItemDatePicker from '@/components/CustomFormItem/requiredFormItemDatePicker'
import requiredModuleFormItemSelect from '@/components/CustomFormItem/requiredModuleFormItemSelect'
import requiredBankFormItemSelect from '@/components/CustomFormItem/requiredBankFormItemSelect.vue'

import HeaderTitle from '@/views/flow/components/headerTitle'

const validations = {
  realName: ['name'],
  contactInfo: ['phone'],
  idCard: ['user_id'],
  tencentQq: ['email'],
  creditCode: ['credit_code'],
  // taxpayerPhone: ['phone'],
  bankAccount: ['bank_account'],
  personLiableName: ['name'],
  personLiablePhone: ['phone'],
  eMail: ['email'],
  renovationLiablePerson: ['name'],
  renovationPersonPhone: ['phone'],
  contactPerson: ['name'],
  contactPersonIdcard: ['user_id'],
  contactPersonPhone: ['phone'],
  warehouseUser: ['name'],
  warehousePhone: ['phone'],
  legalPersonContact: ['phone'],
  age: ['age'],
  // accountName: ['name'],
  account: ['bank_account']
}

export default {
  components: {
    RequestModuleSelect,
    requiredFormItemInput,
    requiredFormItemDatePicker,
    requiredModuleFormItemSelect,
    requiredBankFormItemSelect,
    HeaderTitle
  },
  props: {
    cancel: {
      type: Function,
      default: () => {}
    },
    getDataList: {
      type: Function,
      default: () => {}
    },
    dataList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      formData: {},
      shopNatureOption: [],
      storeTypeOPtion: [],
      partnerShipOption: [],
      marketOption: [],
      validations,
      formLayout: {
        dealerBusinessLicense: {
          businessLicenseType: '营业执照类型',
          registeredCapital: '注册资本（万元)',
          legalPersonName: '法人',
          legalPersonContact: '法人联系方式',
          registerDate: '注册日期',
          businessTerm: '营业期限',
          businessLicenseAddress: '营业执照地址（住所）',
          businessScope: '经营范围',
          registrationAuthority: '登记机关',
          certificatesRecAddress: '函证接收地址',
          eMail: '接受电子发票邮箱'
        },
        dealerBusinessLicense2: {
          // bank: '开户行',
          bankAccount: '银行账号',
          bankAccountName: '开户账号名称',
          businessLicenseName: '开票名称',
          // creditCode: '纳税人识别号',
          taxpayerPhone: '纳税人电话'
        },
        dealerBusinessLicense3: {
          personLiableName: '税务负责人',
          personLiablePhone: '税务负责人电话'
        }
      },
      placeholder: {
        registrationAuthority: '请填写营业执照右下方登记机关红章名称',
        bank: '详细至营业点，**分行/分理处',
        certificatesRecAddress: '实控人接收慕思财务下发函证资料地址',
        nativePlace: '广东东莞',
        capitalSource: '自有资金/政策贷款/其他',
        oaAccount: '归档后生成',
        advertisingInvestment: '年度广告投入具体单位新增（万）单位',
        businessTotalTime: '已从事时间指年份'
      }
    }
  },
  watch: {
    // dataList(val) {
    //   console.log(val);
    //   this.formData = val[0]
    // }
    dataList: {
      deep: true,
      immediate: true,
      handler(value) {
        this.formData = value[0]
      }
    }
  },
  methods: {
    isElement(key) {
      switch (key) {
        case 'registerDate':
          return 'required-form-item-date-picker'
        case 'businessLicenseType':
          return 'required-module-form-item-select'
        default:
          return 'required-form-item-input'
      }
    },
    onCommit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          await businesslicenseUpdate(this.formData)
          this.$message.success('修改成功')
          this.cancel()
          this.getDataList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.formItem {
  width: 20%;
}

.container {
  padding: 0 20px;
}

.shopTypeSelect {
  ::v-deep {
    .el-input.is-disabled,
    .el-input {
      .el-input__inner {
        background-color: transparent;
        border-color: transparent;
        border: 1px solid #dfe4ed;
        color: #333;
        cursor: default;
        padding-left: 10px;
      }
    }

    .el-input__icon {
      cursor: default;
    }
  }
}

.footer {
  margin: 10px 0;
}

@media (max-width: 702px) {
  .formItem {
    width: 100%;
  }
}

@media screen and (min-width: 702px) and (max-width: 1260px) {
  .formItem {
    width: calc(33% - 10px);
  }
}

@media (min-width: 1260px) {
  .formItem {
    width: calc(25% - 10px);
  }
}
</style>
